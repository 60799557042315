import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from 'react-select';
import { format, set } from 'date-fns';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { exportAssetData } from "../AssetCreation/ExportAssetCreation";

import { getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";
import { getSupplier } from "../../../Services/SupplierMasterService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";
import {
    getAsset,
    getAssetStatus,
    getFinancialTransaction,
} from "../../../Services/AssetSettingsService";

import CalendarWithBG from "../../../assets/icon/CalendarWithBG.svg";
import nodataFound from "../../../assets/img/nodatafound.png";

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '275px'
    // }),
};


const FinancialTransaction = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");
    const [dateFormat, setDateFormat] = useState("");

    const [financialArray, setFinancialArray] = useState([]);
    const [FilterfinancialArray, setFilterFinancialArray] = useState([]);
    const [assetArray, setAssetArray] = useState([]);
    const [assetStatusArray, setAssetStatusArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [supplierArray, setSupplierArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            assetName: "",
            assetID: "",
            assetTagID: "",
            categoryId: 0,
            subCategoryId: 0,
            brandId: 0,
            modelNo: "",
            serialNo: "",
            cost: "",
            supplierId: 0,
            purchaseDate: null,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
            empId: 0,
            assetImage: "",
            depreciableAsset: "",
            depreciableCost: "",
            assetLife: "",
            salvageValue: "",
            depreciationMethod: "",
            dateAcquired: null,
            date: null,
            status: 0,
        },
    });

    // Define dataInitialization as a regular async function
    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                fetchAssetData(),
                getSupplierData(),
                getEmployeeData(),
                getDepartmentData(),
                getUnitBranchDetails(),
                getCategoryAndBrandData(),
                // fetchFinancialTransaction(),
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dataInitialization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchAssetData = async () => {
        try {
            const dateCurrentFormat = await getDateFormat();
            setDateFormat(dateCurrentFormat);
            const assetData = await getAsset();
            const assetStatus = await getAssetStatus();
            setAssetStatusArray(assetStatus);
            console.log("assetStatus", assetStatus);
            const finalData = assetData?.length > 0 ? assetData.map((asset, index) => {
                const brandAsset = brandArray?.find(x => x.id === asset?.brandId);
                const formattedDate = asset.purchaseDate ? format(new Date(asset.purchaseDate), dateCurrentFormat) : null;
                const formattedDateAcquired = asset.dateAcquired ? format(new Date(asset.dateAcquired), dateCurrentFormat) : null;
                const getAssetStatusValue = assetStatus?.find(e => e.id === asset?.statusValue);

                return {
                    ...asset,
                    Rowid: index + 1,
                    brandName: brandAsset ? brandAsset?.brandName : '',
                    formatedPurchaseDate: formattedDate,
                    formattedDateAcquired: formattedDateAcquired,
                    assetStatusValue: getAssetStatusValue?.labelAssignedValue
                };
            }) : [];
            setAssetArray(finalData);
            fetchFinancialTransaction(finalData);
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        }
    };

    const getUnitBranchDetails = async () => {
        const unitData = await getBUData();
        const sortedUnitData = unitData.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
        setUnitArray(sortedUnitData);

        const branchData = await getBRData();
        const sortedBranchData = branchData.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
        setBranchArray(sortedBranchData);
    };

    const getCategoryAndBrandData = async () => {
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);

        const SubCategoryData = await getSubCategory();
        setSubCategoryArray(SubCategoryData);

        const BrandData = await getBrand();
        setBrandArray(BrandData);
    };

    const getDepartmentData = async () => {
        const data = await getDepartment();
        setDepartmentArray(data);
    };

    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        setSupplierArray(SupplierData);
    };

    const getEmployeeData = async () => {
        const empData = await getEmployee();
        const finalData = empData.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp?.firstName + " " + Emp?.lastName,
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const fetchFinancialTransaction = async (_assetArray) => {
        let financalData = await getFinancialTransaction();
        let finalFinancialData = [];
        for (let i = 0; i < financalData?.length; i++) {
            let _filteredAssetData = _assetArray.filter((x) => x.id == financalData[i].ftAssetId);
            if (_filteredAssetData.length > 0) {
                let _assetObject = {
                    assetID: _filteredAssetData[0].id,
                    assetCodeID: _filteredAssetData[0].assetID,
                    date: financalData[0].ftTransactionDate,
                    unitID: _filteredAssetData[0].unitId,
                    branchID: _filteredAssetData[0].branchId,
                    categoryID: _filteredAssetData[0].categoryId,
                    subCategoryID: _filteredAssetData[0].subCategoryId,
                    departmentID: _filteredAssetData[0].departmentId,
                    empID: _filteredAssetData[0].empId,
                    status: _filteredAssetData[0].statusValue,
                    transactionDate: financalData[i].ftTransactionDate,
                    formattedTransactionDate: financalData[i].ftTransactionDate.split('T')[0],
                    description: financalData[i].ftDescription,
                    transaction: financalData[i].ftTransaction,
                    amount: financalData[i].ftAmount,
                    paymentType: financalData[i].ftPaymentType
                }
                finalFinancialData.push(_assetObject);
            }
        }
        console.log("financalData", finalFinancialData);
        setFinancialArray(finalFinancialData);
        setFilterFinancialArray(finalFinancialData);
    };

    const handleSearchChange = (inputFieldsValue) => {
        let inputDate = null;
        let formattedInputDate = null;
        if (inputFieldsValue.date != null) {
            inputDate = new Date(inputFieldsValue?.date)
            let date = inputDate.getDate();
            let fullDate = date < 10 ? '0' + date : date;
            let month = inputDate.getMonth() + 1;
            let fullMonth = month < 10 ? '0' + month : month
            let year = inputDate.getFullYear();
            formattedInputDate = "" + year + "-" + fullMonth + "-" + fullDate + ""
        }
        let filterData = financialArray.filter(record => {
            return (
                (formattedInputDate === null || record.filterPurchaseDate === formattedInputDate) &&
                (inputFieldsValue.unitId === 0 || inputFieldsValue.unitId === null || record.unitId === inputFieldsValue.unitId) &&
                (inputFieldsValue.branchId === 0 || inputFieldsValue.branchId === null || record.branchId === inputFieldsValue.branchId) &&
                (inputFieldsValue.categoryId === 0 || inputFieldsValue.categoryId === null || record.categoryId === inputFieldsValue.categoryId) &&
                (inputFieldsValue.subCategoryId === 0 || inputFieldsValue.subCategoryId === null || record.subCategoryId === inputFieldsValue.subCategoryId) &&
                (inputFieldsValue.departmentId === 0 || inputFieldsValue.departmentId === null || record.departmentId === inputFieldsValue.departmentId) &&
                (inputFieldsValue.empId === 0 || inputFieldsValue.empId === null || record.empId === inputFieldsValue.empId) &&
                (inputFieldsValue.status === 0 || inputFieldsValue.status === null || record.statusValue === inputFieldsValue.status)

            );
        });
        setFilterFinancialArray(filterData);
    };

    const handleSearchClear = () => {
        reset();
        setFilterFinancialArray(financialArray);
    };

    const exportAsset = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption !== "") {
            handleExportAsset(selectedOption);
            event.target.value = "";
        }
    };

    const handleExportAsset = (format) => {
        exportAssetData(assetArray, format);
    };

    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        setPageAsset(0);
        setSearchQueryAsset(event.target.value);
    };

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(assetArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = FilterfinancialArray?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (

                <div>
                    <div className="pagetitleCategory">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span>Asset &gt;&gt;</span>
                                <span> Finance Transaction </span>
                            </span>

                            <div><InfoOutlinedIcon /></div></div>
                    </div>

                    <div className="card mt-4 customScrollbar">
                        <div className="card-body-category">
                            <div className="blackTextColor fontFamily fontsize16 fontweightRegular mLeft20 mTop20 mBottom20">
                                Easily check out multiple assets at once with our bulk check-out feature.
                            </div>

                            <div className="pLeft30 pRight20">
                                {/* <div className="blackTextColor fontFamily fontsize16 fontweightRegular mTop20 mBottom20">
                                    Search Fields
                                </div>
                                <input
                                    type="text"
                                    placeholder="Asset ID, Brand, Description, Model, Purchased from etc..."
                                    value={searchQueryAsset}
                                    onChange={handleSearchChangeAsset}
                                    className="searchTextBoxCustom"
                                /> */}

                                <div className="row" style={{ paddingTop: "24px" }}>
                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            Date
                                        </div>
                                        <div className="d-flex">
                                            <Controller
                                                name="date"
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        validDate: (value) => {
                                                            // If the value is empty, it's valid because the field is optional
                                                            if (!value) {
                                                                return true;
                                                            }

                                                            // Check if the value is a valid date object
                                                            const dateValue = new Date(value);
                                                            if (isNaN(dateValue.getTime())) {
                                                                return "Invalid date value";
                                                            }

                                                            // Format the date and validate it
                                                            const formattedDate = format(dateValue, dateFormat);
                                                            const [day, month, year] = formattedDate.split('/');
                                                            const selectedDate = validateDate(dateFormat, day, month, year);

                                                            // Get today's date for comparison
                                                            const today = new Date();
                                                            today.setHours(0, 0, 0, 0);

                                                            // Check if selected date is in the future
                                                            if (selectedDate > today) {
                                                                return "Date cannot be in the future";
                                                            }

                                                            // Validate date format using regex
                                                            const regex = getRegexForDateFormat(dateFormat);
                                                            if (!regex.test(formattedDate)) {
                                                                return "Invalid date format (" + dateFormat + ")";
                                                            }
                                                            return true;
                                                        },
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => { field.onChange(date); }}
                                                        dateFormat={dateFormat}
                                                        className="customDatePicker date-picker-input"
                                                        ref={(input) => field.ref(input?.inputElement)}
                                                    />
                                                )}
                                            />
                                            <img src={CalendarWithBG} alt="CalendarWithBG" height={45} />
                                        </div>
                                        {errors.date && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.date.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Unit
                                        </div>
                                        <Controller
                                            name="unitId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={unitArray.find((c) => c.bU_ID === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                    }}
                                                    options={unitArray}
                                                    getOptionLabel={(option) => option.bU_NAME}
                                                    getOptionValue={(option) => option.bU_NAME}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.unitId && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.unitId.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Branch
                                        </div>
                                        <Controller
                                            name="branchId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={branchArray.find((c) => c.bR_ID === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                    }}
                                                    options={branchArray}
                                                    getOptionLabel={(option) => option.bR_NAME}
                                                    getOptionValue={(option) => option.bR_NAME}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.branchId && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.branchId.message}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row" style={{ paddingTop: "24px" }}>
                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Category
                                        </div>
                                        <Controller
                                            name="categoryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={categoryArray.find(option => option.id === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                    }}
                                                    options={categoryArray}
                                                    placeholder=""
                                                    getOptionLabel={(option) => option.categoryName}
                                                    getOptionValue={(option) => option.categoryName}
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.categoryId && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.categoryId.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Sub Category
                                        </div>
                                        <Controller
                                            name="subCategoryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={subCategoryArray.find(option => option.id === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                    }}
                                                    options={subCategoryArray}
                                                    placeholder=""
                                                    getOptionLabel={(option) => option.subCategoryName}
                                                    getOptionValue={(option) => option.subCategoryName}
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.subCategoryId && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.subCategoryId.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Department
                                        </div>
                                        <Controller
                                            name="departmentId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={departmentArray.find((c) => c.id === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                    }}
                                                    options={departmentArray}
                                                    getOptionLabel={(option) => option.departmentName}
                                                    getOptionValue={(option) => option.departmentName}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.departmentId && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.departmentId.message}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row" style={{ paddingTop: "24px" }}>
                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" >
                                            Employee
                                        </div>
                                        <Controller
                                            name="empId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={employeeArray.find(emp => emp.id === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                    }}
                                                    options={employeeArray}
                                                    getOptionLabel={(option) => option.EmployeeName}
                                                    getOptionValue={(option) => option.EmployeeName}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.empId && (
                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                {errors.empId.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                            Status
                                        </div>
                                        <Controller
                                            name="status"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    value={assetStatusArray.find(status => status.id === field.value) || null}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                    }}
                                                    options={assetStatusArray}
                                                    getOptionLabel={(option) => option.labelAssignedValue}
                                                    getOptionValue={(option) => option.labelAssignedValue}
                                                    placeholder=""
                                                    isClearable
                                                    isSearchable
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                        {errors.status && (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.status.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-4 col-sm-12">
                                        <div className="d-flex align-items-center mTop35">
                                            <button
                                                className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                style={{ marginLeft: "20px" }}
                                                onClick={handleSubmit(handleSearchChange)}
                                            >
                                                Search
                                            </button>
                                            <span>
                                                <button
                                                    className="fontFamily fontweightRegular fontsize16 importBtn"
                                                    onClick={handleSearchClear}
                                                >
                                                    Clear
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px" }}>
                                    <div className="custom-container">
                                        <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                            <span className="show">Show</span>
                                            <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                {paginateArray.map((n) => (
                                                    <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                        {n}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                Entries
                                            </span>
                                        </span>
                                    </div>

                                    {/* <div className="addbuttondiv">
                                        <select
                                            className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn"
                                            name="language"
                                            id="language"
                                            defaultValue=""
                                            onChange={exportAsset}
                                        >
                                            <option value="" hidden={true}>
                                                Export
                                            </option>
                                            <option value="csv">CSV</option>
                                            <option value="excel">Excel</option>
                                            <option value="pdf">Pdf</option>
                                        </select>
                                    </div> */}
                                </div>

                            </div>


                            <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                <div className="tableScroll" style={{ height: '300px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                    <table className="w-100 mt-0">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Transaction Date</th>
                                                <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Asset ID</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Description</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Transaction</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Amount</th>
                                                <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", textAlign: 'center' }}>Payment Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {FilterfinancialArray.length > 0 ? (
                                                FilterfinancialArray.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((financial, index) => (
                                                    <tr className="text-left" key={index} style={{ height: '59px' }}>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{financial.formattedTransactionDate}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{financial.assetCodeID}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{financial.description}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{financial.transaction}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{financial.amount}</td>
                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 `} style={{ textAlign: 'center' }}>{financial.paymentType}</td>
                                                    </tr>

                                                ))
                                            ) : (
                                                <td colSpan="6" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                            )}



                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {totalEntriesAsset > 0 && (
                            <div>
                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                    <div className="text-muted fontFamily fontsize12 fontweightRegular pLeft20">
                                        Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                    </div>
                                    <div>
                                        <div className="pagination-container d-flex align-items-center">
                                            <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                            </button>
                                            <ReactPaginate
                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                forcePage={pageAsset}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handleChangePagePaginationAsset}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                renderOnZeroPageCount={null}
                                            />
                                            <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ padding: "0px 100px" }}></div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            )}
        </div>

    );
}
export default FinancialTransaction;
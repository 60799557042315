import axios from 'axios';
import api from '../webconfig';
import { updateDocument } from './GeneralSettingsService';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

export async function getASTSettings() {
    try {
        const response = await axios.get(API_BASE_URL + 'AssetSettings/GetAssetSettings', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching AssetSettingsData:', error);
        throw error;
    }
}

export async function updateASTSettings(payload) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetSettings/UpdateAssetSettings', payload, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating Asset Settings:', error);
        throw error;
    }
}

// Asset Creation

export async function getAsset() {
    try {
        const response = await axios.get(API_BASE_URL + 'AssetCreation/GetAssetCreation', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset Data:', error);
        throw error;
    }
}

export async function createAsset(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetCreation/CreateAssetCreation', data, { headers: getAuthHeader() });
        if (data.NextID) {
            const payload = {
                id: 3,
                documentType: 'AssetNo',
                documentNo: data.NextID
            }
            await updateDocument(payload);
        }

        return response.data;
    } catch (error) {
        console.error('Error creating Asset:', error);
        throw error;
    }
}

export async function updateAsset(payload) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetCreation/UpdateAssetCreation', payload, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating Asset:', error);
        throw error;
    }
}

export async function deleteAsset(assetId) {
    try {
        const response = await axios.post(`${API_BASE_URL}AssetCreation/DeleteAssetCreation?assetCreationId=${assetId}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Asset:', error);
        throw error;
    }
}

export async function getAssetStatus() {
    try {
        const response = await axios.get(API_BASE_URL + 'AssetCreation/GetAssetLable', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset Status:', error);
        throw error;
    }
}

export async function bulkUpdateAsset(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetCreation/BulkUpdateAsset', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error Updating Bulk Asset:', error);
        throw error;
    }
}

// Asset Attachments

export async function getAssetAttachments() {
    try {
        const response = await axios.get(API_BASE_URL + 'AssetCreation/GetAssetCreationAttachments', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset Attachments Data:', error);
        throw error;
    }
}

export async function createAssetAttachment(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetCreation/CreateAssetCreationAttachments', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error creating Asset Attachments:', error);
        throw error;
    }
}

export async function deleteAssetAttachment(assetId) {
    try {
        const response = await axios.post(`${API_BASE_URL}AssetCreation/DeleteAssetCreationAttachments?assetCreationAttachmentsId=${assetId}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Asset Attachments:', error);
        throw error;
    }
}

export async function GetAssetCreationAttachmentsByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}AssetCreation/GetAssetCreationAttachmentsByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error get Insurance:', error);
        throw error;
    }
}

// Asset Link

export async function getAssetLink() {
    try {
        const response = await axios.get(API_BASE_URL + 'AssetCreation/GetAssetCreationLink', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset Link Data:', error);
        throw error;
    }
}

export async function createAssetLink(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetCreation/CreateAssetCreationLink', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error creating Asset Link:', error);
        throw error;
    }
}

export async function deleteAssetLink(assetId) {
    try {
        const response = await axios.post(`${API_BASE_URL}AssetCreation/DeleteAssetCreationLink?assetCreationLinkId=${assetId}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Asset Link:', error);
        throw error;
    }
}

export async function GetAssetCreationLinkByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}AssetCreation/GetAssetCreationLinkByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error get Insurance:', error);
        throw error;
    }
}

// Asset History

export async function getAssetHistory() {
    try {
        const response = await axios.get(API_BASE_URL + 'AssetCreation/GetAssetCreationHistory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset History Data:', error);
        throw error;
    }
}

export async function CreateAssetHistory(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetCreation/CreateAssetCreationHistory', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error Creating Asset History:', error);
        throw error;
    }
}

// Asset Financial Transaction

export async function getFinancialTransaction() {
    try {
        const response = await axios.get(API_BASE_URL + 'AssetCreation/GetFinancialTransaction', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset Financial Transaction Data:', error);
        throw error;
    }
}

export async function CreateFinancialTransaction(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AssetCreation/CreateFinancialTransaction', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error Creating Asset Financial Transaction:', error);
        throw error;
    }
}

// Audit

export async function getAuditHD() {
    try {
        const response = await axios.get(API_BASE_URL + 'AuditHD/GetAuditHD', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Audit Data:', error);
        throw error;
    }
}

export async function createAuditHD(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AuditHD/CreateAuditHD', data, { headers: getAuthHeader() });
        if (data.NextID) {
            const payload = {
                id: 10,
                documentType: 'AuditNo',
                documentNo: data.NextID
            }
            await updateDocument(payload);
        }
        return response.data;
    } catch (error) {
        console.error('Error creating Audit:', error);
        throw error;
    }
}

export async function updateAuditHD(payload) {
    try {
        const response = await axios.post(API_BASE_URL + 'AuditHD/UpdateAuditHD', payload, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error updating Audit:', error);
        throw error;
    }
}

export async function deleteAuditHD(auditId) {
    try {
        const response = await axios.post(`${API_BASE_URL}AuditHD/DeleteAuditHD?auditHDId=${auditId}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Audit:', error);
        throw error;
    }
}

export async function GetAuditHDByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}AuditHD/GetAuditHDByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error get Insurance:', error);
        throw error;
    }
}

export async function getAuditLN() {
    try {
        const response = await axios.get(API_BASE_URL + 'AuditLN/GetAuditLN', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Audit Data:', error);
        throw error;
    }
}

export async function createAuditLN(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AuditLN/CreateAuditLN', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error creating Audit:', error);
        throw error;
    }
}

export async function createAuditLNList(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AuditLN/CreateAuditLNList', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error creating Audit:', error);
        throw error;
    }
}

export async function deleteAuditLN(auditId) {
    try {
        const response = await axios.post(`${API_BASE_URL}AuditLN/DeleteAuditLN?auditLNId=${auditId}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Audit LN:', error);
        throw error;
    }
}

export async function GetAuditLNByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}AuditLN/GetAuditLNByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error get Insurance:', error);
        throw error;
    }
}
// Audit History

export async function getAuditHistory() {
    try {
        const response = await axios.get(API_BASE_URL + 'AuditHistory/GetAuditHistory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Audit History Data:', error);
        throw error;
    }
}

export async function createAuditHistory(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'AuditHistory/CreateAuditHistory', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error creating Audit History:', error);
        throw error;
    }
}

// Import History

export async function getImportHistory() {
    try {
        const response = await axios.get(API_BASE_URL + 'ImportHistory/GetImportHistory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Import History Data:', error);
        throw error;
    }
}

export async function createImportHistory(data) {
    try {
        const response = await axios.post(API_BASE_URL + 'ImportHistory/CreateImportHistory', data, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error creating Import History:', error);
        throw error;
    }
}
import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from 'xlsx';
import { parse, format, set } from 'date-fns';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import LoadingSpinner from "../../../CommomPages/LoadingSpinner";
import { downloadSampleExcelAsset } from "../AssetCreation/ExportAssetCreation";

import { getSupplier } from "../../../Services/SupplierMasterService";
import { getDocument } from "../../../Services/GeneralSettingsService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";
import { createAsset, getImportHistory, createImportHistory } from "../../../Services/AssetSettingsService";
import { getAssetPrefix, getAssetPadding, getUnitID, getBranchID, getDepartmentID, getAssetDepreciation, getDepreciationMethod, getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";
import { ViewDetailsModal } from "../ActionFormPopup/ActionFormFormPopup";

import viewsvgIcon from "../../../assets/icon/Eye.svg";
import editsvgIcon from "../../../assets/icon/Edit.svg";
import download from "../../../assets/icon/download.svg";
import XMarksvgIcon from '../../../assets/icon/X mark.svg';
import nodataFound from "../../../assets/img/nodatafound.png";


const DataImport = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const paginateArray = [5, 10, 15];
    const fileAssetInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [userName, setUserName] = useState('');

    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");
    const [activeScreen, setActiveScreen] = useState('UploadFile');
    const [tabStatusActive, setTabStatusActive] = useState('Data Import');

    const [supplierArray, setSupplierArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);

    const depreciationMethodOptions = [
        { value: 'Declining Balance', label: 'Declining Balance' },
        { value: 'Straight Line', label: 'Straight Line' },
    ];

    const [fileName, setFileName] = useState('');
    const [totalRecordsCount, setTotalRecordsCount] = useState(0);
    const [totalSuccessCount, setTotalSuccessCount] = useState(0);
    const [totalFailedCount, setTotalFailedCount] = useState(0);
    const [assetArrayUpload, setAssetArrayUpload] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedMessages, setSelectedMessages] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            ImportTo: '',
            documentName: '',
        },
    });

    let localAssetDate = new Date();
    let offset = localAssetDate.getTimezoneOffset(); // Offset in minutes
    // Adjust for local time
    localAssetDate.setMinutes(localAssetDate.getMinutes() - offset);
    // Convert to ISO format without the 'Z'
    let localISODate = localAssetDate.toISOString().slice(0, -1);

    const watchDocumentName = watch('documentName');

    // Define dataInitialization as a regular async function
    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            await Promise.all([
                getUnitBranchDetails(),
                getEmployeeData(),
                getSupplierData(),
                getDepartmentData(),
                getCategoryAndBrandData(),
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dataInitialization();
        const user = localStorage.getItem('UserName');
        setUserName(user);
        setValue("ImportTo", "Asset");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getEmployeeData = async () => {
        const empData = await getEmployee();
        const finalData = empData.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp?.firstName + " " + Emp?.lastName,
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const getUnitBranchDetails = async () => {
        const unitData = await getBUData();
        setUnitArray(unitData);

        const branchData = await getBRData();
        setBranchArray(branchData);
    };

    const getDepartmentData = async () => {
        const data = await getDepartment();
        setDepartmentArray(data);
    };

    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        setSupplierArray(SupplierData);
    };

    const getCategoryAndBrandData = async () => {
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);

        const SubCategoryData = await getSubCategory();
        setSubCategoryArray(SubCategoryData);

        const BrandData = await getBrand();
        setBrandArray(BrandData);
    };


    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        setSearchQueryAsset(event.target.value);
        setPageAsset(0);
    };

    const filteredAsset = assetArrayUpload?.filter(
        (asset) =>
            asset &&
            (
                asset?.assetTagID?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                asset?.assetName?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
                asset?.validationStatus?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase())
            )
    );

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(assetArrayUpload?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = filteredAsset?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    const handleViewMessages = (messages) => {
        setSelectedMessages(messages);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMessages([]);
    };

    const closeFileUpload = () => {
        resetField('documentName');
        navigate('/Dashboard');
    };

    const closeVerifyAndImport = () => {
        resetField('documentName');
        setActiveScreen('UploadFile');
    };

    const closeImportResults = () => {
        resetField('documentName');
        navigate('/Dashboard');
    };

    const clearFileInput = () => {
        resetField('documentName');
        setSelectedFile(null);
    };

    const handleDownloadSampleExcel = () => {
        downloadSampleExcelAsset();
    };

    const handleDownloadFieldLimits = () => {
        // downloadFieldLimitsAsset();
    };

    const handleAssetImportClick = () => {
        fileAssetInputRef.current.click();
    };

    const handleAssetFileChange = async (event) => {
        const file = event.target.files[0];
        const fileType = file?.name.split('.').pop();

        if (file && (fileType === "xlsx" || fileType === "xls")) {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;
            setValue('documentName', fileDisplayText);
            setFileName(fileDisplayText);
            setSelectedFile(jsonData);

            event.target.value = null;
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const onProceedImport = async () => {
        const trimHeadersAndValues = (obj) => {
            const trimmedObj = {};
            Object.keys(obj).forEach(key => {
                const trimmedKey = key.trim();
                const trimmedValue = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
                trimmedObj[trimmedKey] = trimmedValue;
            });
            return trimmedObj;
        };

        const trimmedJsonData = selectedFile.map(trimHeadersAndValues);
        const unitIdConfig = await getUnitID();
        const branchIdConfig = await getBranchID();
        const departmentIdConfig = await getDepartmentID();
        const depreciableAssetValue = await getAssetDepreciation();
        const depreciationMethodValue = await getDepreciationMethod();

        let assetArrayData = [];

        trimmedJsonData.forEach((e, index) => {
            const rowIndex = index + 1;
            let rowStatus = "Success";
            let validationMessages = [];

            // Mandatory checks for assetName and categoryId
            const assetName = e?.['Asset Name'];
            if (!assetName) {
                rowStatus = "Fail";
                validationMessages.push(`Asset Name is required in row ${rowIndex}.`);
            }

            if (e?.Category === '') {
                rowStatus = "Fail";
                validationMessages.push(`Category is required in row ${rowIndex}.`);
            }

            // fields validation (only if they have values)
            const assetTagID = e?.['Asset Tag ID'] ? String(e['Asset Tag ID']) : null;

            const categoryId = findCategoryIdByName(categoryArray, e?.Category);
            if (e?.Category && !categoryId) {
                rowStatus = "Fail";
                validationMessages.push(`Category is invalid in row ${rowIndex}.`);
            }

            const subCategoryId = e?.['Sub Category'] ? findSubCategoryIdByName(subCategoryArray, e['Sub Category']) : null;
            if (e?.['Sub Category'] && !subCategoryId) {
                rowStatus = "Fail";
                validationMessages.push(`Sub Category is invalid in row ${rowIndex}.`);
            }

            const brandId = e?.Brand ? findBrandIdByName(brandArray, e.Brand) : null;
            if (e?.Brand && !brandId) {
                rowStatus = "Fail";
                validationMessages.push(`Brand is invalid in row ${rowIndex}.`);
            }

            const supplierId = e?.['Purchased from'] ? findSupplierIdByName(supplierArray, e['Purchased from']) : null;
            if (e?.['Purchased from'] && !supplierId) {
                rowStatus = "Fail";
                validationMessages.push(`Supplier is invalid in row ${rowIndex}.`);
            }

            const unitId = e?.Unit ? findUnitIdByName(unitArray, e.Unit) : unitIdConfig;
            if (e?.Unit && !unitId) {
                rowStatus = "Fail";
                validationMessages.push(`Unit is invalid in row ${rowIndex}.`);
            }

            const branchId = e?.Branch ? findBranchIdByName(branchArray, e.Branch) : branchIdConfig;
            if (e?.Branch && !branchId) {
                rowStatus = "Fail";
                validationMessages.push(`Branch is invalid in row ${rowIndex}.`);
            }

            const departmentId = e?.Department ? findDepartmentIdByName(departmentArray, e.Department) : departmentIdConfig;
            if (e?.Department && !departmentId) {
                rowStatus = "Fail";
                validationMessages.push(`Department is invalid in row ${rowIndex}.`);
            }

            const empId = e?.['Assigned to person'] ? findEmployeeIdByName(employeeArray, e['Assigned to person']) : null;
            if (e?.['Assigned to person'] && !empId) {
                rowStatus = "Fail";
                validationMessages.push(`Assigned to person is invalid in row ${rowIndex}.`);
            }

            const purchaseDate = e?.['Purchase Date'] ? formatDateToISO(e['Purchase Date']) : null;
            if (e?.['Purchase Date'] && isNaN(Date.parse(purchaseDate))) {
                rowStatus = "Fail";
                validationMessages.push(`Invalid Purchase Date format in row ${rowIndex}.`);
            }

            const dateAcquired = e?.['Date Acquired'] ? formatDateToISO(e['Date Acquired']) : null;
            if (e?.['Date Acquired'] && isNaN(Date.parse(dateAcquired))) {
                rowStatus = "Fail";
                validationMessages.push(`Invalid Date Acquired format in row ${rowIndex}.`);
            }

            const modelNo = e?.Model ? String(e.Model) : null;

            const serialNo = e?.['Serial No'] ? String(e['Serial No']) : null;

            const cost = e?.Cost || null;
            if (cost && isNaN(parseFloat(cost))) {
                rowStatus = "Fail";
                validationMessages.push(`Cost should be a numeric value in row ${rowIndex}.`);
            }

            const depreciableAsset = e?.['Depreciable Asset']?.toString().toLowerCase() === "true" ? "Yes" : "No" || depreciableAssetValue;

            const depreciableCost = e?.['Depreciable Cost'] || "";
            if (depreciableCost && isNaN(parseFloat(depreciableCost))) {
                rowStatus = "Fail";
                validationMessages.push(`Depreciable Cost should be a numeric value in row ${rowIndex}.`);
            }

            const assetLife = e?.['Asset Life (months)'] || "";
            if (assetLife && isNaN(parseInt(assetLife))) {
                rowStatus = "Fail";
                validationMessages.push(`Asset Life should be an integer value in row ${rowIndex}.`);
            }

            const salvageValue = e?.['Salvage Value'] || "";
            if (salvageValue && isNaN(parseFloat(salvageValue))) {
                rowStatus = "Fail";
                validationMessages.push(`Salvage Value should be a numeric value in row ${rowIndex}.`);
            }

            const validDepreciationMethods = depreciationMethodOptions.map(option => option.value);
            const depreciationMethod = e?.['Depreciation Method'] ? String(e['Depreciation Method']) : depreciationMethodValue;
            if (depreciationMethod && !validDepreciationMethods.includes(depreciationMethod)) {
                rowStatus = "Fail";
                validationMessages.push(`Depreciation Method is invalid in row ${rowIndex}.`);
            }

            // Construct the new row object with validation status, message, and other row values
            const newRow = {
                ...e,
                rowIndex: rowIndex,
                validationStatus: rowStatus,
                validationMessages: validationMessages.join(" "),
                assetName: assetName || '',
                assetTagID: assetTagID || '',
                categoryId: categoryId || 0,
                subCategoryId: subCategoryId || 0,
                brandId: brandId || 0,
                supplierId: supplierId || 0,
                unitId: unitId || 0,
                branchId: branchId || 0,
                departmentId: departmentId || 0,
                empId: empId || 0,
                purchaseDate: purchaseDate || null,
                dateAcquired: dateAcquired || null,
                modelNo: modelNo || '',
                serialNo: serialNo || '',
                cost: cost || '',
                depreciableAsset: depreciableAsset || 'No',
                depreciableCost: depreciableCost || '',
                assetLife: assetLife || '',
                salvageValue: salvageValue || '',
                depreciationMethod: depreciationMethod || '',
            };

            // Push each row with its validation Status and messages into the assetArrayData
            assetArrayData.push(newRow);
        });
        console.log("assetArrayData", assetArrayData);
        // Update state with the processed data
        setAssetArrayUpload(assetArrayData);
        setActiveScreen('VerifyAndImport');
    };


    const uploadAssetData = async () => {
        try {
            setIsLoading(true);
            let totalUploadCount = 0;
            let successUploadCount = 0;
            let failedUploadCount = 0;

            const assetPrefix = await getAssetPrefix();
            const assetPadding = await getAssetPadding();
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 3);
            let newId = maxId.documentNo + 1;

            const filteredUploadArray = assetArrayUpload?.filter(item => item.validationStatus === "Success");
            const uploadPromises = filteredUploadArray?.map(async (e, index) => {
                try {
                    const payload = {
                        id: 0,
                        assetID: `${assetPrefix.toUpperCase()}${zeros}${newId}`,
                        assetName: e?.assetName,
                        assetTagID: e?.assetTagID,
                        categoryId: e?.categoryId,
                        subCategoryId: e?.subCategoryId,
                        brandId: e?.brandId,
                        modelNo: e?.modelNo,
                        serialNo: e?.serialNo,
                        cost: e?.cost,
                        supplierId: e?.supplierId,
                        purchaseDate: e?.purchaseDate,
                        unitId: e?.unitId,
                        branchId: e?.branchId,
                        departmentId: e?.departmentId,
                        empId: e?.empId,
                        assetImage: "",
                        depreciableAsset: e?.depreciableAsset,
                        depreciableCost: e?.depreciableCost,
                        assetLife: e?.assetLife,
                        salvageValue: e?.salvageValue,
                        depreciationMethod: e?.depreciationMethod,
                        dateAcquired: e?.dateAcquired,
                        status: true,
                        createdBy: userName,
                        NextID: newId,
                    };

                    await createAsset(payload);
                    successUploadCount++;
                    newId++; // Increment newId only when the asset is successfully uploaded
                } catch (error) {
                    failedUploadCount++;
                }
                totalUploadCount++
            });

            await Promise.all(uploadPromises);
            setPageAsset(0);
            setTotalRecordsCount(totalUploadCount);
            setTotalSuccessCount(successUploadCount);
            setTotalFailedCount(failedUploadCount);
            setIsLoading(false);

            if (successUploadCount > 0) {
                toast.success(`${successUploadCount} assets uploaded successfully.`);
            }
            if (failedUploadCount > 0) {
                toast.error(`${failedUploadCount} assets failed to upload.`);
            }
            const importHistoryPayload = {
                id: 0,
                importTo: 'Asset',
                documentName: fileName || '',
                totalRecordsCount: totalUploadCount || 0,
                successCount: successUploadCount || 0,
                failureCount: failedUploadCount || 0,
                importedBy: userName || '',
                importedDate: localISODate || null,
            };

            await createImportHistory(importHistoryPayload);
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
            setActiveScreen('ImportResults');
        }
    };

    // Function to reformat the date
    const formatDateToISO = (dateString) => {
        // List of possible date formats
        const possibleFormats = ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd'];
        let parsedDate;

        // Try each format until one successfully parses the date
        for (const formatString of possibleFormats) {
            try {
                parsedDate = parse(dateString, formatString, new Date());

                // Check if the parsed date is valid
                if (parsedDate instanceof Date && !isNaN(parsedDate)) {
                    return format(parsedDate, 'yyyy-MM-dd'); // Return ISO formatted date
                }
            } catch (error) {
                // Ignore the error and try the next format
            }
        }

        // Return null or throw an error if no format matched
        return null;
    };

    const findCategoryIdByName = (array, categoryName) => {
        const record = array?.find(item => item.categoryName === categoryName);
        return record ? record.id : null;
    };

    const findSubCategoryIdByName = (array, subCategoryName) => {
        const record = array?.find(item => item.subCategoryName === subCategoryName);
        return record ? record.id : null;
    };

    const findBrandIdByName = (array, brandName) => {
        const record = array?.find(item => item.brandName === brandName);
        return record ? record.id : null;
    };

    const findSupplierIdByName = (array, supplierName) => {
        const record = array?.find(item => item.supplierName === supplierName);
        return record ? record.id : null;
    };

    const findUnitIdByName = (array, bU_NAME) => {
        const record = array?.find(item => item.bU_NAME === bU_NAME);
        return record ? record.bU_ID : null;
    };

    const findBranchIdByName = (array, bR_NAME) => {
        const record = array?.find(item => item.bR_NAME === bR_NAME);
        return record ? record.bR_ID : null;
    };

    const findDepartmentIdByName = (array, departmentName) => {
        const record = array?.find(item => item.departmentName === departmentName);
        return record ? record.id : null;
    };

    const findEmployeeIdByName = (array, EmployeeName) => {
        const record = array?.find(item => item.EmployeeName === EmployeeName);
        return record ? record.id : null;
    };


    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleAsset">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span>Import &gt;&gt;</span>
                                <span> {tabStatusActive}</span>
                            </span>
                            
                            <div><InfoOutlinedIcon /></div></div>
                    </div>
                    {activeScreen === "UploadFile" && (
                        <div className="card mt-4">
                            <div className="cardBodyPadding">
                                <div className="" style={{ paddingTop: "20px" }} >
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                        Step 1 : Upload File
                                    </div>
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ padding: '20px 0px 20px 20px' }}>
                                        Import assets using an Excel spreadsheet. Download our template, fill it in and upload. Also download ‘Field Limits Info’ to make sure  your data is within
                                        character limits for all fields. There is no limit on the number of assets you ca have. But you can import up to 5,000 records in one spreadsheet.
                                    </div>
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ padding: '0 0 20px 20px' }}>
                                        If you need assistance in uploading your assets, please feel free to email your spreadsheet to{' '}
                                        <a href="mailto:support@itech.com" className="email-link">support@itech.com</a>
                                    </div>
                                    <div style={{ padding: '0 0 20px 20px' }}>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >Import To</div>
                                                <input
                                                    className="textinputCustomAsset"
                                                    {...register('ImportTo')}
                                                    disabled={true}
                                                />
                                                {errors.ImportTo && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.ImportTo.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12 pTop24">
                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 importBtn"
                                                    onClick={handleDownloadSampleExcel}
                                                >
                                                    <img src={download} alt="download" height={15} style={{ marginRight: '5px' }} />
                                                    Download Template
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 importBtn"
                                                    onClick={handleDownloadFieldLimits}
                                                >
                                                    <img src={download} alt="download" height={15} style={{ marginRight: '5px' }} />
                                                    Download Field Limits
                                                </button> */}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >Select File<span className="mandatoryCss"> *</span></div>
                                                <div style={{ position: 'relative', marginTop: "5px" }}>
                                                    <input
                                                        placeholder="No file chosen"
                                                        {...register('documentName', { required: "Please Add Document" })}
                                                        className="textinputCustomAsset"
                                                        disabled={true}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={clearFileInput}
                                                        style={{
                                                            position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: 'pointer'
                                                        }}
                                                    >
                                                        {watchDocumentName && <img src={XMarksvgIcon} alt="clear" />}
                                                    </button>
                                                </div>
                                                {errors.documentName && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.documentName.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12 pTop30">
                                                <input
                                                    type="file"
                                                    ref={fileAssetInputRef}
                                                    style={{ display: 'none' }}
                                                    accept=".xlsx"
                                                    onChange={handleAssetFileChange}
                                                />
                                                <button
                                                    type="button"
                                                    className="coloredButtonUpload"
                                                    onClick={handleAssetImportClick}
                                                >
                                                    Upload File
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="coloredButtonSave"
                                                    style={{ marginLeft: '6%' }}
                                                    onClick={handleSubmit(onProceedImport)}
                                                >
                                                    Proceed
                                                </button>
                                                <button
                                                    type="button"
                                                    className="outlineButton"
                                                    style={{ marginLeft: '2%' }}
                                                    onClick={closeFileUpload}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "VerifyAndImport" && (
                        <div className="card mt-4 customScrollbar">
                            <div className="cardBodyPadding">
                                <div className="" style={{ paddingTop: "20px" }} >
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                        Step 2 : Verify and Import
                                    </div>
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ padding: '20px 0px 20px 20px' }}>
                                        Please review the data to be imported, shown in the grid below.  Each record includes failure information displayed next to it. Confirm the accuracy of the data before proceeding with the import.
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px" }}>
                                        <div className="custom-container">
                                            <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                <span className="show">Show</span>
                                                <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                    {paginateArray.map((n) => (
                                                        <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                            {n}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                    Entries
                                                </span>
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={searchQueryAsset}
                                                onChange={handleSearchChangeAsset}
                                                className="searchTextBox"
                                            />
                                        </div>
                                    </div>

                                    <div className="p-1">
                                        <div className="EmpBoxShadow">
                                            <div className="" style={{ padding: "10px" }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left empTableHeight">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "15%", padding: '2px 2px 2px 1%' }} > Asset Tag ID </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "15%" }} > Asset Description </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "15%" }} > Validation </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "15%" }} >  </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredAsset.length > 0 ? (
                                                            filteredAsset.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((Asset, index) => (
                                                                <tr key={Asset.rowIndex} style={{ height: "59px" }}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Asset.assetTagID}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Asset.assetName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`}
                                                                        style={{ color: Asset.validationStatus === 'Success' ? '#2ED47A' : '#EC2020' }}>{Asset.validationStatus}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`}>
                                                                        <span
                                                                            style={{
                                                                                cursor: Asset.validationStatus === 'Success' ? 'not-allowed' : 'pointer',
                                                                                color: Asset.validationStatus === 'Success' ? '#A9A9A9' : '#03ABFF',
                                                                                pointerEvents: Asset.validationStatus === 'Success' ? 'none' : 'auto'
                                                                            }}
                                                                            onClick={() => Asset.validationStatus !== 'Success' && handleViewMessages(Asset.validationMessages)}
                                                                        >
                                                                            View Details
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {totalEntriesAsset > 0 && (
                                            <div>
                                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                    <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                        Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                                    </div>
                                                    <div>
                                                        <div className="pagination-container d-flex align-items-center">
                                                            <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                            </button>
                                                            <ReactPaginate
                                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                                forcePage={pageAsset}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handleChangePagePaginationAsset}
                                                                containerClassName={"pagination"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"}
                                                                renderOnZeroPageCount={null}
                                                            />
                                                            <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "0px 100px" }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ marginTop: "8%", display: "flex", justifyContent: "center", paddingTop: 15, paddingBottom: 10, }}>
                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonSave ButtonSpace" onClick={uploadAssetData}>
                                        Save
                                    </button>
                                    <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeVerifyAndImport}>Cancel</button>
                                </div>
                            </div>
                            <ViewDetailsModal
                                show={showModal}
                                onClose={handleCloseModal}
                                messages={selectedMessages}
                            />
                        </div>
                    )}

                    {activeScreen === "ImportResults" && (
                        <div className="card mt-4">
                            <div className="cardBodyPadding">
                                <div className="" style={{ paddingTop: "20px" }} >
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                        Step 3 : Import Results
                                    </div>
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ padding: '20px 0px 20px 20px' }}>
                                        Import results are ready.  A total of {totalRecordsCount} records were processed.  Click 'View / Download Details' for a full report on successful and failed imports.
                                    </div>

                                    <div className="p-1">
                                        <div className="EmpBoxShadow">
                                            <div className="" style={{ padding: "10px" }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left empTableHeight">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "12%", padding: '2px 2px 2px 1%' }} > File Name </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Total Records </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Success </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Failure </th>
                                                            {/* <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Action </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ height: "59px" }}>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}
                                                                style={{ width: "12%", padding: "2px 2px 2px 3%", maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                title={fileName}>{fileName}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} >{totalRecordsCount}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} >{totalSuccessCount}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`} >{totalFailedCount}</td>
                                                            {/* <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "#03ABFF" }}
                                                                // onClick={() => handleViewDetails(validationMessages)}
                                                                >
                                                                    View
                                                                </span>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "#03ABFF", marginLeft: "5px" }}
                                                                // onClick={() => handleDownloadDetails(validationMessages)}
                                                                >
                                                                    Download
                                                                </span>
                                                            </td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "8%", display: "flex", justifyContent: "center", paddingTop: 15, paddingBottom: 10, }}>
                                    <button type="button" className="outlineButton" onClick={closeImportResults}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeScreen === "ImportHistory" && (
                        <div className="card mt-4">
                            <div className="cardBodyPadding">
                                <div id="printableContent">
                                    <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: "8px" }}>
                                        <div className="custom-container">
                                            <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                <span className="show">Show</span>
                                                <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                    {paginateArray.map((n) => (
                                                        <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                            {n}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                    Entries
                                                </span>
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={searchQueryAsset}
                                                onChange={handleSearchChangeAsset}
                                                className="searchTextBox"
                                            />
                                        </div>
                                    </div>

                                    <div className="p-1">
                                        <div className="EmpBoxShadow">
                                            <div className="" style={{ padding: "10px" }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left empTableHeight">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "12%", padding: '2px 2px 2px 1%' }} > Audit Name </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Status </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Audit Created </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Audit Created By </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "14%" }} > Audit Duration </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Lock / Unlock </th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "8%" }} > Action </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredAsset.length > 0 ? (
                                                            filteredAsset.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((Asset, index) => (
                                                                <tr key={Asset.Rowid} style={{ height: "59px" }}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} ><a href={`/AssetDetailsView?id=${Asset.id}`}><span style={{ color: "#605BFF", width: "12%", padding: "2px 2px 2px 3%" }}>{Asset.assetID}</span></a></td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Asset.assetName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Asset.assetStatusValue}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} style={{ color: "#605BFF" }}>{Asset.assetName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Asset.formatedPurchaseDate} - {Asset.formatedPurchaseDate}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`}
                                                                        style={{ color: Asset.status === true ? '#2ED47A' : '#03ABFF' }}>{Asset.assetStatusValue}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >
                                                                        <span style={{ cursor: "pointer" }}><a href={`/AssetCreationAdd?id=${Asset.id}&view=1`}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href={`/AssetCreationAdd?id=${Asset.id}&view=0`}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {totalEntriesAsset > 0 && (
                                            <div>
                                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                    <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                        Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                                    </div>
                                                    <div>
                                                        <div className="pagination-container d-flex align-items-center">
                                                            <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                            </button>
                                                            <ReactPaginate
                                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                                forcePage={pageAsset}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handleChangePagePaginationAsset}
                                                                containerClassName={"pagination"}
                                                                subContainerClassName={"pages pagination"}
                                                                activeClassName={"active"}
                                                                renderOnZeroPageCount={null}
                                                            />
                                                            <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "0px 100px" }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DataImport;

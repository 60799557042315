import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from 'react-router-dom';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import LoadingSpinner from "../../../CommomPages/LoadingSpinner";
import { getAssetPrefix, getAssetPadding, getUnitID, getBranchID, getDepartmentID, getAssetDepreciation, getDepreciationMethod, getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";
import { getSupplier } from "../../../Services/SupplierMasterService";
import { getDocument } from "../../../Services/GeneralSettingsService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getBUData, getBRData, } from "../../../Services/BusinessEntityNewService";
import { getAsset, createAsset, updateAsset, CreateFinancialTransaction, CreateAssetHistory } from "../../../Services/AssetSettingsService";
import { getCategory, getSubCategory, getBrand } from "../../../Services/AssetCategoriesAndBrandService";

import "./AssetCreationCss.css";

import uploadImg from "../../../assets/img/uploadImage.png";
import datesvgIcon from "../../../assets/icon/CalendarWithBG.svg";
import rupeeWithBG from "../../../assets/icon/RupeeWithBG.svg";

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Nunito, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        color: "#B0ACAC",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '275px'
    // }),
};


const AssetCreationAdd = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [assetId, setAssetId] = useState(0);
    const [dateFormat, setDateFormat] = useState('');
    const [documentID, setDocumentID] = useState(0);
    const [isAssetView, setIsAssetView] = useState(false);
    const [userName, setUserName] = useState('');
    const [supplierArray, setSupplierArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [businessUnitArray, setBusinessUnitArray] = useState([]);
    const [businessBranchArray, setBusinessBranchArray] = useState([]);
    const [currentAssetData, setCurrentAssetData] = useState('');

    const depreciationMethodOptions = [
        { value: 'Declining Balance', label: 'Declining Balance' },
        { value: 'Straight Line', label: 'Straight Line' },
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
        getValues,
        resetField,
    } = useForm({
        defaultValues: {
            assetName: "",
            assetID: "",
            assetTagID: "",
            categoryId: 0,
            subCategoryId: 0,
            brandId: 0,
            modelNo: "",
            serialNo: "",
            cost: "",
            supplierId: 0,
            purchaseDate: null,
            unitId: 0,
            branchId: 0,
            departmentId: 0,
            empId: 0,
            assetImage: "",
            depreciableAsset: "",
            depreciableCost: "",
            assetLife: "",
            salvageValue: "",
            depreciationMethod: "",
        },
    });

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    let localAssetDate = new Date();
    let offset = localAssetDate.getTimezoneOffset(); // Offset in minutes
    // Adjust for local time
    localAssetDate.setMinutes(localAssetDate.getMinutes() - offset);
    // Convert to ISO format without the 'Z'
    let localISODate = localAssetDate.toISOString().slice(0, -1);

    // Watch the assetImage value
    const assetImage = watch("assetImage");

    // Watch the value of depreciableAsset
    const depreciableAsset = watch("depreciableAsset");

    // Retrieve the current value of assetID
    const assetIdValue = getValues("assetID");

    const costValue = parseFloat(getValues("depreciableCost"));
    const purchaseDateValue = getValues("purchaseDate");
    const [depreciableCost, setDepreciableCost] = useState(costValue);
    const [purchaseDate, setPurchaseDate] = useState(purchaseDateValue);

    useEffect(() => {
        setDepreciableCost(costValue);
    }, [costValue]);

    useEffect(() => {
        setPurchaseDate(purchaseDateValue);
    }, [purchaseDateValue]);

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const idValue = queryParams.get('id');
        const viewValue = queryParams.get('view');
        if (idValue !== "0") {
            const parcedAssetId = parseInt(idValue, 10);
            setAssetId(parcedAssetId);
            bindAssetValues(parcedAssetId, viewValue);
        } else {
            getAssetDocNo();
            fetchAssetAssociation();
            fetchAssetDepreciation();
        }
        const user = localStorage.getItem('UserName');
        setUserName(user);
        dataInitialization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Define dataInitialization as a regular async function
    const dataInitialization = useCallback(async () => {
        try {
            setIsLoading(true);
            getBusinessBranch();
            await Promise.all([
                getBusinessUnit(),
                getDepartmentData(),
                getSupplierData(),
                getEmployeeData(),
                getCategoryAndBrandData(),
            ]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, []); // Add an empty dependency array to memoize the function


    const bindAssetValues = async (paramAssetId, viewValue) => {
        try {
            setIsLoading(true);
            const assetView = viewValue === "1";
            setIsAssetView(assetView);

            const data = await getAsset();
            const currentAsset = data.find(e => e.id === paramAssetId);
            setCurrentAssetData(currentAsset);
            if (currentAsset) {
                setValue("assetName", currentAsset.assetName);
                setValue("assetID", currentAsset.assetID);
                setValue("assetTagID", currentAsset.assetTagID);
                setValue("categoryId", currentAsset.categoryId);
                setValue("subCategoryId", currentAsset.subCategoryId);
                setValue("brandId", currentAsset.brandId);
                setValue("modelNo", currentAsset.modelNo);
                setValue("serialNo", currentAsset.serialNo);
                setValue("cost", currentAsset.cost);
                setValue("supplierId", currentAsset.supplierId);
                if (currentAsset.purchaseDate != null) {
                    setValue("purchaseDate", currentAsset.purchaseDate.split('T')[0]);
                } else {
                    setValue("purchaseDate", null);
                }
                // setValue("unitId", currentAsset.unitId);
                // setValue("branchId", currentAsset.branchId);
                // setValue("departmentId", currentAsset.departmentId);
                // setValue("empId", currentAsset.empId);
                setValue("assetImage", currentAsset.assetImage);
                setValue("depreciableAsset", currentAsset.depreciableAsset);
                if (currentAsset.depreciableAsset === "Yes") {
                    setValue("depreciableCost", currentAsset.depreciableCost);
                    setValue("assetLife", currentAsset.assetLife);
                    setValue("salvageValue", currentAsset.salvageValue);
                    setValue("depreciationMethod", currentAsset.depreciationMethod);
                }
            }
        } catch (error) {
            console.error("Error fetching Asset data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getBusinessUnit = async () => {
        const data = await getBUData();
        const finalData = data?.map((unit, index) => {
            return {
                ...unit,
                Rowid: index + 1,
            };
        });
        setBusinessUnitArray(finalData);
    };

    const getBusinessBranch = async () => {
        const data = await getBRData();
        const finalData = data?.map((branch, index) => {
            return {
                ...branch,
                Rowid: index + 1,
            };
        });
        setBusinessBranchArray(finalData);
    };

    const getDepartmentData = async () => {
        const data = await getDepartment();
        const finalData = data?.map((dept, index) => {
            return {
                ...dept,
                Rowid: index + 1,
            };
        });
        setDepartmentArray(finalData);
    };

    const getEmployeeData = async () => {
        const empData = await getEmployee();
        const finalData = empData?.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp?.firstName + " " + Emp?.lastName
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const getSupplierData = async () => {
        const SupplierData = await getSupplier();
        setSupplierArray(SupplierData);

        const dateCurrentFormat = await getDateFormat();
        setDateFormat(dateCurrentFormat);
    };

    const getCategoryAndBrandData = async () => {
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);

        const SubCategoryData = await getSubCategory();
        setSubCategoryArray(SubCategoryData);

        const BrandData = await getBrand();
        setBrandArray(BrandData);
    };

    const getAssetDocNo = async () => {
        try {
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 3);
            const newId = maxId.documentNo + 1;
            setDocumentID(newId);

            const assetPrefix = await getAssetPrefix();
            const assetPadding = await getAssetPadding();
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            setValue("assetID", `${assetPrefix.toUpperCase()}${zeros}${newId}`);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchAssetAssociation = async () => {
        const unitId = await getUnitID();
        setValue("unitId", unitId);

        const branchId = await getBranchID();
        setValue("branchId", branchId);

        const departmentId = await getDepartmentID();
        setValue("departmentId", departmentId);
    };

    const fetchAssetDepreciation = async () => {
        const depreciableAssetValue = await getAssetDepreciation();
        setValue("depreciableAsset", depreciableAssetValue);

        const depreciationMethod = await getDepreciationMethod();
        setValue("depreciationMethod", depreciationMethod);
    };

    const handleCloseAssetCreation = () => {
        setTimeout(() => navigate("/AssetCreationView"), 2000);
        reset();
        setAssetId(0);
        resetField("assetID");
        setIsAssetView(false);
    };

    const findEmployeeNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.EmployeeName) : '';
    };

    const findUnitNameById = (id, array) => {
        const record = array?.find(item => item.bU_ID === id);
        return record ? String(record.bU_NAME) : '';
    };

    const findBranchNameById = (id, array) => {
        const record = array?.find(item => item.bR_ID === id);
        return record ? String(record.bR_NAME) : '';
    };

    const findDepartmentNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.departmentName) : '';
    };

    const findCategoryNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.categoryName) : '';
    };

    const findSubCategoryNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.subCategoryName) : '';
    };

    const findBrandNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.brandName) : '';
    };

    const findSupplierNameById = (id, array) => {
        const record = array?.find(item => item.id === id);
        return record ? String(record.supplierName) : '';
    };

    const handleAssetSubmit = async (data) => {
        const isUpdating = assetId !== 0;
        try {
            setIsLoading(true);
            const payload = {
                id: assetId || 0,
                assetName: data?.assetName || '',
                assetID: data?.assetID || '',
                assetTagID: data?.assetTagID || '',
                categoryId: data?.categoryId || 0,
                subCategoryId: data?.subCategoryId || 0,
                brandId: data?.brandId || 0,
                modelNo: data?.modelNo || '',
                serialNo: data?.serialNo || '',
                cost: data?.cost || '',
                supplierId: data?.supplierId || 0,
                purchaseDate: data?.purchaseDate || null,
                unitId: data?.unitId || 0,
                branchId: data?.branchId || 0,
                departmentId: data?.departmentId || 0,
                empId: data?.empId || 0,
                assetImage: data?.assetImage || '',
                depreciableAsset: data?.depreciableAsset || 'No',
                depreciableCost: data?.depreciableCost || '',
                assetLife: data?.assetLife || '',
                salvageValue: data?.salvageValue || '',
                depreciationMethod: data?.depreciationMethod || '',
                dateAcquired: data?.purchaseDate || null,
                status: true,
                createdBy: userName,
                updatedBy: userName,
                NextID: documentID
            };
            const response = isUpdating ? await updateAsset(payload) : await createAsset(payload);
            if (response === (isUpdating ? "Updated" : "Inserted")) {
                toast.success(`Asset ${isUpdating ? "Updated" : "Inserted"} Successfully`);
                const changedFields = [];
                // Compare each field in the payload with the corresponding field in currentAssetData
                for (const key in payload) {
                    if (payload.hasOwnProperty(key) && currentAssetData[key] !== payload[key] && key !== 'NextID') {
                        let changedFrom = currentAssetData[key] || '';
                        let changedTo = payload[key] || '';

                        // Check if the key is empId, unitId, branchId, or departmentId
                        if (key === 'empId') {
                            changedFrom = findEmployeeNameById(changedFrom, employeeArray);
                            changedTo = findEmployeeNameById(changedTo, employeeArray);
                        } else if (key === 'unitId') {
                            changedFrom = findUnitNameById(changedFrom, businessUnitArray);
                            changedTo = findUnitNameById(changedTo, businessUnitArray);
                        } else if (key === 'branchId') {
                            changedFrom = findBranchNameById(changedFrom, businessBranchArray);
                            changedTo = findBranchNameById(changedTo, businessBranchArray);
                        } else if (key === 'departmentId') {
                            changedFrom = findDepartmentNameById(changedFrom, departmentArray);
                            changedTo = findDepartmentNameById(changedTo, departmentArray);
                        } else if (key === 'categoryId') {
                            changedFrom = findCategoryNameById(changedFrom, categoryArray);
                            changedTo = findCategoryNameById(changedTo, categoryArray);
                        } else if (key === 'subCategoryId') {
                            changedFrom = findSubCategoryNameById(changedFrom, subCategoryArray);
                            changedTo = findSubCategoryNameById(changedTo, subCategoryArray);
                        } else if (key === 'brandId') {
                            changedFrom = findBrandNameById(changedFrom, brandArray);
                            changedTo = findBrandNameById(changedTo, brandArray);
                        } else if (key === 'supplierId') {
                            changedFrom = findSupplierNameById(changedFrom, supplierArray);
                            changedTo = findSupplierNameById(changedTo, supplierArray);
                        }

                        if (changedFrom !== changedTo) {
                            changedFields.push({
                                assetField: key,
                                assetChangedFrom: changedFrom,
                                assetChangedTo: changedTo
                            });
                        }
                    }
                }

                // Loop through each changed field and create history
                for (const change of changedFields) {
                    const historyPayload = {
                        id: 0,
                        assetId: assetId || 0,
                        assetDate: localISODate,
                        assetEvent: 'Update Asset',
                        assetField: change.assetField,
                        assetChangedFrom: String(change.assetChangedFrom),
                        assetChangedTo: String(change.assetChangedTo),
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayload);
                }

                if (payload.cost) {
                    const transactionPayload = {
                        id: 0,
                        ftAssetId: assetId || 0,
                        ftAssetCodeId: assetIdValue,
                        ftTransactionDate: localISODate,
                        ftDescription: 'Purchase of Asset',
                        ftTransaction: 'Purchase',
                        ftAmount: payload.cost,
                        ftPaymentType: 'Paid',
                    };
                    await CreateFinancialTransaction(transactionPayload);
                }

                if (payload.depreciableAsset === "Yes" && payload.depreciableCost) {
                    const transactionPayload = {
                        id: 0,
                        ftAssetId: assetId || 0,
                        ftAssetCodeId: assetIdValue,
                        ftTransactionDate: localISODate,
                        ftDescription: 'Depreciation of Asset',
                        ftTransaction: 'Depreciation',
                        ftAmount: payload.depreciableCost,
                        ftPaymentType: 'Paid',
                    };
                    await CreateFinancialTransaction(transactionPayload);
                }
                handleCloseAssetCreation();
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Something went wrong");
        } finally {
            setIsLoading(false);
            handleCloseAssetCreation();
        }
    };

    const fileUpload = (event) => {
        event.preventDefault();
        document.getElementById("uploadImgID").click();
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validFileTypes = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
            ];
            if (validFileTypes.includes(file.type)) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setValue("assetImage", reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Only JPG, GIF, and PNG formats are allowed.");
                // Clear the file input if the file type is invalid
                e.target.value = null;
            }
        } else {
            return
        }
    };

    const clearImage = (event) => {
        event.preventDefault();
        setValue("assetImage", "");
    };


    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleAsset">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span>Asset &gt;&gt;</span>
                                <span> Create Asset </span>
                            </span>
                            <div>
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    </div>
                    <div className="customScrollbar">
                        <div className="card mt-4">
                            <div className="card-body-AssetSettings">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Asset Creation
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmit(handleAssetSubmit)}>
                                    <div className="CustomBoxShadow">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                            Asset Details
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Asset Name<span className="mandatoryCss"> *</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("assetName", { required: "Please Enter Asset Name" })}
                                                    disabled={isAssetView}
                                                />
                                                {errors.assetName && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.assetName.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Asset ID
                                                </div>
                                                <input
                                                    type="text"
                                                    value={assetIdValue}
                                                    className="textinputCustomAssetCreate"
                                                    placeholder=""
                                                    {...register("assetID")}
                                                    disabled={isAssetView || true} // Auto-generated and should not be editable
                                                />
                                                {errors.assetID && (
                                                    <div className="invalid-feedback" style={{ display: "block" }} >
                                                        {errors.assetID.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "24px" }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Asset Tag ID: (Bar Code / QR Code Mapping ID)
                                                </div>
                                                <input
                                                    type="text"
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("assetTagID")}
                                                    disabled={isAssetView}
                                                />
                                                {errors.assetTagID && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.assetTagID.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Category<span className="mandatoryCss"> *</span>
                                                </div>
                                                <Controller
                                                    name="categoryId"
                                                    control={control}
                                                    rules={{
                                                        required: 'Asset Category is required',
                                                        validate: {
                                                            validDate: (value) => {
                                                                if (!value) return "Asset Category is required";
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={categoryArray.find(option => option.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={categoryArray}
                                                            placeholder=""
                                                            getOptionLabel={(option) => option.categoryName}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.categoryId && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.categoryId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "24px" }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Sub Category
                                                </div>
                                                <Controller
                                                    name="subCategoryId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={subCategoryArray.find(option => option.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={subCategoryArray}
                                                            placeholder=""
                                                            getOptionLabel={(option) => option.subCategoryName}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.subCategoryId && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.subCategoryId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Brand
                                                </div>
                                                <Controller
                                                    name="brandId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={brandArray.find(option => option.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={brandArray}
                                                            placeholder=""
                                                            getOptionLabel={(option) => option.brandName}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.brandId && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.brandId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "24px" }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Model No.
                                                </div>
                                                <input
                                                    type="text"
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("modelNo")}
                                                    disabled={isAssetView}
                                                />
                                                {errors.modelNo && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.modelNo.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Serial No.
                                                </div>
                                                <input
                                                    type="text"
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("serialNo")}
                                                    disabled={isAssetView}
                                                />
                                                {errors.serialNo && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.serialNo.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "24px" }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Cost
                                                </div>
                                                <input
                                                    type="number"
                                                    className="textinputCustomAsset"
                                                    placeholder=""
                                                    {...register("cost")}
                                                    disabled={isAssetView}
                                                />
                                                {errors.cost && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.cost.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Supplier Name
                                                </div>
                                                <Controller
                                                    name="supplierId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={supplierArray.find((c) => c.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={supplierArray}
                                                            getOptionLabel={(option) => option.supplierName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.supplierId && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.supplierId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "24px" }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                    Purchase Date
                                                </div>
                                                <div className="d-flex">
                                                    <Controller
                                                        name="purchaseDate"
                                                        control={control}
                                                        rules={{
                                                            validate: {
                                                                validDate: (value) => {
                                                                    // If the value is empty, it's valid because the field is optional
                                                                    if (!value) {
                                                                        return true;
                                                                    }

                                                                    // Check if the value is a valid date object
                                                                    const dateValue = new Date(value);
                                                                    if (isNaN(dateValue.getTime())) {
                                                                        return "Invalid date value";
                                                                    }

                                                                    // Format the date and validate it
                                                                    const formattedDate = format(dateValue, dateFormat);
                                                                    const [day, month, year] = formattedDate.split('/');
                                                                    const selectedDate = validateDate(dateFormat, day, month, year);

                                                                    // Get today's date for comparison
                                                                    const today = new Date();
                                                                    today.setHours(0, 0, 0, 0);

                                                                    // Check if selected date is in the future
                                                                    if (selectedDate > today) {
                                                                        return "Purchase Date cannot be in the future";
                                                                    }

                                                                    // Validate date format using regex
                                                                    const regex = getRegexForDateFormat(dateFormat);
                                                                    if (!regex.test(formattedDate)) {
                                                                        return "Invalid date format (" + dateFormat + ")";
                                                                    }
                                                                    return true;
                                                                },
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                selected={purchaseDate || field.value}
                                                                onChange={(date) => {
                                                                    field.onChange(date);
                                                                    setPurchaseDate(date);
                                                                }}
                                                                dateFormat={dateFormat}
                                                                className={isAssetView ? "customDatePickerDisable date-picker-input" : "customDatePicker date-picker-input"}
                                                                disabled={isAssetView}
                                                                ref={(input) => field.ref(input?.inputElement)}
                                                            />
                                                        )}
                                                    />
                                                    <img src={datesvgIcon} alt="datesvgIcon" height={45} />
                                                </div>
                                                {errors.purchaseDate && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.purchaseDate.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6"></div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                            Asset Association
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Unit
                                                </div>
                                                <Controller
                                                    name="unitId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={businessUnitArray.find((c) => c.bU_ID === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                            }}
                                                            options={businessUnitArray}
                                                            getOptionLabel={(option) => option.bU_NAME}
                                                            getOptionValue={(option) => option.bU_ID}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.unitId && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.unitId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Branch
                                                </div>
                                                <Controller
                                                    name="branchId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={businessBranchArray.find((c) => c.bR_ID === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                            }}
                                                            options={businessBranchArray}
                                                            getOptionLabel={(option) => option.bR_NAME}
                                                            getOptionValue={(option) => option.bR_ID}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.branchId && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.branchId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "24px" }}>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Department
                                                </div>
                                                <Controller
                                                    name="departmentId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={departmentArray.find((c) => c.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={departmentArray}
                                                            getOptionLabel={(option) => option.departmentName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.departmentId && (
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.departmentId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: "4px" }}>
                                                    Employee
                                                </div>
                                                <Controller
                                                    name="empId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={employeeArray.find(emp => emp.id === field.value) || null}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                            }}
                                                            options={employeeArray}
                                                            getOptionLabel={(option) => option.EmployeeName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder=""
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isAssetView}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.empId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.empId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Asset Image
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                            {assetImage ? (<img src={assetImage} alt="assetImage" height={150} />) : (<img src={uploadImg} alt="uploadImg" height={150} />)}
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                            <div className="col-5 col-md-5 col-lg-5 col-sm-12" style={{ paddingTop: "30px" }}>
                                                <Controller
                                                    name="assetImage"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            type="file"
                                                            id="uploadImgID"
                                                            accept=".jpg,.jpeg,.png,.gif"
                                                            style={{ display: "none" }}
                                                            disabled={isAssetView}
                                                            onChange={(e) => {
                                                                handleFileInputChange(e);
                                                                field.onChange(e.target.files[0]);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                    {assetImage ? (
                                                        <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButton" onClick={clearImage}>
                                                            Clear
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            style={{ cursor: isAssetView ? "not-allowed" : "pointer", }}
                                                            disabled={isAssetView}
                                                            className="coloredButtonSave fontFamily fontweightRegular fontsize16"
                                                            onClick={fileUpload}
                                                        >
                                                            Browse
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px", }}>
                                            <div className="fontFamily fontweightRegular fontsize16 blackTextColor">
                                                Only (JPG, GIF, PNG) are allowed
                                            </div>
                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Asset Depreciation
                                        </div>
                                        <div className="d-flex mTop20 mBottom20">
                                            <div className="d-flex align-items-center">
                                                <Controller
                                                    name="depreciableAsset"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <div className="blackTextColor fontFamily fontsize16 fontweightRegular pRight45">
                                                                Depreciable Asset
                                                            </div>
                                                            <input
                                                                type="radio"
                                                                value="Yes"
                                                                checked={value === "Yes"}
                                                                onChange={(e) => {
                                                                    onChange(e.target.value);
                                                                }}
                                                                disabled={isAssetView}
                                                            />
                                                            <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12">
                                                                Yes
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="d-flex pLeft12 align-items-center">
                                                <Controller
                                                    name="depreciableAsset"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                value="No"
                                                                checked={value === "No"}
                                                                onChange={(e) => {
                                                                    onChange(e.target.value);
                                                                    if (e.target.value === "No") {
                                                                        resetField("depreciableCost");
                                                                        resetField("assetLife");
                                                                        resetField("salvageValue");
                                                                        resetField("depreciationMethod");
                                                                    }
                                                                }}
                                                                disabled={isAssetView}
                                                            />
                                                            <div className="blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12">
                                                                No
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {depreciableAsset === "Yes" && (
                                            <div >
                                                <div className="row mBottom20">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                                Depreciable Cost
                                                            </div>
                                                            <div className="fontFamily fontweightRegular fontsize14 grayTextColor" style={{ marginTop: "5px" }}>
                                                                Including sales tax, freight and installation
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <input
                                                                type="number"
                                                                placeholder=""
                                                                {...register("depreciableCost", {
                                                                    validate: (value) => {
                                                                        if (depreciableAsset === "Yes") {
                                                                            if (!value) {
                                                                                return "Depreciable Cost is required";
                                                                            }
                                                                            if (isNaN(value)) {
                                                                                return "Invalid Depreciable Cost, only numeric characters are allowed";
                                                                            }
                                                                            if (Number(value) < 0) {
                                                                                return "Depreciable Cost cannot be negative";
                                                                            }
                                                                            return true;
                                                                        }
                                                                        // If depreciableAsset is not "Yes", no validation error for this field
                                                                        return true;
                                                                    }
                                                                })}
                                                                className={isAssetView ? "textBoxNewView" : "textBoxNew"}
                                                                disabled={depreciableAsset !== "Yes" || isAssetView}
                                                            />
                                                            <img src={rupeeWithBG} alt="rupeeWithBG" height={45} />
                                                        </div>
                                                        {errors.depreciableCost && (
                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {errors.depreciableCost.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-6 col-md-6 col-lg-6">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                            Asset Life (months)
                                                        </div>
                                                        <input
                                                            type="number"
                                                            placeholder="0.00"
                                                            {...register("assetLife", {
                                                                validate: (value) => {
                                                                    if (depreciableAsset === "Yes") {
                                                                        if (value <= 0) {
                                                                            return "Asset Life must be greater than 0";
                                                                        }
                                                                        if (!Number.isInteger(Number(value))) {
                                                                            return "Asset Life must be a positive integer";
                                                                        }
                                                                        return true;
                                                                    }
                                                                    // If depreciableAsset is not "Yes", no validation error for this field
                                                                    return true;
                                                                }
                                                            })}
                                                            className={`input-number-custom ${isAssetView ? "textBoxNewView" : "textBoxNew"}`}
                                                            disabled={depreciableAsset !== "Yes" || isAssetView}
                                                        />
                                                        {errors.assetLife && (
                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {errors.assetLife.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mBottom20">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                            Salvage Value
                                                        </div>
                                                        <div className="d-flex">
                                                            <input
                                                                type="number"
                                                                placeholder="0.00"
                                                                {...register("salvageValue", {
                                                                    validate: (value) => {
                                                                        if (depreciableAsset === "Yes") {
                                                                            if (value) {
                                                                                // Check if the value is a valid number
                                                                                const salvageValue = parseFloat(value);
                                                                                if (isNaN(salvageValue)) {
                                                                                    return "Invalid Salvage Value, only numeric values are allowed";
                                                                                }

                                                                                // Check if the value is non-negative
                                                                                if (salvageValue < 0) {
                                                                                    return "Salvage Value cannot be negative";
                                                                                }

                                                                                // Check if the salvage value is greater than the depreciable cost
                                                                                const depreciableCost = parseFloat(getValues("depreciableCost"));
                                                                                if (salvageValue > depreciableCost) {
                                                                                    return "Salvage Value cannot be greater than Depreciable Cost";
                                                                                }
                                                                                return true;
                                                                            }
                                                                        }

                                                                        // If depreciableAsset is not "Yes", no validation error for this field
                                                                        return true;
                                                                    }
                                                                })}
                                                                className={isAssetView ? "textBoxNewView" : "textBoxNew"}
                                                                disabled={depreciableAsset !== "Yes" || isAssetView}
                                                            />
                                                            <img src={rupeeWithBG} alt="rupeeWithBG" height={45} />
                                                        </div>
                                                        {errors.salvageValue && (
                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {errors.salvageValue.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-6 col-md-6 col-lg-6">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                            Depreciation Method
                                                        </div>
                                                        <Controller
                                                            name="depreciationMethod"
                                                            control={control}
                                                            rules={{
                                                                validate: (value) => {
                                                                    if (depreciableAsset === "Yes" && !value) {
                                                                        return "Depreciation Method is required";
                                                                    }
                                                                    // If depreciableAsset is not "Yes", no validation error for this field
                                                                    return true;
                                                                }
                                                            }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={depreciationMethodOptions.find(option => option.value === field.value) || null}
                                                                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                                                                    options={depreciationMethodOptions}
                                                                    placeholder="Select an option"
                                                                    isSearchable
                                                                    onBlur={field.onBlur}
                                                                    className="fontFamily fontweightRegular fontsize16"
                                                                    styles={customStyles}
                                                                    isDisabled={depreciableAsset !== "Yes" || isAssetView}
                                                                />
                                                            )}
                                                        />
                                                        {errors.depreciationMethod && (
                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {errors.depreciationMethod.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mBottom20">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                                Date Acquired
                                                            </div>
                                                            <div className="fontFamily fontweightRegular fontsize14 grayTextColor" style={{ marginTop: "5px" }}>
                                                                Depreciation begin date
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <Controller
                                                                name="purchaseDate"
                                                                control={control}
                                                                rules={{
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (depreciableAsset === "Yes") {
                                                                                if (!value) {
                                                                                    return "Date Acquired is required";
                                                                                }

                                                                                // Check if value is a valid date
                                                                                const dateValue = new Date(value);
                                                                                if (isNaN(dateValue.getTime())) {
                                                                                    return "Invalid date value";
                                                                                }

                                                                                const formattedDate = format(dateValue, dateFormat);
                                                                                const [day, month, year] = formattedDate.split('/');
                                                                                const selectedDate = validateDate(dateFormat, day, month, year);
                                                                                const today = new Date();
                                                                                today.setHours(0, 0, 0, 0);

                                                                                if (selectedDate > today) {
                                                                                    return "Date Acquired cannot be in the future";
                                                                                }

                                                                                const regex = getRegexForDateFormat(dateFormat);
                                                                                if (!regex.test(formattedDate)) {
                                                                                    return `Invalid date format (${dateFormat})`;
                                                                                }
                                                                                return true;
                                                                            }
                                                                            // If depreciableAsset is not "Yes", no validation error for this field
                                                                            return true;
                                                                        }
                                                                    }
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => {
                                                                            field.onChange(date);
                                                                            setPurchaseDate(date);
                                                                        }}
                                                                        dateFormat={dateFormat}
                                                                        className={depreciableAsset !== "Yes" || isAssetView ? "customDatePickerDisable date-picker-input" : "customDatePicker date-picker-input"}
                                                                        disabled={depreciableAsset !== "Yes" || isAssetView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img src={datesvgIcon} alt="datesvgIcon" height={45} />
                                                        </div>
                                                        {errors.purchaseDate && (
                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {errors.purchaseDate.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-6 col-md-6 col-lg-6"></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div style={{ marginTop: "8%", display: "flex", justifyContent: "center", paddingTop: 15, paddingBottom: 10, }}>
                                        {!isAssetView && (
                                            <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonSave ButtonSpace" onClick={handleSubmit(handleAssetSubmit)}>
                                                Save
                                            </button>
                                        )}
                                        <a
                                            href="/AssetCreationView"
                                            type="button"
                                            id="btncloseModal"
                                            className="fontFamily fontweightRegular fontsize16 outlineButton"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            Cancel
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AssetCreationAdd;

import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import { parse, format, set } from 'date-fns';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import LoadingSpinner from "../../../CommomPages/LoadingSpinner";
import { getImportHistory } from "../../../Services/AssetSettingsService";
import { getDateFormat, getRegexForDateFormat, validateDate } from "../../../CommomPages/Config";

import nodataFound from "../../../assets/img/nodatafound.png";


const ImportHistory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const paginateArray = [5, 10, 15];

    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryFile, setSearchQueryFile] = useState("");
    const [searchQueryDate, setSearchQueryDate] = useState("");
    const [searchQueryUser, setSearchQueryUser] = useState("");
    const [tabStatusActive, setTabStatusActive] = useState('Import History');

    const [importHistoryArray, setImportHistoryArray] = useState([]);
    const [filteredImportHistory, setFilteredImportHistory] = useState([]);

    useEffect(() => {
        fetchImportHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchImportHistory = async () => {
        setIsLoading(true);
        const dateCurrentFormat = await getDateFormat();
        const importHistoryData = await getImportHistory();
        const finalData = importHistoryData.map((item, index) => {
            const formattedDate = item?.importedDate ? format(new Date(item.importedDate), dateCurrentFormat) : null;
            return {
                ...item,
                Rowid: index + 1,
                formatedImportedDate: formattedDate
            };
        });
        setImportHistoryArray(finalData);
        setFilteredImportHistory(finalData);
        setIsLoading(false);
    };


    // Section Search and Pagination
    const handleSearchChangeFile = (event) => {
        setSearchQueryFile(event.target.value);
        const filteredImportHistory = importHistoryArray?.filter((item) => item?.documentName?.toLowerCase()?.includes(event.target.value?.toLowerCase()));
        setFilteredImportHistory(filteredImportHistory);
        setPageAsset(0);
    };

    const handleSearchChangeDate = (event) => {
        setSearchQueryDate(event.target.value);
        const filteredImportHistory = importHistoryArray?.filter((item) => item?.formatedImportedDate?.toLowerCase()?.includes(event.target.value?.toLowerCase()));
        setFilteredImportHistory(filteredImportHistory);
        setPageAsset(0);
    };

    const handleSearchChangeUser = (event) => {
        setSearchQueryUser(event.target.value);
        const filteredImportHistory = importHistoryArray?.filter((item) => item?.importedBy?.toLowerCase()?.includes(event.target.value?.toLowerCase()));
        setFilteredImportHistory(filteredImportHistory);
        setPageAsset(0);
    };

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(importHistoryArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = filteredImportHistory?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleAsset">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span>Import &gt;&gt;</span>
                                <span> {tabStatusActive}</span>
                            </span>
                            <div><InfoOutlinedIcon /></div></div>
                    </div>

                    <div className="card mt-4">
                        <div className="cardBodyPadding">
                            <div className="row">
                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                        File
                                    </div>
                                    <input
                                        type="text"
                                        value={searchQueryFile}
                                        onChange={handleSearchChangeFile}
                                        className="textinputCustomAsset"
                                    />
                                </div>
                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                        Date Range
                                    </div>
                                    <input
                                        type="text"
                                        value={searchQueryDate}
                                        onChange={handleSearchChangeDate}
                                        className="textinputCustomAsset"
                                    />
                                </div>
                                <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                        User
                                    </div>
                                    <input
                                        type="text"
                                        value={searchQueryUser}
                                        onChange={handleSearchChangeUser}
                                        className="textinputCustomAsset"
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center" style={{ padding: "10px 0px" }}>
                                <div className="custom-container">
                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                        <span className="show">Show</span>
                                        <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                            {paginateArray.map((n) => (
                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                    {n}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                            Entries
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div className="p-1">
                                <div className="EmpBoxShadow">
                                    <div className="customScrollbar" style={{ padding: "10px" }}>
                                        <table className="w-100 mt-2">
                                            <thead>
                                                <tr className="text-left empTableHeight">
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG " style={{ width: "13%", padding: '2px 2px 2px 1%' }} > File Name </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Total Records </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Success </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Failure </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "12%" }} > Imported Date </th>
                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }} > User </th>
                                                    {/* <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "8%" }} > Action </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredImportHistory.length > 0 ? (
                                                    filteredImportHistory.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((item, index) => (
                                                        <tr key={item.Rowid} style={{ height: "59px" }}>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`}
                                                                style={{ width: "12%", padding: "2px 2px 2px 3%", maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                title={item.documentName}>{item.documentName}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{item.totalRecordsCount}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{item.successCount}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{item.failureCount}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{item.formatedImportedDate}</td>
                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`}
                                                                style={{ width: "12%", padding: "2px 2px 2px 3%", maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                title={item.importedBy}>{item.importedBy}</td>
                                                            {/* <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2`}>
                                                                        <span
                                                                            style={{ cursor: "pointer", color: "#03ABFF" }}
                                                                        // onClick={() => handleViewDetails(validationMessages)}
                                                                        >
                                                                            View
                                                                        </span>
                                                                        <span
                                                                            style={{ cursor: "pointer", color: "#03ABFF", marginLeft: "5px" }}
                                                                        // onClick={() => handleDownloadDetails(validationMessages)}
                                                                        >
                                                                            Download
                                                                        </span>
                                                                    </td> */}
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {totalEntriesAsset > 0 && (
                                    <div>
                                        <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                            <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                            </div>
                                            <div>
                                                <div className="pagination-container d-flex align-items-center">
                                                    <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                    </button>
                                                    <ReactPaginate
                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                        forcePage={pageAsset}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handleChangePagePaginationAsset}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}
                                                        renderOnZeroPageCount={null}
                                                    />
                                                    <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ padding: "0px 100px" }}></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImportHistory;

import { getGSetting } from '../Services/GeneralSettingsService';
import { getASTSettings, getAssetHistory } from '../Services/AssetSettingsService';


export const getDateFormat = async () => {
    const data = await getGSetting();
    return data.gS_DateFormat;
}

export const getFinancialYear = async () => {
    const data = await getGSetting();
    return data.gS_FinancialYear?.split(" – ")[0];
}

export const getRegexForDateFormat = (format) => {
    switch (format) {
        case 'dd/MM/yyyy':
            return /^\d{2}\/\d{2}\/\d{4}$/;
        case 'MM/dd/yyyy':
            return /^\d{2}\/\d{2}\/\d{4}$/;
        case 'yyyy/MM/dd':
            return /^\d{4}\/\d{2}\/\d{2}$/;
        default:
            return /^\d{2}\/\d{2}\/\d{4}$/;
    }
};

export const validateDate = (dateFormat, day, month, year) => {
    let selectedDate;
    switch (dateFormat) {
        case 'dd/MM/yyyy':
            selectedDate = new Date(year, month - 1, day);
            break;
        case 'MM/dd/yyyy':
            selectedDate = new Date(year, day - 1, month);
            break;
        case 'yyyy/MM/dd':
            selectedDate = new Date(year, month - 1, day);
            break;
        default:
            selectedDate = new Date(year, month - 1, day);
    }
    return selectedDate;
};

export async function countries() {
    return {
        'Africa': 'TND',
        'America': 'USD',
        'Antarctica': 'USD',
        'Indian': 'INR',
    };
}

export async function timeFormats() {
    return ['12-hour', '24-hour']
}

export async function dateFormats() {
    return [{ label: 'DD/MM/YYYY', value: 'dd/MM/yyyy' },
    { label: 'MM/DD/YYYY', value: 'MM/dd/yyyy' },
    { label: 'YYYY/MM/DD', value: 'yyyy/MM/dd' }];
}

export async function YearOptions() {
    return [
        { label: 'January', value: 'January – December' },
        { label: 'February', value: 'February – January' },
        { label: 'March', value: 'March – February' },
        { label: 'April', value: 'April – March' },
        { label: 'May', value: 'May – April' },
        { label: 'June', value: 'June – May' },
        { label: 'July', value: 'July – June' },
        { label: 'August', value: 'August – July' },
        { label: 'September', value: 'September – August' },
        { label: 'October', value: 'October – September' },
        { label: 'November', value: 'November – October' },
        { label: 'December', value: 'December – November' },
    ];
}

export async function LanguagesOptions() {
    return [
        { code: 'en-US', label: 'English (United States)' },
        { code: 'en-GB', label: 'English (United Kingdom)' },
        { code: 'en-AU', label: 'English (Australia)' },
        { code: 'en-CA', label: 'English (Canada)' },
        { code: 'en-JP', label: 'English (Japan)' },
        { code: 'en-IN', label: 'English (India)' }
    ];
}

export const getAssetPrefix = async () => {
    const data = await getASTSettings();
    console.log("getASTSettings", data);
    console.log("prefix", data[0].prefix);
    return data[0].prefix;
}

export const getAssetPadding = async () => {
    const data = await getASTSettings();
    console.log("padding", data[0].padding);
    return data[0].padding;
}

export const getUnitID = async () => {
    const data = await getASTSettings();
    console.log("unitId", data[0].unitId);
    return data[0].unitId;
}

export const getBranchID = async () => {
    const data = await getASTSettings();
    console.log("branchId", data[0].branchId);
    return data[0].branchId;
}

export const getDepartmentID = async () => {
    const data = await getASTSettings();
    console.log("departmentId", data[0].departmentId);
    return data[0].departmentId;
}

export const getAssetDepreciation = async () => {
    const data = await getASTSettings();
    console.log("assetDepreciation", data[0].assetDepreciation);
    return data[0].assetDepreciation;
}

export const getDepreciationMethod = async () => {
    const data = await getASTSettings();
    console.log("depreciationMethod", data[0].depreciationMethod);
    return data[0].depreciationMethod;
}

export const getContarctMethod = async () => {
    const data = await getASTSettings();
    return data[0].contract;
}

export const getServiceRequestMethod = async () => {
    const data = await getASTSettings();
    return data[0].serviceRequest;
}

export const getWarrantiesMethod = async () => {
    const data = await getASTSettings();
    return data[0].warranties;
}

export const getInsuranceMethod = async () => {
    const data = await getASTSettings();
    return data[0].insurance;
}

export const getAllHistory = async () => {
    // Helper function to format the date
    const formatAssetDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options).replace(',', '');
    };

    // Fetch the asset history
    const historyData = await getAssetHistory();

    // Map the data and format the assetDate
    const finalData = historyData?.map((his, index) => {
        let assetFieldConverted = his?.assetField;
        if (assetFieldConverted === "assetName") {
            assetFieldConverted = "Asset Name";
        } else if (assetFieldConverted === "assetTagID") {
            assetFieldConverted = "Asset Tag ID";
        } else if (assetFieldConverted === "categoryId") {
            assetFieldConverted = "Category";
        } else if (assetFieldConverted === "subCategoryId") {
            assetFieldConverted = "Sub Category";
        } else if (assetFieldConverted === "brandId") {
            assetFieldConverted = "Brand";
        } else if (assetFieldConverted === "modelNo") {
            assetFieldConverted = "Model No";
        } else if (assetFieldConverted === "serialNo") {
            assetFieldConverted = "Serial No";
        } else if (assetFieldConverted === "cost") {
            assetFieldConverted = "Cost";
        } else if (assetFieldConverted === "supplierId") {
            assetFieldConverted = "Supplier";
        } else if (assetFieldConverted === "purchaseDate") {
            assetFieldConverted = "Purchase Date";
        } else if (assetFieldConverted === "unitId") {
            assetFieldConverted = "Unit";
        } else if (assetFieldConverted === "branchId") {
            assetFieldConverted = "Branch";
        } else if (assetFieldConverted === "departmentId") {
            assetFieldConverted = "Department";
        } else if (assetFieldConverted === "empId") {
            assetFieldConverted = "Employee";
        } else if (assetFieldConverted === "assetImage") {
            assetFieldConverted = "Asset Image";
        } else if (assetFieldConverted === "depreciableAsset") {
            assetFieldConverted = "Depreciable Asset";
        } else if (assetFieldConverted === "depreciableCost") {
            assetFieldConverted = "Depreciable Cost";
        } else if (assetFieldConverted === "assetLife") {
            assetFieldConverted = "Asset Life";
        } else if (assetFieldConverted === "salvageValue") {
            assetFieldConverted = "Salvage Value";
        } else if (assetFieldConverted === "depreciationMethod") {
            assetFieldConverted = "Depreciation Method";
        } else if (assetFieldConverted === "dateAcquired") {
            assetFieldConverted = "Date Acquired";
        } else if (assetFieldConverted === "status") {
            assetFieldConverted = "Status";
        } else if (assetFieldConverted === "setReminders") {
            assetFieldConverted = "Reminders";
        } else if (assetFieldConverted === "notes") {
            assetFieldConverted = "Notes";
        } else if (assetFieldConverted === "expirationDate") {
            assetFieldConverted = "Expiration Date";
        } else if (assetFieldConverted === "length") {
            assetFieldConverted = "Length";
        } else if (assetFieldConverted === "assetTagId") {
            assetFieldConverted = "Asset ID";
        } else if (assetFieldConverted === "warrantyTagId") {
            assetFieldConverted = "Warranty ID";
        } else if (assetFieldConverted === "insuranceName") {
            assetFieldConverted = "Insurance Name";
        } else if (assetFieldConverted === "insuranceCompany") {
            assetFieldConverted = "Insurance Company";
        } else if (assetFieldConverted === "documentLink") {
            assetFieldConverted = "Document Name";
        } else if (assetFieldConverted === "policyNumber") {
            assetFieldConverted = "Policy No";
        } else if (assetFieldConverted === "contactPerson") {
            assetFieldConverted = "Contact Person";
        } else if (assetFieldConverted === "insuranceTagId") {
            assetFieldConverted = "Insurance ID";
        } else if (assetFieldConverted === "insuranceStartDate") {
            assetFieldConverted = "Insurance Start Date";
        } else if (assetFieldConverted === "insuranceEndDate") {
            assetFieldConverted = "Insurance End Date";
        } else if (assetFieldConverted === "updatedBy") {
            assetFieldConverted = "Updated By";
        }

        // Update the state with the formatted data
        return {
            ...his,
            Rowid: index + 1,
            assetField: assetFieldConverted,
            assetDate: formatAssetDate(his?.assetDate),
        };
    });
    // console.log("finalData", finalData);
    return finalData;
}
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import GetUserAccess from '../../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { getTab, createCheckOut, createCheckIn, createLost, createDisposed, createBroken, createRepair, createFound, createDonate, createSell } from "../../../Services/ActionFormConfigurationService";
import { getEmployee } from "../../../Services/EmployeeManagementService";
import { getBUData, getBRData } from "../../../Services/BusinessEntityNewService";
import { getDepartment } from "../../../Services/DepartmentsAndTeamService";
import { getDateFormat, getRegexForDateFormat, validateDate } from '../../../CommomPages/Config';

import './ActionFormConfig.css';

import rupeesvgIcon from '../../../assets/icon/Rupee.svg';
import calendarsvgIcon from '../../../assets/icon/calendarDate.svg';

const ActionFormConfiguration = () => {
    const navigate = useNavigate();
    const [userScreens, setUserScreens] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [tabActive, setTabActive] = useState('');
    const [dateFormat, setDateFormat] = useState('');
    const [tabs, setTabs] = useState([]);

    //CheckOut
    const [isCheckOutView, setIsCheckOutView] = useState(false);
    const [unitArray, setUnitArray] = useState([]);
    const [branchArray, setBranchArray] = useState([]);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [IsSendMail, setIsSendMail] = useState(false);

    //CheckIn
    const [isCheckInView, setIsCheckInView] = useState(false);
    const [unitSelectCheckInID, setUnitSelectCheckInID] = useState(null);
    const [branchSelectCheckInID, setBranchSelectCheckInID] = useState(null);
    const [departmentSelectCheckInID, setDepartmentSelectCheckInID] = useState(null);

    //Lost
    const [isLostView, setIsLostView] = useState(false);

    //Found
    const [isFoundView, setIsFoundView] = useState(false);

    //Repair
    const [isRepairView, setIsRepairView] = useState(false);
    const [employeeSelectRepairID, setEmployeeSelectRepairID] = useState(null);

    //Broken
    const [isBrokenView, setIsBrokenView] = useState(false);

    //Dispose
    const [isDisposeView, setIsDisposeView] = useState(false);

    //Donate
    const [isDonateView, setIsDonateView] = useState(false);

    //Sell
    const [isSellView, setIsSellView] = useState(false);

    useEffect(() => {
        fetchUserScreens();
        const fetchData = async () => {
            const tabs = await getTab();
            setTabs(tabs.map(tab => tab?.tabName));
            setTabActive(tabs.length > 0 ? tabs[0].tabName : null);
            await getEmployeeDetails();
            await getUnitBranchDetails();
            await getDeparmentDetails();
            const dateFormat = await getDateFormat();
            setDateFormat(dateFormat);
            setIsCheckOutView(false);
            setIsCheckInView(false);
            setIsLostView(false);
            setIsFoundView(false);
            setIsRepairView(false);
            setIsBrokenView(false);
            setIsDisposeView(false);
            setIsDonateView(false);
            setIsSellView(false);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const getEmployeeDetails = async () => {
        const EmployeeData = await getEmployee();
        const finalData = EmployeeData.map((Emp, index) => {
            return {
                ...Emp,
                Rowid: index + 1,
                EmployeeName: Emp.firstName + " " + Emp.lastName,
                EmployeeStatus: Emp.status === true ? "Active" : "Inactive"
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
    };

    const getUnitBranchDetails = async () => {
        const unitData = await getBUData();
        const sortedUnitData = unitData.sort((a, b) => a.bU_NAME.localeCompare(b.bU_NAME, undefined, { sensitivity: 'base' }));
        setUnitArray(sortedUnitData);

        const branchData = await getBRData();
        const sortedBranchData = branchData.sort((a, b) => a.bR_NAME.localeCompare(b.bR_NAME, undefined, { sensitivity: 'base' }));
        setBranchArray(sortedBranchData);
    };

    const getDeparmentDetails = async () => {
        const departmentData = await getDepartment();
        const sortedDepartmentData = departmentData.sort((a, b) => a.departmentName.localeCompare(b.departmentName, undefined, { sensitivity: 'base' }));
        setDepartmentArray(sortedDepartmentData);
    };

    //CheckOut
    const {
        register: registerCheckOut,
        handleSubmit: handleSubmitCheckOut,
        formState: { errors: errorsCheckOut },
        control: controlCheckOut,
        reset: resetCheckOut,
        watch: watchCheckOut,
    } = useForm({
        defaultValues: {
            checkOutDate: '',
            employee: false,
            unit: false,
            dueDate: '',
            assignTo: '',
            unitId: '',
            branchId: '',
            departmentId: '',
            checkOutNotes: '',
            sendEmail: '',
            sendEmailCheck: false,
        },
    });

    //CheckIn
    const {
        register: registerCheckIn,
        handleSubmit: handleSubmitCheckIn,
        formState: { errors: errorsCheckIn },
        control: controlCheckIn,
        reset: resetCheckIn,
        watch: watchCheckIn,
    } = useForm({
        defaultValues: {
            returnDate: '',
            employee: false,
            unit: false,
            unitId: '',
            branchId: '',
            departmentId: '',
            checkInNotes: '',
            sendEmail: '',
            sendEmailCheck: false,
        },
    });

    //Lost
    const {
        register: registerLost,
        handleSubmit: handleSubmitLost,
        formState: { errors: errorsLost },
        control: controlLost,
        reset: resetLost,
    } = useForm({
        defaultValues: {
            dateLost: '',
            notes: '',
        },
    });

    //Found
    const {
        register: registerFound,
        handleSubmit: handleSubmitFound,
        formState: { errors: errorsFound },
        control: controlFound,
        reset: resetFound,
    } = useForm({
        defaultValues: {
            dateFound: '',
            notes: '',
        },
    });

    //Repair
    const {
        register: registerRepair,
        handleSubmit: handleSubmitRepair,
        formState: { errors: errorsRepair },
        control: controlRepair,
        reset: resetRepair,
        watch: watchRepair,
    } = useForm({
        defaultValues: {
            scheduleDate: '',
            assignTo: '',
            repairCompleted: '',
            repairCost: '',
            notes: '',
        },
    });

    //Broken
    const {
        register: registerBroken,
        handleSubmit: handleSubmitBroken,
        formState: { errors: errorsBroken },
        control: controlBroken,
        reset: resetBroken,
    } = useForm({
        defaultValues: {
            dateBroken: '',
            notes: '',
        },
    });

    //Dispose
    const {
        register: registerDispose,
        handleSubmit: handleSubmitDispose,
        formState: { errors: errorsDispose },
        control: controlDispose,
        reset: resetDispose,
    } = useForm({
        defaultValues: {
            dateDispose: '',
            disposeTo: '',
            notes: '',
        },
    });

    //Donate
    const {
        register: registerDonate,
        handleSubmit: handleSubmitDonate,
        formState: { errors: errorsDonate },
        control: controlDonate,
        reset: resetDonate,
    } = useForm({
        defaultValues: {
            dateDonate: '',
            donateTo: '',
            donatedValue: '',
            deductible: '',
            notes: '',
        },
    });

    //Sell
    const {
        register: registerSell,
        handleSubmit: handleSubmitSell,
        formState: { errors: errorsSell },
        control: controlSell,
        reset: resetSell,
    } = useForm({
        defaultValues: {
            saleDate: '',
            soldTo: '',
            saleAmount: '',
            notes: '',
        },
    });

    const onCancel = () => {
        resetCheckOut();
        resetCheckIn();
        resetLost();
        resetBroken();
        resetFound();
        resetRepair();
        resetDispose();
        resetDonate();
        resetSell();
        navigate("/Settings");
    };

    const handleSendmailCheckBox = (e) => {
        if (e.target.checked) {
            setIsSendMail(true);
        } else {
            setIsSendMail(false);
        }
    };

    //CheckOut
    const onSubmitCheckOut = async (dataCheckOut) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { checkOutDate, employee, unit, dueDate, assignTo, unitId, branchId, departmentId, checkOutNotes, sendEmail, sendEmailCheck } = dataCheckOut;
        const payload = {
            id: 0,
            checkOutDate: checkOutDate,
            isEmployee: employee,
            isUnit: unit,
            dueDate: dueDate,
            assignTo: assignTo,
            unitId: unitId,
            branchId: branchId,
            departmentId: departmentId,
            checkOutNotes: checkOutNotes?.trim(),
            sendEmail: sendEmail?.trim(),
            isSendEmailCheck: sendEmailCheck,
        };

        const response = await createCheckOut(payload);
        if (response === "Inserted") {
            toast.success("CheckOut data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //CheckIn
    const onSubmitCheckIn = async (dataCheckIn) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { returnDate, employee, unit, unitId, branchId, departmentId, checkInNotes, sendEmail, sendEmailCheck } = dataCheckIn;
        const payload = {
            id: 0,
            returnDate: returnDate,
            isEmployee: employee,
            isUnit: unit,
            unitId: unitId,
            branchId: branchId,
            departmentId: departmentId,
            checkInNotes: checkInNotes?.trim(),
            sendEmail: sendEmail?.trim(),
            isSendEmailCheck: sendEmailCheck,
        };

        const response = await createCheckIn(payload);
        if (response === "Inserted") {
            toast.success("CheckIn data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //Lost
    const onSubmitLost = async (dataLost) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { dateLost, notes } = dataLost;
        const payload = {
            id: 0,
            dateLost: dateLost,
            notes: notes?.trim(),
        };
        console.log('onSubmitLost', payload);

        const response = await createLost(payload);
        if (response === "Inserted") {
            toast.success("Lost data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //Found
    const onSubmitFound = async (dataFound) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { dateFound, notes } = dataFound;
        const payload = {
            id: 0,
            dateFound: dateFound,
            notes: notes?.trim(),
        };

        const response = await createFound(payload);
        if (response === "Inserted") {
            toast.success("Found data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //Repair
    const onSubmitRepair = async (dataRepair) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { scheduleDate, assignTo, repairCompleted, repairCost, notes } = dataRepair;
        const payload = {
            id: 0,
            scheduleDate: scheduleDate,
            assignTo: assignTo,
            repairCompleted: repairCompleted,
            repairCost: `${repairCost}`,
            notes: notes?.trim(),
        };

        const response = await createRepair(payload);
        if (response === "Inserted") {
            toast.success("Repair data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //Broken
    const onSubmitBroken = async (dataBroken) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { dateBroken, notes } = dataBroken;
        const payload = {
            id: 0,
            dateBroken: dateBroken,
            notes: notes?.trim(),
        };

        const response = await createBroken(payload);
        if (response === "Inserted") {
            setTimeout(() => navigate("/Settings"), 1000);
            toast.success("Broken data Inserted Successfully");
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //Dispose
    const onSubmitDispose = async (dataDispose) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { dateDispose, disposeTo, notes } = dataDispose;
        const payload = {
            id: 0,
            dateDispose: dateDispose,
            disposeTo: disposeTo,
            notes: notes?.trim(),
        };

        const response = await createDisposed(payload);
        if (response === "Inserted") {
            toast.success("Dispose data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //Donate
    const onSubmitDonate = async (dataDonate) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { dateDonate, donateTo, donatedValue, deductible, notes } = dataDonate;
        const payload = {
            id: 0,
            dateDonate: dateDonate,
            donateTo: donateTo,
            donatedValue: `${donatedValue}`,
            deductible: deductible,
            notes: notes?.trim(),
        };

        const response = await createDonate(payload);
        if (response === "Inserted") {
            toast.success("Donate data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    //Sell
    const onSubmitSell = async (dataSell) => {
        if (!userScreens["Action Form Configuration"]?.Add || !userScreens["Action Form Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { saleDate, soldTo, saleAmount, notes } = dataSell;
        const payload = {
            id: 0,
            saleDate: saleDate,
            soldTo: soldTo,
            saleAmount: `${saleAmount}`,
            notes: notes?.trim(),
        };

        const response = await createSell(payload);
        if (response === "Inserted") {
            toast.success("Sell data Inserted Successfully");
            setTimeout(() => navigate("/Settings"), 1000);
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> Asset &gt;&gt; </span>
                                <span> Asset Action Form Configuration </span>
                            </span>
                            <div><InfoOutlinedIcon /></div>
                        </div>
                    </div>
                    {tabActive && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="HeaderTab">
                                        {tabs.map(tab => (
                                            <a
                                                key={tab}
                                                href={`#${tab.replace(' ', '')}`}
                                                className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                                onClick={() => setTabActive(tab)}
                                            >
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    {tab}
                                                </div>
                                            </a>
                                        ))}
                                    </div>

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Check Out') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitCheckOut(onSubmitCheckOut)}>
                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Check-out Date <span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="checkOutDate"
                                                                control={controlCheckOut}
                                                                rules={{
                                                                    required: "Check-out Date is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Check-out Date is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate > today) {
                                                                                return "Check-out Date cannot be in the future";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isCheckOutView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isCheckOutView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsCheckOut.checkOutDate && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckOut.checkOutDate.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-6 col-md-6 col-lg-6">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Check-Out Assets<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center mTop12 mBottom10'>
                                                                <Controller
                                                                    name="employee"
                                                                    control={controlCheckOut}
                                                                    rules={{
                                                                        validate: (value) => value || watchCheckOut('unit') || "At least one asset must be selected",
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                {...field}
                                                                                checked={field.value}
                                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                                                disabled={isCheckOutView}
                                                                            />
                                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight45'>
                                                                                Employee
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className='d-flex pLeft12 align-items-center mTop12 mBottom10'>
                                                                <Controller
                                                                    name="unit"
                                                                    control={controlCheckOut}
                                                                    rules={{
                                                                        validate: (value) => value || watchCheckOut('employee') || "At least one asset must be selected",
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                {...field}
                                                                                checked={field.value}
                                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                                                disabled={isCheckOutView}
                                                                            />
                                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                Unit
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        {errorsCheckOut.employee && errorsCheckOut.unit && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckOut.employee.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-6 col-md-6 col-lg-6'>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                            Assign to
                                                        </div>
                                                        <Controller
                                                            name="assignTo"
                                                            control={controlCheckOut}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={employeeArray.find(repo => repo.id === field.value) || null}
                                                                    onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                                    options={employeeArray}
                                                                    getOptionLabel={(option) => option.EmployeeName}
                                                                    getOptionValue={(option) => option.id}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isCheckOutView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className='col-6 col-md-6 col-lg-6'>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Due Date<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="dueDate"
                                                                control={controlCheckOut}
                                                                rules={{
                                                                    required: "Due Date is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Due Date is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate < today) {
                                                                                return "Due Date cannot be in the past";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isCheckOutView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isCheckOutView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsCheckOut.dueDate && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckOut.dueDate.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24 " >
                                                            Unit<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="unitId"
                                                            control={controlCheckOut}
                                                            rules={{ required: 'Unit is required' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={unitArray.find(unit => unit.bU_ID === field.value) || null}
                                                                    onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bU_ID : null); }}
                                                                    options={unitArray}
                                                                    getOptionLabel={(option) => option.bU_NAME}
                                                                    getOptionValue={(option) => option.bU_ID}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isCheckOutView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsCheckOut.unitId && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckOut.unitId.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                            Branch<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="branchId"
                                                            control={controlCheckOut}
                                                            rules={{ required: 'Branch is required' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={branchArray.find(branch => branch.bR_ID === field.value) || null}
                                                                    onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.bR_ID : null); }}
                                                                    options={branchArray}
                                                                    getOptionLabel={(option) => option.bR_NAME}
                                                                    getOptionValue={(option) => option.bR_ID}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isCheckOutView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsCheckOut.branchId && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckOut.branchId.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                            Department<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="departmentId"
                                                            control={controlCheckOut}
                                                            rules={{ required: 'Department is required' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={departmentArray.find(dept => dept.id === field.value) || null}
                                                                    onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                                    options={departmentArray}
                                                                    getOptionLabel={(option) => option.departmentName}
                                                                    getOptionValue={(option) => option.id}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isCheckOutView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsCheckOut.departmentId && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckOut.departmentId.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Check-out Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerCheckOut('checkOutNotes')}
                                                            disabled={isCheckOutView}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-6 col-md-6 col-lg-6 col-sm-12'>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center grayTextColor'>
                                                                <Controller
                                                                    name="sendEmailCheck"
                                                                    control={controlCheckOut}
                                                                    // rules={IsSendMail ? {
                                                                    //     required: 'Please Enter Email',
                                                                    //     pattern: {
                                                                    //         value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                    //         message: 'Please Enter a Valid Email Address'
                                                                    //     }
                                                                    // } : undefined}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                {...field}
                                                                                checked={field.value}
                                                                                onChange={(e) => {
                                                                                    field.onChange(e.target.checked);
                                                                                    handleSendmailCheckBox(e);
                                                                                }}
                                                                                disabled={isCheckOutView}
                                                                            />
                                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pLeft8'>
                                                                                Send Email{IsSendMail && (<span className="mandatoryCss"> *</span>)}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={isCheckOutView ? "textBoxNewView" : "textBoxNew"}
                                                            {...registerCheckOut('sendEmail', {
                                                                required: IsSendMail ? 'Please Enter Email' : false,
                                                                pattern: {
                                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                    message: 'Please Enter a Valid Email Address'
                                                                }
                                                            })}
                                                            disabled={isCheckOutView}
                                                        />
                                                        {errorsCheckOut.sendEmail && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckOut.sendEmail.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isCheckOutView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Check In') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitCheckIn(onSubmitCheckIn)}>
                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Check-In Assets By<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center mTop12 mBottom10'>
                                                                <Controller
                                                                    name="employee"
                                                                    control={controlCheckIn}
                                                                    rules={{
                                                                        validate: (value) => value || watchCheckIn('unit') || "At least one asset must be selected",
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                {...field}
                                                                                checked={field.value}
                                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                                                disabled={isCheckInView}
                                                                            />
                                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight45'>
                                                                                Employee
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className='d-flex pLeft12 align-items-center mTop12 mBottom10'>
                                                                <Controller
                                                                    name="unit"
                                                                    control={controlCheckIn}
                                                                    rules={{
                                                                        validate: (value) => value || watchCheckIn('employee') || "At least one asset must be selected",
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                {...field}
                                                                                checked={field.value}
                                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                                                disabled={isCheckInView}
                                                                            />
                                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                Unit
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        {errorsCheckIn.employee && errorsCheckIn.unit && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckIn.employee.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Return Date<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="returnDate"
                                                                control={controlCheckIn}
                                                                rules={{
                                                                    required: "Return Date is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Return Date is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate < today) {
                                                                                return "Return Date cannot be in the past";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isCheckInView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isCheckInView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsCheckIn.returnDate && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckIn.returnDate.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24 " >
                                                            Unit<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="unitId"
                                                            control={controlCheckIn}
                                                            rules={{ required: 'Unit is required' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={unitArray.find(unit => unit.bU_ID === unitSelectCheckInID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setUnitSelectCheckInID(selectedOption ? selectedOption.bU_ID : null);
                                                                        field.onChange(selectedOption ? selectedOption.bU_ID : null);
                                                                    }}
                                                                    options={unitArray}
                                                                    getOptionLabel={(option) => option.bU_NAME}
                                                                    getOptionValue={(option) => option.bU_ID}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isCheckInView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsCheckIn.unitId && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckIn.unitId.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                            Branch<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="branchId"
                                                            control={controlCheckIn}
                                                            rules={{ required: 'Branch is required' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={branchArray.find(branch => branch.bR_ID === branchSelectCheckInID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setBranchSelectCheckInID(selectedOption ? selectedOption.bR_ID : null);
                                                                        field.onChange(selectedOption ? selectedOption.bR_ID : null);
                                                                    }}
                                                                    options={branchArray}
                                                                    getOptionLabel={(option) => option.bR_NAME}
                                                                    getOptionValue={(option) => option.bR_ID}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isCheckInView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsCheckIn.branchId && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckIn.branchId.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                            Department<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="departmentId"
                                                            control={controlCheckIn}
                                                            rules={{ required: 'Department is required' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={departmentArray.find(dept => dept.id === departmentSelectCheckInID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setDepartmentSelectCheckInID(selectedOption ? selectedOption.id : null);
                                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                                    }}
                                                                    options={departmentArray}
                                                                    getOptionLabel={(option) => option.departmentName}
                                                                    getOptionValue={(option) => option.id}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isCheckInView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsCheckIn.departmentId && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckIn.departmentId.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Check-In Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerCheckIn('checkInNotes')}
                                                            disabled={isCheckInView}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-6 col-md-6 col-lg-6 col-sm-12'>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center grayTextColor'>
                                                                <Controller
                                                                    name="sendEmailCheck"
                                                                    control={controlCheckIn}
                                                                    // rules={IsSendMail ? {
                                                                    //     required: 'Please Enter Email',
                                                                    //     pattern: {
                                                                    //         value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                    //         message: 'Please Enter a Valid Email Address'
                                                                    //     }
                                                                    // } : undefined}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                {...field}
                                                                                checked={field.value}
                                                                                onChange={(e) => {
                                                                                    field.onChange(e.target.checked);
                                                                                    handleSendmailCheckBox(e);
                                                                                }}
                                                                                disabled={isCheckInView}
                                                                            />
                                                                            <div className='grayTextColor fontFamily fontsize16 fontweightRegular pLeft8'>
                                                                                Send Email<span className="mandatoryCss"> *</span>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <input
                                                            className={isCheckInView ? "textBoxNewView" : "textBoxNew"}
                                                            {...registerCheckIn('sendEmail', {
                                                                required: IsSendMail ? 'Please Enter Email' : false,
                                                                pattern: {
                                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                                    message: 'Please Enter a Valid Email Address'
                                                                }
                                                            })}
                                                            disabled={isCheckInView}
                                                        />
                                                        {errorsCheckIn.sendEmail && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsCheckIn.sendEmail.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isCheckInView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Lost') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitLost(onSubmitLost)}>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Date Lost<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="dateLost"
                                                                control={controlLost}
                                                                rules={{
                                                                    required: "Date Lost is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Date Lost is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate > today) {
                                                                                return "Date Lost cannot be in the future";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isLostView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isLostView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsLost.dateLost && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsLost.dateLost.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerLost('notes')}
                                                            disabled={isLostView}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isLostView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Found') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitFound(onSubmitFound)}>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Date Found<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="dateFound"
                                                                control={controlFound}
                                                                rules={{
                                                                    required: "Date Found is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Date Found is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate > today) {
                                                                                return "Date Found cannot be in the future";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + ")";
                                                                            }

                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isFoundView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isFoundView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsFound.dateFound && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsFound.dateFound.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerFound('notes')}
                                                            disabled={isFoundView}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isFoundView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Repair') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitRepair(onSubmitRepair)}>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Schedule Date<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="scheduleDate"
                                                                control={controlRepair}
                                                                rules={{
                                                                    required: "Schedule Date is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Schedule Date is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate < today) {
                                                                                return "Schedule Date cannot be in the past";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isRepairView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isRepairView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsRepair.scheduleDate && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsRepair.scheduleDate.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24" >
                                                            Assign to<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <Controller
                                                            name="assignTo"
                                                            control={controlRepair}
                                                            rules={{ required: 'Assign To field is required' }}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    value={employeeArray.find(repo => repo.id === employeeSelectRepairID) || null}
                                                                    onChange={(selectedOption) => {
                                                                        setEmployeeSelectRepairID(selectedOption ? selectedOption.id : null);
                                                                        field.onChange(selectedOption ? selectedOption.id : null);
                                                                    }}
                                                                    options={employeeArray}
                                                                    getOptionLabel={(option) => option.EmployeeName}
                                                                    getOptionValue={(option) => option.id}
                                                                    placeholder=""
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={isRepairView}
                                                                    styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        />
                                                        {errorsRepair.assignTo && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsRepair.assignTo.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Repair Completed<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="repairCompleted"
                                                                control={controlRepair}
                                                                rules={{
                                                                    required: "Repair Completed is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Repair Completed is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const scheduleDate = new Date(watchRepair('scheduleDate'));
                                                                            if (selectedDate < scheduleDate) {
                                                                                return "Repair Completed cannot be before Schedule Date";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isRepairView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isRepairView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsRepair.repairCompleted && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsRepair.repairCompleted.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Repair Cost
                                                        </div>
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className={isRepairView ? "textBoxNewView" : "textBoxNew"}
                                                            {...registerRepair('repairCost', {
                                                                valueAsNumber: true,
                                                                min: {
                                                                    value: 0,
                                                                    message: "Repair cost cannot be negative"
                                                                }
                                                            })}
                                                            disabled={isRepairView}
                                                        />
                                                        {errorsRepair.repairCost && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsRepair.repairCost.message}
                                                            </div>
                                                        )}
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerRepair('notes')}
                                                            disabled={isRepairView}
                                                            maxLength={500}
                                                        />
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">

                                                    </div>
                                                </div>

                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isRepairView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Broken') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitBroken(onSubmitBroken)}>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Date Broken<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="dateBroken"
                                                                control={controlBroken}
                                                                rules={{
                                                                    required: "Date Broken is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Date Broken is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate > today) {
                                                                                return "Date Broken cannot be in the future";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isBrokenView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isBrokenView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsBroken.dateBroken && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsBroken.dateBroken.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerBroken('notes')}
                                                            disabled={isBrokenView}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isBrokenView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Dispose') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitDispose(onSubmitDispose)}>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Date Disposed<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="dateDispose"
                                                                control={controlDispose}
                                                                rules={{
                                                                    required: "Date Disposed is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Date Disposed is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate > today) {
                                                                                return "Date Disposed cannot be in the future";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isDisposeView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isDisposeView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsDispose.dateDispose && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsDispose.dateDispose.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Dispose to<span className="mandatoryCss"> *</span></div>
                                                        <input
                                                            className={isDisposeView ? "textBoxNewView" : "textBoxNew"}
                                                            {...registerDispose('disposeTo', { required: "Dispose to is required" })}
                                                            disabled={isDisposeView}
                                                        />
                                                        {errorsDispose.disposeTo && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsDispose.disposeTo.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerDispose('notes')}
                                                            disabled={isDisposeView}
                                                        />
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">

                                                    </div>
                                                </div>

                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isDisposeView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Donate') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitDonate(onSubmitDonate)}>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Date Donated<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="dateDonate"
                                                                control={controlDonate}
                                                                rules={{
                                                                    required: "Date Donated is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) return "Date Donated is required";
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate > today) {
                                                                                return "Date Donated cannot be in the future";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isDonateView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isDonateView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsDonate.dateDonate && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsDonate.dateDonate.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Donate to<span className="mandatoryCss"> *</span></div>
                                                        <input
                                                            className={isDonateView ? "textBoxNewView" : "textBoxNew"}
                                                            {...registerDonate('donateTo', { required: "Donate to is required" })}
                                                            disabled={isDonateView}
                                                        />
                                                        {errorsDonate.donateTo && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsDonate.donateTo.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Donated Value<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className={isDonateView ? "textBoxNewView" : "textBoxNew"}
                                                            {...registerDonate('donatedValue', {
                                                                required: "Donated Value is required",
                                                                valueAsNumber: true,
                                                                min: {
                                                                    value: 0,
                                                                    message: "Donated Value cannot be negative"
                                                                }
                                                            })}
                                                            disabled={isDonateView}
                                                        />
                                                        {errorsDonate.donatedValue && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsDonate.donatedValue.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Deductible<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='d-flex mTop12 mBottom10'>
                                                            <div className='d-flex align-items-center'>
                                                                <Controller
                                                                    name="deductible"
                                                                    control={controlDonate}
                                                                    rules={{
                                                                        required: "Deductible is required",
                                                                        validate: value =>
                                                                            value === "Yes" || value === "No" || "Deductible must be either Yes or No"
                                                                    }}
                                                                    render={({ field }) => (
                                                                        <>

                                                                            <input
                                                                                type="radio"
                                                                                value="Yes"
                                                                                {...field}
                                                                                checked={field.value === 'Yes'}
                                                                                onChange={() => field.onChange('Yes')}
                                                                            />
                                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                Yes
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className='d-flex pLeft12 align-items-center'>
                                                                <Controller
                                                                    name="deductible"
                                                                    control={controlDonate}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <input
                                                                                type="radio"
                                                                                value="No"
                                                                                {...field}
                                                                                checked={field.value === 'No'}
                                                                                onChange={() => field.onChange('No')}
                                                                            />
                                                                            <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                                No
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        {errorsDonate.deductible && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsDonate.deductible.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerDonate('notes')}
                                                            disabled={isDonateView}
                                                        />
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">

                                                    </div>
                                                </div>

                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isDonateView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}

                                    {(userScreens["Action Form Configuration"]?.View && tabActive === 'Sell') && (
                                        <div className='mTop26'>
                                            <form onSubmit={handleSubmitSell(onSubmitSell)}>
                                                <div className='row'>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Date Sell<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <Controller
                                                                name="saleDate"
                                                                control={controlSell}
                                                                rules={{
                                                                    required: "Sale Date is required",
                                                                    validate: {
                                                                        validDate: (value) => {
                                                                            if (!value) {
                                                                                return "Sale Date is required";
                                                                            }
                                                                            // Check if the value is a valid date object
                                                                            const dateValue = new Date(value);
                                                                            if (isNaN(dateValue.getTime())) {
                                                                                return "Invalid date value";
                                                                            }

                                                                            // Format the date and validate it
                                                                            const formattedDate = format(dateValue, dateFormat);
                                                                            const [day, month, year] = formattedDate.split('/');
                                                                            const selectedDate = validateDate(dateFormat, day, month, year);
                                                                            const today = new Date();
                                                                            today.setHours(0, 0, 0, 0);
                                                                            if (selectedDate > today) {
                                                                                return "Sale Date cannot be in the future";
                                                                            }
                                                                            const regex = getRegexForDateFormat(dateFormat);
                                                                            if (!regex.test(formattedDate)) {
                                                                                return "Invalid date format (" + dateFormat + "})";
                                                                            }
                                                                            return true;
                                                                        },
                                                                    },
                                                                }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value}
                                                                        onChange={(date) => field.onChange(date)}
                                                                        dateFormat={dateFormat}
                                                                        placeholderText={dateFormat.toUpperCase()}
                                                                        className={isSellView ? "DatePickerDisable" : "DatePicker"}
                                                                        disabled={isSellView}
                                                                        ref={(input) => field.ref(input?.inputElement)}
                                                                    />
                                                                )}
                                                            />
                                                            <img className="eyeContent" src={calendarsvgIcon} alt="calendar icon" />
                                                        </div>
                                                        {errorsSell.saleDate && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsSell.saleDate.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Sold to<span className="mandatoryCss"> *</span></div>
                                                        <input
                                                            className={isSellView ? "textBoxNewView" : "textBoxNew"}
                                                            {...registerSell('soldTo', { required: "Sold to is required" })}
                                                            disabled={isSellView}
                                                        />
                                                        {errorsSell.soldTo && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsSell.soldTo.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                            Sale Amount<span className="mandatoryCss"> *</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                className={isSellView ? "textBoxNewView" : "textBoxNew"}
                                                                {...registerSell('saleAmount', {
                                                                    required: "Sale Amount Value is required",
                                                                    valueAsNumber: true,
                                                                    min: {
                                                                        value: 0,
                                                                        message: "Sale Amount Value cannot be negative"
                                                                    }
                                                                })}
                                                                disabled={isSellView}
                                                            />
                                                            <img className="eyeContent" src={rupeesvgIcon} alt="rupeesvgIcon" />
                                                        </div>
                                                        {errorsSell.saleAmount && (
                                                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                                                {errorsSell.saleAmount.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">Notes</div>
                                                        <textarea
                                                            placeholder=""
                                                            className="textareaCustom textAeraHeight"
                                                            {...registerSell('notes')}
                                                            disabled={isSellView}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                                    {!isSellView && (
                                                        <button type="submit" className="coloredButtonSave">Save</button>
                                                    )}
                                                    <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {!tabActive && (
                        <div className="cardError mt-4">
                            <div className="card-body">
                                <div className="restrictedAccessContainer">
                                    <div className="restrictedAccessIcon">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>
                                    <div className="restrictedAccessText">
                                        You do not have access to this tab section
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ActionFormConfiguration;

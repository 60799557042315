import Select, { components } from 'react-select';
import ReactPaginate from 'react-paginate';
import 'react-toastify/dist/ReactToastify.css';
import { useState, React, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import PropTypes from 'prop-types';
import { exportUserData } from './ExportUserAccess';
import { customStyles } from './MultiSelectDropdownDesign';
import GetUserAccess from '../../../CommomPages/GetUserAccess';
import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { getRoleData } from '../../../Services/RoleManagementService';
import { getEmployee } from '../../../Services/EmployeeManagementService';
import { getUserAccessPermission, createUserAccessPermission, updateUserAccessPermission, deleteUserAccessPermission } from '../../../Services/UserAccessPermissionService';

import './UserAccessPermissionCss.css';

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import nodataFound from '../../../assets/img/nodatafound.png';
import { useNavigate } from 'react-router-dom';

const CustomOption = (props) => (
    <components.Option {...props}>
        <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
        />{' '}
        <label>{props.label}</label>
    </components.Option>
);

CustomOption.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
};

const CustomMultiValue = (props) => (
    <components.MultiValue {...props}>
        <span>{props.data.rolE_User_Role}</span>
    </components.MultiValue>
);

CustomMultiValue.propTypes = {
    data: PropTypes.shape({
        rolE_User_Role: PropTypes.string.isRequired,
    }).isRequired,
};

const UserAccessPermission = () => {
    const navigate = useNavigate();
    const [userScreens, setUserScreens] = useState({});
    const paginateArray = [5, 10, 15];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [userAccessID, setUserAccessID] = useState(0);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [employeeSelectID, setEmployeeSelectID] = useState(null);
    const [roleArray, setRoleArray] = useState([]);
    const [userAccessArray, setUserAccessArray] = useState([]);
    const [activeScreen, setActiveScreen] = useState('UserAccess');
    const [isUserAccessView, setIsUserAccessView] = useState(false);
    const [passwordVisiblePassword, setPasswordVisiblePassword] = useState(false);
    const [passwordVisibleConfirmPassword, setPasswordVisibleConfirmPassword] = useState(false);
    const MAX_ROLE_LIMIT = 3;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
        control
    } = useForm({
        defaultValues: {
            employeeId: null,
            roleId: [],
            userName: '',
            emailID: '',
            password: '',
            confirmPassword: '',
            Status: true
        }
    });

    const statusWatch = watch('Status');

    useEffect(() => {
        fetchUserScreens();
        getEmployeeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const togglePasswordVisibilityConfirm = () => {
        setPasswordVisibleConfirmPassword(!passwordVisibleConfirmPassword);
    };

    const togglePasswordVisibilityPassword = () => {
        setPasswordVisiblePassword(!passwordVisiblePassword);
    };

    const getEmployeeData = async () => {
        setIsLoading(true);
        const dataEmp = await getEmployee();
        const finalData = dataEmp.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp.firstName + " " + Emp.lastName
            };
        });
        const sortedEmployeeData = finalData.sort((a, b) => a.EmployeeName.localeCompare(b.EmployeeName, undefined, { sensitivity: 'base' }));
        setEmployeeArray(sortedEmployeeData);
        getRoleDetails();
    };

    const getRoleDetails = async () => {
        const dataRole = await getRoleData();
        const sortedRoleData = dataRole.sort((a, b) => a.rolE_User_Role.localeCompare(b.rolE_User_Role, undefined, { sensitivity: 'base' }));
        setRoleArray(sortedRoleData);
        getUserAccessData();
    };

    const EmployeeDataFinder = async (val) => {
        const dataEmp = await getEmployee();
        const finalData = dataEmp.map((Emp, index) => {
            return {
                ...Emp,
                EmployeeName: Emp.firstName + " " + Emp.lastName
            };
        });
        const found = finalData.find(option => option.id === val);
        return found ? found.EmployeeName : 'Unknown';
    };

    const RoleDataFinder = async (val) => {
        const dataRole = await getRoleData();
        const roleIds = val.split(',').map(id => parseInt(id, 10));
        const roles = roleIds
            .map(roleId => {
                const found = dataRole.find(option => option.rolE_ID === roleId);
                return found ? found.rolE_User_Role : null;
            })
            .filter(role => role !== null);
        return roles;
    };

    const setUserAccess = async () => {
        try {
            const userRole = localStorage.getItem('UserRole');
            const roleData = await getRoleData();
            const selectedScreens = roleData?.find(option => option?.rolE_User_Role == userRole)?.rolE_Screen;

            // Clean up selected screens by removing any empty objects
            for (let key in selectedScreens) {
                if (Object.keys(selectedScreens[key]).length === 0) {
                    delete selectedScreens[key];
                }
            }

            // Store cleaned-up selectedScreens as a string in localStorage
            localStorage.setItem('UserScreens', selectedScreens);


            // Asset Actions
            // Parse the selectedActions based on the user's role
            const selectedActions = roleData?.find(option => option?.rolE_User_Role == userRole)?.rolE_Asset;

            // Clean up selected screens by removing any empty objects
            for (let key in selectedActions) {
                if (Object.keys(selectedActions[key]).length === 0) {
                    delete selectedActions[key];
                }
            }

            // Store cleaned-up selectedActions as a string in localStorage
            localStorage.setItem('UserActionsOnAsset', selectedActions);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const getUserAccessData = async () => {
        try {
            const data = await getUserAccessPermission();
            const finalData = await Promise.all(data.map(async (user, index) => {
                const employeeName = await EmployeeDataFinder(user.employeeId);
                const roleName = await RoleDataFinder(user.roleId);
                return {
                    ...user,
                    Rowid: index + 1,
                    EmployeeName: employeeName,
                    RoleName: roleName,
                    UserAccessStatus: user.status
                };
            }));
            setUserAccessArray(finalData);
            setPage(0);
        } catch (error) {
            console.error("Error fetching user Access data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUserAccessSubmit = async (data) => {
        if (!userScreens["User Access Permission"]?.Add || !userScreens["User Access Permission"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsLoading(true);
        const { employeeId, roleId, userName, emailID, password, confirmPassword, Status } = data;
        const userExists = userAccessArray.some(x => x.emailID === emailID);
        const userNameExists = userAccessArray.some(x => x.userName === userName);
        const emploeeExists = userAccessArray.some(x => x.employeeId === employeeId);
        const isUpdating = userAccessID !== 0;

        if (isUpdating) {
            const currentUser = userAccessArray.find(x => x.emailID === emailID || x.userName === userName || x.employeeId === employeeId);
            if (userExists && currentUser.emailID !== emailID) {
                toast.error("Email already exists.");
                return;
            }

            if (userNameExists && currentUser.userName !== userName) {
                toast.error("Username already exists.");
                return;
            }

            if (emploeeExists && currentUser.employeeId !== employeeId) {
                toast.error("Employee already exists.");
                return;
            }

        } else {
            if (userExists) {
                toast.error("Email already exists.");
                return;
            }

            if (userNameExists) {
                toast.error("Username already exists.");
                return;
            }

            if (emploeeExists) {
                toast.error("Employee already exists.");
                return;
            }
        }

        const payload = {
            id: userAccessID || 0,
            employeeId: employeeId,
            roleId: roleId?.join(','),
            userName: userName?.trim(),
            emailID: emailID?.trim(),
            password: password?.trim(),
            confirmPassword: confirmPassword?.trim(),
            status: Status ? 'Active' : 'Inactive'
        };

        const response = isUpdating ? await updateUserAccessPermission(payload) : await createUserAccessPermission(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            setUserAccess();
            getUserAccessData();
            toast.success(`User ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            if (isUpdating) setPage(0);
            closeUserAccess();
            window.location.reload();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const handleAddButtonClick = () => {
        if (!userScreens["User Access Permission"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsUserAccessView(false);
        setActiveScreen("UserAccessEdit");
        reset();
        setUserAccessID(0);
        setEmployeeSelectID(null);
    };

    const closeUserAccess = () => {
        setIsUserAccessView(false);
        reset();
        setEmployeeSelectID(null);
        setActiveScreen("UserAccess");
    };

    const exportUserAccess = (event) => {
        if (!userScreens["User Access Permission"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportUserAccess(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportUserAccess = (format) => {
        exportUserData(userAccessArray, format);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredUserAccess = userAccessArray.filter((userAccess) =>
        userAccess &&
        (
            userAccess?.userName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            userAccess?.EmployeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            userAccess?.RoleName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            userAccess?.UserAccessStatus?.toLowerCase()?.startsWith(searchQuery?.toLowerCase())
        )
    );

    const deleteUserAccessId = async (useID) => {
        if (!userScreens["User Access Permission"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const result = await ConfirmDelete();
        if (result) {
            setIsLoading(true);
            const response = await deleteUserAccessPermission(useID);
            if (response === "Deleted") {
                getUserAccessData();
                setIsLoading(false);
                toast.success("User Access Deleted Sucessfully");
            } else {
                setIsLoading(false);
                toast.error(response);
            }
        }
    };

    const viewUserAccess = (userAccess) => {
        if (!userScreens["User Access Permission"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsUserAccessView(true);
        setUserAccessID(userAccess.id);
        setEmployeeSelectID(userAccess.employeeId);
        const roleIds = userAccess.roleId.split(',').map(id => parseInt(id, 10));
        setValue('employeeId', userAccess.employeeId);
        setValue('roleId', roleIds);
        setValue('userName', userAccess.userName);
        setValue('emailID', userAccess.emailID);
        setValue('password', userAccess.password);
        setValue('confirmPassword', userAccess.confirmPassword);
        setValue('Status', userAccess.status);
        setActiveScreen("UserAccessEdit");
    };

    const editUserAccess = (userAccess) => {
        if (!userScreens["User Access Permission"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsUserAccessView(false);
        setUserAccessID(userAccess.id);
        setEmployeeSelectID(userAccess.employeeId);
        const roleIds = userAccess.roleId.split(',').map(id => parseInt(id, 10));
        setValue('employeeId', userAccess.employeeId);
        setValue('roleId', roleIds);
        setValue('userName', userAccess.userName);
        setValue('emailID', userAccess.emailID);
        setValue('password', userAccess.password);
        setValue('confirmPassword', userAccess.confirmPassword);
        setValue('Status', userAccess.status);
        setActiveScreen("UserAccessEdit");
    };

    const onChangeEmployee = (selectedEmployee) => {
        if (selectedEmployee != null) {
            setValue('emailID', selectedEmployee.email);
            setValue('userName', selectedEmployee.EmployeeName);
        } else {
            setValue('emailID', '');
            setValue('userName', '');
        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleFirstPage = () => {
        handleChangePagePagination({ selected: 0 });
    };

    const handleChangePagePagination = ({ selected }) => {
        setPage(selected);
    };

    const handleLastPage = () => {
        handleChangePagePagination({ selected: Math.ceil(userAccessArray.length / rowsPerPage) - 1 });
    };

    const totalEntries = filteredUserAccess.length;
    const startEntry = page * rowsPerPage + 1;
    const endEntry = Math.min((page + 1) * rowsPerPage, totalEntries);


    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitle">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            {activeScreen === "UserAccess" ? (
                                <span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> User Management &gt;&gt; </span>
                                    <span> User Access Permission </span>
                                </span>
                            ) : userAccessID == 0 ? (
                                <span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> User Management &gt;&gt; </span>
                                    <span onClick={() => setActiveScreen('UserAccess')} style={{ cursor: 'pointer' }}> User Access Permission &gt;&gt; </span>
                                    <span> Add </span>
                                </span>
                            ) : isUserAccessView == false ? (
                                <span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> User Management &gt;&gt; </span>
                                    <span onClick={() => setActiveScreen('UserAccess')} style={{ cursor: 'pointer' }}> User Access Permission &gt;&gt; </span>
                                    <span> Update </span>
                                </span>
                            ) : (
                                <span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                    <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> User Management &gt;&gt; </span>
                                    <span onClick={() => setActiveScreen('UserAccess')} style={{ cursor: 'pointer' }}> User Access Permission &gt;&gt; </span>
                                    <span> View </span>
                                </span>
                            )}


                            <div><InfoOutlinedIcon /></div>
                        </div>
                    </div>
                    {(userScreens["User Access Permission"]?.View && activeScreen === "UserAccess") && (
                        <div className="useScrollbar">
                            <div className="card mt-4">
                                <div className="cardBodyUserAccess">
                                    <div className="d-flex align-items-left">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">User Access Permission</div>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: '8px' }}>
                                            <div className="blackTextColor">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                    className="searchTextBox"
                                                />
                                            </div>

                                            <div className="addbuttondiv">
                                                <button type="button" id="BtnaddUnit" className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd" onClick={handleAddButtonClick}>Add New </button>
                                                <select className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportUserAccess}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="csv">CSV</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="useBoxShadow">
                                            <div className="useTableScroll">
                                                <table className="w-100 ">
                                                    <thead>
                                                        <tr className="text-left useTableHeight">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG  " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>SI.No</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>User name</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Employee</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "18%" }}>Role</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }}>Status</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredUserAccess.length > 0 ? (
                                                            filteredUserAccess.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((UserAccess, index) => (
                                                                <tr key={UserAccess.Rowid} style={{ height: "38px" }}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{UserAccess.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{UserAccess.userName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} title={UserAccess.EmployeeName}>{UserAccess.EmployeeName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }} title={UserAccess.RoleName}>{UserAccess.RoleName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ color: UserAccess.UserAccessStatus === 'Active' ? '#2ED47A' : '#EC2020' }}>
                                                                        {UserAccess.UserAccessStatus}
                                                                    </td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewUserAccess" onClick={() => viewUserAccess(UserAccess)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editUserAccess" onClick={() => editUserAccess(UserAccess)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#deleteUserAccess" onClick={() => deleteUserAccessId(UserAccess.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16">
                                                                    <img src={nodataFound} alt="no data found" />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {totalEntries > 0 && (
                                            <div >
                                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                                    <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                        Showing {startEntry} to {endEntry} of {totalEntries} entries
                                                    </div>
                                                    <div >
                                                        <div className="pagination-container d-flex align-items-center">
                                                            <button className="paginationButton btn btn-link" onClick={handleFirstPage}>
                                                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                            </button>
                                                            <ReactPaginate
                                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={Math.ceil(totalEntries / rowsPerPage)}
                                                                forcePage={page}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handleChangePagePagination}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                                renderOnZeroPageCount={null}
                                                            />
                                                            <button className="paginationButton btn btn-link" onClick={handleLastPage}>
                                                                <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "0px 100px" }}>
                                                    </div>

                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {(userScreens["User Access Permission"]?.View && activeScreen === "UserAccessEdit") && (
                        <div className='useScrollbarAdd'>
                            <div className="card">
                                <div className="card-body">
                                    <form className="p-2" noValidate onSubmit={handleSubmit(handleUserAccessSubmit)}>
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                    Employee<span className="mandatoryCss"> *</span>
                                                </div>
                                                <Controller
                                                    name="employeeId"
                                                    control={control}
                                                    rules={{ required: 'Please select a employee' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={employeeArray.find(unit => unit.id === employeeSelectID) || null}
                                                            onChange={(selectedOption) => {
                                                                setEmployeeSelectID(selectedOption ? selectedOption.id : null);
                                                                field.onChange(selectedOption ? selectedOption.id : null);
                                                                onChangeEmployee(selectedOption);
                                                            }}
                                                            options={employeeArray}
                                                            getOptionLabel={(option) => option.EmployeeName}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder="Select Employee"
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isUserAccessView}
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                                {errors.employeeId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.employeeId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                    Role<span className="mandatoryCss"> *</span>
                                                </div>
                                                <Controller
                                                    name="roleId"
                                                    control={control}
                                                    rules={{ required: 'Please select at least one role' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={roleArray.filter(role => (field.value || []).includes(role.rolE_ID))}
                                                            onChange={(selectedOptions) => {
                                                                if (selectedOptions && selectedOptions.length > MAX_ROLE_LIMIT) {
                                                                    return; // Prevent additional selections if limit is reached
                                                                }
                                                                const selectedIds = selectedOptions ? selectedOptions.map(option => option.rolE_ID) : [];
                                                                field.onChange(selectedIds);
                                                            }}
                                                            options={roleArray}
                                                            getOptionLabel={(option) => option.rolE_User_Role}
                                                            getOptionValue={(option) => option.rolE_ID}
                                                            placeholder="Select roles"
                                                            isClearable={false}
                                                            isSearchable
                                                            isDisabled={isUserAccessView}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            components={{
                                                                Option: CustomOption,
                                                                MultiValue: CustomMultiValue,
                                                            }}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                {errors.roleId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.roleId.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">User Name<span className="mandatoryCss"> *</span></div>
                                                <input
                                                    className={isUserAccessView ? "textBoxNewView" : "textBoxNew"}
                                                    placeholder="Enter the User Name"
                                                    {...register('userName', { required: 'Please Enter User Name' })}
                                                    disabled={true}
                                                />
                                                {errors.userName && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.userName.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">email ID<span className="mandatoryCss"> *</span></div>
                                                <input
                                                    className={isUserAccessView ? "textBoxNewView" : "textBoxNew"}
                                                    placeholder="Enter the email ID"
                                                    {...register('emailID', {
                                                        required: 'Please Enter Email',
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: 'Please Enter a Valid Email Address'
                                                        }
                                                    })}
                                                    disabled={true}
                                                />
                                                {errors.emailID && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.emailID.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Password<span className="mandatoryCss"> *</span>
                                                </div>
                                                <div className='position-relative'>
                                                    <input
                                                        type={passwordVisiblePassword ? "text" : "password"}
                                                        placeholder="Enter Password"
                                                        {...register('password', {
                                                            required: 'Please Enter Password',
                                                            minLength: {
                                                                value: 8,
                                                                message: 'Password must be at least 8 characters'
                                                            }
                                                        })}
                                                        className={isUserAccessView ? "textBoxNewView" : "textBoxNew"}
                                                        disabled={isUserAccessView}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={passwordVisiblePassword ? faEye : faEyeSlash}
                                                        onClick={togglePasswordVisibilityPassword}
                                                        className="eyeContent"
                                                    />
                                                </div>
                                                {errors.password && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }} >
                                                        {errors.password.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                    Confirm Password<span className="mandatoryCss"> *</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        type={passwordVisibleConfirmPassword ? "text" : "password"}
                                                        placeholder="Enter Confirm Password"
                                                        {...register('confirmPassword', {
                                                            required: 'Please Enter Confirm Password',
                                                            minLength: {
                                                                value: 8,
                                                                message: 'Password must be at least 8 characters'
                                                            },
                                                            validate: (value) =>
                                                                value === watch('password') || 'Passwords do not match'
                                                        })}
                                                        className={isUserAccessView ? "textBoxNewView" : "textBoxNew"}
                                                        disabled={isUserAccessView}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={passwordVisibleConfirmPassword ? faEye : faEyeSlash}
                                                        onClick={togglePasswordVisibilityConfirm}
                                                        className="eyeContent"
                                                    />
                                                </div>
                                                {errors.confirmPassword && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }} >
                                                        {errors.confirmPassword.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                        Status
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                        <a
                                                            href="#status"
                                                            className="switch-container"
                                                            onClick={!isUserAccessView ? () => setValue('Status', !statusWatch) : undefined}
                                                            style={{ cursor: isUserAccessView ? 'not-allowed' : 'pointer' }}
                                                            disabled={isUserAccessView}
                                                        >
                                                            <div className={`switch ${statusWatch ? 'active' : ''}`}>
                                                                <div className="handle"></div>
                                                            </div>
                                                        </a>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor statusPadding">
                                                            {statusWatch ? 'Active' : 'Inactive'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">

                                            </div>
                                        </div>

                                        <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                            {!isUserAccessView && (
                                                <button type="submit" className="coloredButtonSave">Save</button>
                                            )}
                                            <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeUserAccess}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            )
            }
        </div >
    );
};

UserAccessPermission.propTypes = {
    control: PropTypes.object.isRequired,
    roleArray: PropTypes.arrayOf(PropTypes.shape({
        rolE_ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        rolE_User_Role: PropTypes.string.isRequired,
    })).isRequired,
    isUserAccessView: PropTypes.bool.isRequired,
    customStyles: PropTypes.object,
};

export default UserAccessPermission;

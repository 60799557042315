import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import GetUserAccess from '../../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { timeFormats, dateFormats, YearOptions, LanguagesOptions } from '../../../CommomPages/Config';
import { updateGSettings, getGSetting } from '../../../Services/GeneralSettingsService';


const GeneralSettingsSchema = yup.object().shape({
    timeZone: yup
        .object()
        .nullable()
        .required('Time Zone is required'),
    dateFormat: yup
        .object()
        .nullable()
        .required('Date Format is required'),
    language: yup
        .object()
        .nullable()
        .required('Language is required'),
    currency: yup
        .object()
        .nullable()
        .required('Currency is required'),
    financialYear: yup
        .object()
        .nullable()
        .required('Financial Year is required'),
    timeFormat: yup
        .object()
        .nullable()
        .required('Time Format is required'),
});

const GeneralSettings = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [userScreens, setUserScreens] = useState({});
    const [dateFormatOptions, setDateFormatOptions] = useState([]);
    const [timeFormatOptions, setTimeFormatOptions] = useState([]);
    const [yearFirstOptions, setYearFirstOptions] = useState([]);
    const [languagesDropOptions, setLanguagesDropOptions] = useState([]);
    const { control, handleSubmit, setValue, formState: { errors: GSerrors } } = useForm({ resolver: yupResolver(GeneralSettingsSchema) });

    const fetchGSData = async () => {
        try {
            const data = await getGSetting();
            const dateOpt = await dateFormats();
            const timeOpt = await timeFormats();
            const YearOpt = await YearOptions();
            const LanguagesOpt = await LanguagesOptions();
            const selectedFormat = dateOpt.find(format => format.value === data.gS_DateFormat);
            setLanguagesDropOptions(LanguagesOpt);
            setYearFirstOptions(YearOpt);
            setTimeFormatOptions(timeOpt);
            setDateFormatOptions(dateOpt);
            let TimeFormat;
            if (data.gS_TimeFormat === 12) {
                TimeFormat = "12-hour"
            }
            else if (data.gS_TimeFormat === 24) {
                TimeFormat = "24-hour"
            }
            setValue('timeZone', { value: data.gS_TimeZone, label: data.gS_TimeZone });
            setValue('dateFormat', { value: selectedFormat.value, label: selectedFormat.label });
            setValue('financialYear', { value: data.gS_FinancialYear, label: data.gS_FinancialYear });
            setValue('timeFormat', { value: TimeFormat, label: TimeFormat });
            setValue('currency', { value: data.gS_Currency, label: data.gS_Currency });
            setValue('language', { value: data.gS_Language, label: data.gS_Language });
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching General Setting data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUserScreens();
        fetchGSData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const handleCloseGeneralSettingsModal = () => {
        // navigate("/Dashboard");
        navigate("/Settings");
        setValue('dateFormat', null);
        setValue('language', null);
        setValue('timeZone', null);
        setValue('financialYear', null);
        setValue('timeFormat', null);
        setValue('currency', null);
    };

    const onSubmit = async (data) => {
        if (!userScreens["General Settings"]?.Edit || !userScreens["General Settings"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            setIsLoading(true);
            if (!data.currency?.value) {
                setIsLoading(false);
                toast.error('Currency is required');
                return;
            }
            if (!data.dateFormat?.value) {
                setIsLoading(false);
                toast.error('Date format is required');
                return;
            }
            if (!data.financialYear?.value) {
                setIsLoading(false);
                toast.error('Financial year is required');
                return;
            }
            if (!data.language?.value) {
                setIsLoading(false);
                toast.error('Language is required');
                return;
            }
            if (!data.timeFormat?.value) {
                setIsLoading(false);
                toast.error('Time format is required');
                return;
            }
            if (!["12-hour", "24-hour"].includes(data.timeFormat.value)) {
                setIsLoading(false);
                toast.error('Invalid time format');
                return;
            }
            if (!data.timeZone?.value) {
                setIsLoading(false);
                toast.error('Time zone is required');
                return;
            }
            let TimeFormat;
            if (data.timeFormat.value === "12-hour") {
                TimeFormat = 12
            }
            else {
                TimeFormat = 24
            }
            const payload = {
                GS_ID: 1,
                GS_Currency: data.currency.value,
                GS_DateFormat: data.dateFormat.value,
                GS_FinancialYear: data.financialYear.value,
                GS_Language: data.language.value,
                GS_TimeFormat: TimeFormat,
                GS_TimeZone: data.timeZone.value
            };
            const response = await updateGSettings(payload);
            if (response === "Updated") {
                setIsLoading(false);
                toast.success('Preferences saved successfully');
                setTimeout(() => navigate("/Settings"), 2000);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Failed to save preferences');
        }
    };

    const handleTimeZoneChange = (option) => {
        setValue('timeZone', option);
    };

    const handleDateFormatChange = (option) => {
        setValue('dateFormat', option);
    };

    const handleFinancialChange = (option) => {
        setValue('financialYear', option);
    };

    const handleTimeFormatChange = (option) => {
        setValue('timeFormat', option);
    };
    const handleCurrencyChange = (option) => {
        if (option) {
            setValue('currency', option);
        }
    };

    const handleLanguageChange = (option) => {
        setValue('language', option);
    };

    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleGeneral">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">General Settings<div><InfoOutlinedIcon /></div></div>
                    </div>

                    {userScreens["General Settings"]?.View && (
                        <div className="card mt-4 card-custom-GS">
                            <div className="card-body ">
                                <Box component="form" noValidate sx={{ mt: 1, width: '100%', padding: "20px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="timeZone"
                                                control={control}
                                                render={({ field }) => (
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Time Zone<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            {...field}
                                                            options={Intl.supportedValuesOf('timeZone').map(zone => ({ value: zone, label: zone }))}
                                                            placeholder="Select Time Zone"
                                                            isClearable
                                                            isSearchable
                                                            onChange={(option) => {
                                                                field.onChange(option);
                                                                handleTimeZoneChange(option);
                                                            }}
                                                        />
                                                        <FormHelperText error={!!GSerrors?.timeZone}>
                                                            {GSerrors?.timeZone?.message}
                                                        </FormHelperText>
                                                    </div>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="currency"
                                                control={control}
                                                render={({ field }) => (
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Currency<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            {...field}
                                                            options={Intl.supportedValuesOf('currency').map(currency => ({ value: currency, label: currency }))}
                                                            placeholder="Select Currency"
                                                            isClearable
                                                            isSearchable
                                                            onChange={(option) => {
                                                                field.onChange(option);
                                                                handleCurrencyChange(option);
                                                            }}
                                                        />
                                                        <FormHelperText error={!!GSerrors?.currency}>
                                                            {GSerrors?.currency?.message}
                                                        </FormHelperText>
                                                    </div>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="dateFormat"
                                                control={control}
                                                render={({ field }) => (
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Date Format<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            {...field}
                                                            options={dateFormatOptions.map(format => ({ value: format.value, label: format.label }))}
                                                            placeholder="Select Date Format"
                                                            isClearable
                                                            isSearchable
                                                            onChange={(option) => {
                                                                field.onChange(option);
                                                                handleDateFormatChange(option);
                                                            }}
                                                        />
                                                        <FormHelperText error={!!GSerrors?.dateFormat}>
                                                            {GSerrors?.dateFormat?.message}
                                                        </FormHelperText>
                                                    </div>
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="timeFormat"
                                                control={control}
                                                render={({ field }) => (
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Time Format<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            {...field}
                                                            options={timeFormatOptions.map(format => ({ value: format, label: format }))}
                                                            placeholder="Select Time Format"
                                                            isClearable
                                                            isSearchable
                                                            onChange={(option) => {
                                                                field.onChange(option);
                                                                handleTimeFormatChange(option);
                                                            }}
                                                        />
                                                        <FormHelperText error={!!GSerrors?.timeFormat}>
                                                            {GSerrors?.timeFormat?.message}
                                                        </FormHelperText>
                                                    </div>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="financialYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Financial Year Begins<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            {...field}
                                                            options={yearFirstOptions.map(option => ({ value: option.value, label: option.value }))}
                                                            placeholder="Select Financial Year"
                                                            isClearable
                                                            isSearchable
                                                            onChange={(option) => {
                                                                field.onChange(option);
                                                                handleFinancialChange(option);
                                                            }}
                                                        />
                                                        <FormHelperText error={!!GSerrors?.financialYear}>
                                                            {GSerrors?.financialYear?.message}
                                                        </FormHelperText>
                                                    </div>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name="language"
                                                control={control}
                                                render={({ field }) => (
                                                    <div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Language<span className="mandatoryCss"> *</span></div>
                                                        <Select
                                                            {...field}
                                                            options={languagesDropOptions.map(lang => ({ value: lang.code, label: lang.label }))}
                                                            placeholder="Select Language"
                                                            isClearable
                                                            isSearchable
                                                            onChange={(option) => {
                                                                field.onChange(option);
                                                                handleLanguageChange(option);
                                                            }}
                                                        />
                                                        <FormHelperText error={!!GSerrors?.language}>
                                                            {GSerrors?.language?.message}
                                                        </FormHelperText>
                                                    </div>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                            <div style={{ marginTop: "8%", display: 'flex', justifyContent: 'center', paddingTop: 15, paddingBottom: 10 }}>
                                <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButton ButtonSpace" onClick={handleSubmit(onSubmit)}>
                                    Submit
                                </button>
                                <button type="button" id="btncloseModal" onClick={handleCloseGeneralSettingsModal} className="fontFamily fontweightRegular fontsize16 outlineButton" style={{ marginLeft: '5px' }}>Cancel</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default GeneralSettings;

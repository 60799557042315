import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

import GetUserAccess from '../../../CommomPages/GetUserAccess';
import ConfirmDelete from '../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import {
    getDepartment,
    deleteDepartment,
    createDepartment,
    updateDepartment,
    getSubDepartment,
    createSubDepartment,
    updateSubDepartment,
    deleteSubDepartment,
    getTeam,
    createTeam,
    updateTeam,
    deleteTeam,
} from '../../../Services/DepartmentsAndTeamService';
import { getEmployee } from '../../../Services/EmployeeManagementService';
import { getASTSettings } from '../../../Services/AssetSettingsService';
import { getBUData, getBRData } from '../../../Services/BusinessEntityNewService';
import { getCheckIn, getCheckOut } from '../../../Services/ActionFormConfigurationService';
import { exportDataDepartment, exportDataSubDepartment, exportDataTeam } from './ExportDepartmentsAndTeam';

import './DepartmentsAndTeamCss.css';

import viewsvgIcon from '../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../assets/icon/Delete.svg';
import nodataFound from '../../../assets/img/nodatafound.png';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#B0ACAC',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};


const DepartmentsAndTeam = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userScreens, setUserScreens] = useState({});

    const [tabActive, setTabActive] = useState('Department');
    const [activeScreen, setActiveScreen] = useState('AccountDetails');
    const [tabStatusActive, setTabStatusActive] = useState('Department');
    const [isLoading, setIsLoading] = useState(true);
    const [allBusinessUnitArray, setAllBusinessUnitArray] = useState([]);
    const [allBusinessBranchArray, setAllBusinessBranchArray] = useState([]);
    const [allEmployeeArray, setAllEmployeeArray] = useState([]);
    const [filteredBusinessUnitArray, setFilteredBusinessUnitArray] = useState([]);
    const [filteredBusinessBranchArray, setFilteredBusinessBranchArray] = useState([]);
    const [filteredEmployeeArray, setFilteredEmployeeArray] = useState([]);
    const [searchQueryAll, setSearchQueryAll] = useState('');

    // Department Section
    const [allDepartmentArray, setAllDepartmentArray] = useState([]);
    const [filteredDepartmentArray, setFilteredDepartmentArray] = useState([]);
    const [pageDepartment, setPageDepartment] = useState(0);
    const [rowsDepartmentPerPage, setRowsDepartmentPerPage] = useState(5);
    const [departmentID, setDepartmentID] = useState(0);
    const [isDepartmentView, setIsDepartmentView] = useState(false);
    const [searchQueryDepartment, setSearchQueryDepartment] = useState('');
    const fileDepartmentInputRef = useRef(null);
    const {
        register: registerDepartment,
        handleSubmit: handleSubmitDepartment,
        formState: { errors: errorsDepartment },
        setValue: setValueDepartment,
        reset: resetDepartment,
        watch: watchDepartment
    } = useForm({
        defaultValues: {
            departmentName: '',
            departmentCode: '',
            departmentDescription: '',
            departmentStatus: true,
        }
    });

    const statusWatchDepartment = watchDepartment('departmentStatus');

    // Sub Department Section
    const [allSubDepartmentArray, setAllSubDepartmentArray] = useState([]);
    const [filteredSubDepartmentArray, setFilteredSubDepartmentArray] = useState([]);
    const [pageSubDepartment, setPageSubDepartment] = useState(0);
    const [rowsSubDepartmentPerPage, setRowsSubDepartmentPerPage] = useState(5);
    const [subDepartmentID, setSubDepartmentID] = useState(0);
    const [isSubDepartmentView, setIsSubDepartmentView] = useState(false);
    const [searchQuerySubDepartment, setSearchQuerySubDepartment] = useState('');
    const [departmentSelectID, setDepartmentSelectID] = useState(null);
    const fileSubDepartmentInputRef = useRef(null);
    const {
        register: registerSubDepartment,
        handleSubmit: handleSubmitSubDepartment,
        formState: { errors: errorsSubDepartment },
        setValue: setValueSubDepartment,
        reset: resetSubDepartment,
        watch: watchSubDepartment,
        control: controlSUBDepartmentName,
        trigger: triggerSubDepartment
    } = useForm({
        defaultValues: {
            departmentSelectID: null,
            subDepartmentName: '',
            subDepartmentCode: '',
            subDepartmentDescription: '',
            subDepartmentStatus: true,
        }
    });

    const statusWatchSubDepartment = watchSubDepartment('subDepartmentStatus');

    // Add useEffect to trigger validation when the form loads
    useEffect(() => {
        triggerSubDepartment('departmentSelectID');
    }, [triggerSubDepartment]);


    // Team Section
    const [teamArray, setTeamArray] = useState([]);
    const [pageTeam, setPageTeam] = useState(0);
    const [rowsTeamPerPage, setRowsTeamPerPage] = useState(5);
    const [teamID, setTeamID] = useState(0);
    const [isTeamView, setIsTeamView] = useState(false);
    const [searchQueryTeam, setSearchQueryTeam] = useState('');
    const [searchQueryEmployee, setSearchQueryEmployee] = useState('');
    const [selectedUnitIds, setSelectedUnitIds] = useState([]);
    const [selectedBranchIds, setSelectedBranchIds] = useState([]);
    const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
    const [selectedSubDepartmentIds, setSelectedSubDepartmentIds] = useState([]);
    const [selectedEmployeesIds, setSelectedEmployeesIds] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState('Level 1');
    const levels = ['Level 1', 'Level 2', 'Level 3'];
    const [levelEmployees, setLevelEmployees] = useState({ 'Level 1': [], 'Level 2': [], 'Level 3': [] });
    const fileTeamInputRef = useRef(null);
    const {
        register: registerTeam,
        handleSubmit: handleSubmitTeam,
        formState: { errors: errorsTeam },
        setValue: setValueTeam,
        reset: resetTeam,
        watch: watchTeam
    } = useForm({
        defaultValues: {
            teamName: '',
            teamDescription: '',
            teamStatus: true,
            teamLevel: 'Level 1',
        }
    });

    const statusWatchTeam = watchTeam('teamStatus');

    useEffect(() => {
        fetchUserScreens();
        const queryParams = getQueryParams(location.search);
        const tabName = queryParams.get('tab');
        selectedTab(tabName);
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const fetchData = async () => {
        setIsLoading(true);

        const TeamData = await getTeam();
        const modifiedTeamData = TeamData?.map((team, index) => {
            // Parse IDs into arrays
            const unitIds = team.unitId ? team.unitId?.split(',')?.map(Number) : [];
            const branchIds = team.branchId ? team.branchId?.split(',')?.map(Number) : [];
            const departmentIds = team.departmentId ? team.departmentId?.split(',')?.map(Number) : [];
            const subDepartmentIds = team.subDepartmentId ? team.subDepartmentId?.split(',')?.map(Number) : [];
            const empIds = team.empId ? JSON.parse(team.empId) : [];

            return {
                ...team,
                Rowid: index + 1,
                employeeCount: empIds.length,
                unitId: unitIds,
                branchId: branchIds,
                departmentId: departmentIds,
                subDepartmentId: subDepartmentIds,
                empId: empIds
            };
        });
        setTeamArray(modifiedTeamData);

        // Get all unique IDs from the teams to filter out already selected items
        const allTeamUnitIds = new Set(modifiedTeamData.flatMap(team => team.unitId));
        const allTeamBranchIds = new Set(modifiedTeamData.flatMap(team => team.branchId));
        const allTeamDepartmentIds = new Set(modifiedTeamData.flatMap(team => team.departmentId));
        const allTeamSubDepartmentIds = new Set(modifiedTeamData.flatMap(team => team.subDepartmentId));
        const allTeamEmpIds = new Set(modifiedTeamData.flatMap(team => team.empId));

        // Fetch and filter out IDs that are already in the team arrays
        const DepartmentData = await getDepartment();
        const modifiedDepartmentData = DepartmentData?.map((department, index) => ({
            ...department,
            Rowid: index + 1
        }));
        setAllDepartmentArray(modifiedDepartmentData);
        const filteredDepartmentData = modifiedDepartmentData?.filter(department => !allTeamDepartmentIds?.has(department?.id));
        setFilteredDepartmentArray(filteredDepartmentData);

        const SubDepartmentData = await getSubDepartment();
        const modifiedSubDepartmentData = SubDepartmentData?.map((subDepartment, index) => ({
            ...subDepartment,
            departmentName: modifiedDepartmentData.find(x => x?.id === subDepartment.departmentId)?.departmentName,
            Rowid: index + 1
        }));
        setAllSubDepartmentArray(modifiedSubDepartmentData);
        const filteredSubDepartmentData = modifiedSubDepartmentData?.filter(subDepartment => !allTeamSubDepartmentIds?.has(subDepartment?.id));
        setFilteredSubDepartmentArray(filteredSubDepartmentData);

        const empData = await getEmployee();
        const modifiedEmpData = empData?.map((employee, index) => ({
            ...employee,
            Rowid: index + 1,
            employeeName: `${employee?.firstName} ${employee?.lastName}`
        }));

        setAllEmployeeArray(modifiedEmpData);
        const filteredEmpData = modifiedEmpData?.filter(employee => !allTeamEmpIds?.has(employee?.id));
        setFilteredEmployeeArray(filteredEmpData);

        const unitData = await getBUData();
        const modifiedUnitData = unitData?.map((unit, index) => ({
            ...unit,
            Rowid: index + 1,
        }));
        setAllBusinessUnitArray(modifiedUnitData);
        const filteredUnitData = modifiedUnitData?.filter(unit => !allTeamUnitIds?.has(unit?.bU_ID));
        setFilteredBusinessUnitArray(filteredUnitData);

        const branchData = await getBRData();
        const modifiedBranchData = branchData?.map((branch, index) => ({
            ...branch,
            Rowid: index + 1,
        }));
        setAllBusinessBranchArray(modifiedBranchData);
        const filteredBranchData = modifiedBranchData?.filter(branch => !allTeamBranchIds?.has(branch?.bR_ID));
        setFilteredBusinessBranchArray(filteredBranchData);

        setIsLoading(false);
    };


    const selectedTab = (tabValue) => {
        setTabActive(tabValue);
        if (tabValue === "Department") {
            setTabStatusActive("Department")
        }
        else if (tabValue === "SubDepartment") {
            setTabStatusActive("Sub Department")
        }
        else if (tabValue === "Team") {
            setTabStatusActive("Team")
        }
    };


    // Department Section
    const handleDepartmentSubmit = async (data) => {
        if (!userScreens["Department"]?.Add || !userScreens["Department"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const { departmentName, departmentCode, departmentDescription, departmentStatus } = data;
        const departmentNameExists = allDepartmentArray.some(x => x.departmentName === departmentName);
        const departmentCodeExists = allDepartmentArray.some(x => x.departmentCode === departmentCode);
        const isUpdating = departmentID !== 0;

        if (isUpdating) {
            const currentDepartment = allDepartmentArray.find(x => x.id === departmentID);
            if (departmentNameExists && currentDepartment.departmentName !== departmentName) {
                toast.error("Department Name already exists.");
                return;
            }
            if (departmentCodeExists && currentDepartment.departmentCode !== departmentCode) {
                toast.error("Department Code already exists.");
                return;
            }
        } else {
            if (departmentNameExists) {
                toast.error("Department Name already exists.");
                return;
            }
            if (departmentCodeExists) {
                toast.error("Department Code already exists.");
                return;
            }
        }

        setIsLoading(true);

        const payload = {
            id: departmentID || 0,
            departmentName: departmentName?.trim(),
            departmentCode: departmentCode?.trim(),
            description: departmentDescription?.trim(),
            status: departmentStatus ? 'Active' : 'Inactive',
        };

        try {
            const response = isUpdating ? await updateDepartment(payload) : await createDepartment(payload);
            if (response === (isUpdating ? "Updated" : "Inserted")) {
                fetchData();
                toast.success(`Department ${isUpdating ? "Updated" : "Inserted"} Successfully`);
                if (isUpdating) setPageDepartment(0);
                closeDepartment();
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("An error occurred while submitting the department.");
        } finally {
            setIsLoading(false);
        }
    };


    const addNewDepartment = () => {
        if (!userScreens["Department"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsDepartmentView(false);
        setActiveScreen("DepartmentEdit");
        resetDepartment();
        setDepartmentID(0);
    };

    const viewOrEditDepartment = (departmentID, viewStatus) => {
        if (!viewStatus) {
            if (!userScreens["Department"]?.Edit) {
                toast.error('You do not have permission to this Action.');
                return;
            }
        }
        setIsDepartmentView(viewStatus);
        setDepartmentID(departmentID);
        const departmentData = allDepartmentArray.find(x => x.id === departmentID);
        setValueDepartment('departmentName', departmentData.departmentName);
        setValueDepartment('departmentCode', departmentData.departmentCode);
        setValueDepartment('departmentDescription', departmentData.description);
        setValueDepartment('departmentStatus', departmentData.status === 'Active');
        setActiveScreen("DepartmentEdit");
    };

    const DeleteDepartment = async (departmentID) => {
        if (!userScreens["Department"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            const checkInData = await getCheckIn();
            const checkOutData = await getCheckOut();
            const teamData = await getTeam();
            for (let team of teamData) {
                const teamIds = team.unitId?.split(',');
                if (teamIds.includes(departmentID)) {
                    toast.error(`Cannot delete the department as it is associated with one or more teams.`);
                    return;
                }
            }

            for (let cIn of checkInData) {
                if (cIn.unitId === departmentID) {
                    toast.error(`This Department is associated with ActionFormConfiguration - checkIn. You can't delete it.`);
                    return;
                }
            }

            for (let cOut of checkOutData) {
                if (cOut.unitId === departmentID) {
                    toast.error(`This Department is associated with ActionFormConfiguration - checkOut. You can't delete it.`);
                    return;
                }
            }

            const AssetData = await getASTSettings();
            const val = AssetData.find(r => r.id === 1);
            if (val.departmentId === departmentID) {
                toast.error(`This Department is associated with AssetSettings. You can't delete it.`);
                return;
            }

            const subDepartmentData = await getSubDepartment();
            const isAssociated = subDepartmentData.some(subDept => subDept.departmentId === departmentID);
            if (isAssociated) {
                toast.error(`Cannot delete the department as it is associated with one or more sub-departments.`);
                return;
            }

            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteDepartment(departmentID);
                if (response === "Deleted") {
                    toast.success(`Department deleted successfully.`);
                    fetchData();
                } else {
                    toast.error(response);
                }
            }
        } catch (error) {
            console.error("Error deleting department:", error);
            toast.error(`An error occurred while deleting the department.`);
        } finally {
            setIsLoading(false);
        }
    };

    const closeDepartment = () => {
        setIsDepartmentView(false);
        resetDepartment();
        setActiveScreen("AccountDetails");
    };

    const handleDepartmentFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let departmentArrayData = [];
            let departmentErrorArrayCount = [];

            jsonData.forEach((e) => {
                const departmentExists = allDepartmentArray.some(x => x?.departmentName === e.DepartmentName?.trim());
                if (departmentExists) {
                    toast.error(`${e.DepartmentName} already exists.`);
                    departmentErrorArrayCount.push(e);
                } else {
                    departmentArrayData.push(e);
                }
            });

            event.target.value = null;
            if (departmentArrayData.length > 0 && departmentErrorArrayCount.length === 0) {
                uploadDepartmentData(departmentArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadDepartmentData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data?.map(async (e) => {
                const payload = {
                    id: 0,
                    departmentName: e.DepartmentName?.trim(),
                    departmentCode: e.DepartmentCode?.trim(),
                    description: e.DepartmentDescription?.trim(),
                    status: e.DepartmentStatus ? 'Active' : 'Inactive'
                }
                const response = await createDepartment(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            fetchData();
            closeDepartment();
            setPageDepartment(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDepartmentImportClick = () => {
        if (!userScreens["Department"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        fileDepartmentInputRef.current.click();
    };

    const exportDepartment = (event) => {
        if (!userScreens["Department"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportDepartment(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportDepartment = (format) => {
        exportDataDepartment(allDepartmentArray, format);
    };


    // Sub Department Section
    const handleSubDepartmentSubmit = async (data) => {
        if (!userScreens["Sub Department"]?.Add || !userScreens["Sub Department"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }

        const { subDepartmentName, subDepartmentCode, subDepartmentDescription, subDepartmentStatus } = data;
        const subDepartmentnameExists = allSubDepartmentArray.some(x => x.subDepartmentName === subDepartmentName);
        const subDepartmentCodeExists = allSubDepartmentArray.some(x => x.subDepartmentCode === subDepartmentCode);
        const isUpdating = subDepartmentID !== 0;

        if (isUpdating) {
            const currentSubDepartment = allSubDepartmentArray.find(x => x.id === subDepartmentID);
            if (subDepartmentnameExists && currentSubDepartment.subDepartmentName !== subDepartmentName) {
                toast.error("Sub-Department Name already exists.");
                return;
            }
            if (subDepartmentCodeExists && currentSubDepartment.subDepartmentCode !== subDepartmentCode) {
                toast.error("Sub-Department Code already exists.");
                return;
            }
        } else {
            if (subDepartmentnameExists) {
                toast.error("Sub-Department Name already exists.");
                return;
            }
            if (subDepartmentCodeExists) {
                toast.error("Sub-Department Code already exists.");
                return;
            }
        }

        setIsLoading(true);
        const payload = {
            id: subDepartmentID || 0,
            departmentId: departmentSelectID || 0,
            subDepartmentName: subDepartmentName?.trim(),
            subDepartmentCode: subDepartmentCode?.trim(),
            description: subDepartmentDescription?.trim(),
            status: subDepartmentStatus ? 'Active' : 'Inactive'
        };

        const response = isUpdating ? await updateSubDepartment(payload) : await createSubDepartment(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            fetchData();
            toast.success(`Sub-Department ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            closeSubDepartment();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };


    const addNewSubDepartment = () => {
        if (!userScreens["Sub Department"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsSubDepartmentView(false);
        setActiveScreen("SubDepartmentEdit");
        resetSubDepartment();
        setDepartmentSelectID(0);
        setSubDepartmentID(0);
    };

    const viewOrEditSubDepartment = (subDepartmentID, viewStatus) => {
        if (!viewStatus) {
            if (!userScreens["Sub Department"]?.Edit) {
                toast.error('You do not have permission to this Action.');
                return;
            }
        }
        setIsSubDepartmentView(viewStatus);
        setSubDepartmentID(subDepartmentID);
        const subDepartmentData = allSubDepartmentArray.find(x => x.id === subDepartmentID);
        const departmentData = allDepartmentArray.find(x => x.id === subDepartmentData?.departmentId);
        setDepartmentSelectID(departmentData?.id);
        setValueSubDepartment('departmentSelectID', departmentData?.id);
        setValueSubDepartment('subDepartmentName', subDepartmentData?.subDepartmentName);
        setValueSubDepartment('subDepartmentCode', subDepartmentData?.subDepartmentCode);
        setValueSubDepartment('subDepartmentDescription', subDepartmentData?.description);
        setValueSubDepartment('subDepartmentStatus', subDepartmentData?.status === 'Active');
        setActiveScreen("SubDepartmentEdit");
    };

    const DeleteSubDepartment = async (subDepartmentID) => {
        if (!userScreens["Sub Department"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            const teamData = await getTeam();
            for (let team of teamData) {
                const teamIds = team.unitId?.split(',');
                if (teamIds.includes(subDepartmentID)) {
                    toast.error(`Cannot delete the sub-department as it is associated with one or more teams.`);
                    return;
                }
            }
            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteSubDepartment(subDepartmentID);
                if (response === "Deleted") {
                    toast.success("Sub Department Deleted Sucessfully")
                    fetchData();
                } else {
                    toast.error(response)
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error deleting sub-department:", error);
            toast.error(`An error occurred while deleting the sub-department.`);
        } finally {
            setIsLoading(false);
        }
    };

    const closeSubDepartment = () => {
        setIsSubDepartmentView(false);
        resetSubDepartment();
        setActiveScreen("AccountDetails");
    };

    const handleSubDepartmentFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let subDepartmentArrayData = [];
            let subDepartmentErrorArrayCount = [];

            jsonData.forEach((e) => {
                const departmentExists = allDepartmentArray.find(x => x.departmentName === e.DepartmentName?.trim());
                const subDepartmentExists = allSubDepartmentArray.some(x => x.subDepartmentName === e.SubDepartmentName?.trim() && x.departmentId === departmentExists?.id);

                if (subDepartmentExists) {
                    toast.error(`${e.SubDepartmentName} already exists.`);
                    subDepartmentErrorArrayCount.push(e);
                } else if (!departmentExists) {
                    toast.error(`Department name doesn't exist.`);
                    subDepartmentErrorArrayCount.push(e);
                } else {
                    e.DepartmentId = departmentExists.id;
                    subDepartmentArrayData.push(e);
                }
            });

            event.target.value = null;
            if (subDepartmentArrayData.length > 0 && subDepartmentErrorArrayCount.length === 0) {
                await uploadSubDepartmentData(subDepartmentArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadSubDepartmentData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data?.map(async (e) => {
                const payload = {
                    id: 0,
                    departmentId: e.DepartmentId || 0,
                    subDepartmentName: e.SubDepartmentName?.trim(),
                    subDepartmentCode: e.SubDepartmentCode?.trim(),
                    description: e.SubDepartmentDescription?.trim(),
                    status: e.SubDepartmentStatus ? 'Active' : 'Inactive'
                };
                const response = await createSubDepartment(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            fetchData();
            closeSubDepartment();
            setPageSubDepartment(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubDepartmentImportClick = () => {
        if (!userScreens["Sub Department"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        fileSubDepartmentInputRef.current.click();
    };

    const exportSubDepartment = (event) => {
        if (!userScreens["Sub Department"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportSubDepartment(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportSubDepartment = (format) => {
        exportDataSubDepartment(allSubDepartmentArray, format);
    };


    // Team Section
    const handleTeamSubmit = async (data) => {
        if (!userScreens["Team"]?.Add || !userScreens["Team"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }

        const isUpdating = teamID !== 0;
        const { teamName, teamDescription, teamStatus } = data;
        const teamExists = teamArray.some(x => x.teamName === teamName);
        const currentTeam = teamArray.find(x => x.id === teamID);

        if (isUpdating) {
            if (teamExists && currentTeam.teamName !== teamName) {
                toast.error("Team name already exists.");
                return;
            }
        } else if (teamExists) {
            toast.error("Team name already exists.");
            return;
        }

        setIsLoading(true);

        const payload = {
            id: teamID || 0,
            teamName: teamName?.trim(),
            unitId: selectedUnitIds?.join(',') || "",
            branchId: selectedBranchIds?.join(',') || "",
            departmentId: selectedDepartmentIds?.join(',') || "",
            subDepartmentId: selectedSubDepartmentIds?.join(',') || "",
            empId: selectedEmployeesIds.length > 0 ? JSON.stringify(selectedEmployeesIds) : currentTeam?.empId || '', // Assigning all employee IDs
            teamDescription: teamDescription?.trim() || "",
            levelOne: levelEmployees['Level 1']?.map(emp => emp.employeeID).join(',') || "",
            levelTwo: levelEmployees['Level 2']?.map(emp => emp.employeeID).join(',') || "",
            levelThree: levelEmployees['Level 3']?.map(emp => emp.employeeID).join(',') || "",
            status: teamStatus ? 'Active' : 'Inactive'
        };
        console.log("payload in team submit", payload);
        const response = isUpdating ? await updateTeam(payload) : await createTeam(payload);
        if (response === (isUpdating ? "Updated" : "Inserted")) {
            fetchData();
            toast.success(`Team ${isUpdating ? "Updated" : "Inserted"} Successfully`);
            if (isUpdating) setPageTeam(0);
            closeTeam();
        } else {
            toast.error(response);
        }
        setIsLoading(false);
    };

    const findEmployeeById = (employeeID) => {
        return allEmployeeArray.find(emp => emp.employeeID === employeeID);
    };

    const addNewTeam = () => {
        if (!userScreens["Team"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsTeamView(false);
        setActiveScreen("TeamEdit");
        resetTeam();
        setTeamID(0);
        setSelectedEmployee(null);
        setSelectedEmployeesIds([]);
        setLevelEmployees({ 'Level 1': [], 'Level 2': [], 'Level 3': [] });
        setSearchQueryAll('');
        setSearchQueryEmployee('');
        setSelectedUnitIds([]);
        setSelectedBranchIds([]);
        setSelectedDepartmentIds([]);
        setSelectedSubDepartmentIds([]);
    };


    const viewOrEditTeam = (teamID, viewStatus) => {
        if (!viewStatus) {
            if (!userScreens["Team"]?.Edit) {
                toast.error('You do not have permission to this Action.');
                return;
            }
        }
        setIsTeamView(viewStatus);
        setTeamID(teamID);
        setSearchQueryAll('');
        setSearchQueryEmployee('');

        const teamData = teamArray.find(x => x?.id === teamID);

        // Setting form values
        setValueTeam('teamName', teamData.teamName);
        setValueTeam('teamDescription', teamData.teamDescription);
        setValueTeam('teamStatus', teamData.status === 'Active');

        // Convert comma-separated strings to arrays and remove duplicates, but only if they are strings
        const unitIds = Array.isArray(teamData.unitId) ? teamData.unitId : (teamData.unitId ? [...new Set(teamData.unitId.split(','))] : []);
        const branchIds = Array.isArray(teamData.branchId) ? teamData.branchId : (teamData.branchId ? [...new Set(teamData.branchId.split(','))] : []);
        const departmentIds = Array.isArray(teamData.departmentId) ? teamData.departmentId : (teamData.departmentId ? [...new Set(teamData.departmentId.split(','))] : []);
        const subDepartmentIds = Array.isArray(teamData.subDepartmentId) ? teamData.subDepartmentId : (teamData.subDepartmentId ? [...new Set(teamData.subDepartmentId.split(','))] : []);

        // Setting selected IDs for checkboxes
        setSelectedUnitIds(unitIds);
        setSelectedBranchIds(branchIds);
        setSelectedDepartmentIds(departmentIds);
        setSelectedSubDepartmentIds(subDepartmentIds);

        // Setting level employees
        setLevelEmployees({
            'Level 1': teamData.levelOne ? teamData.levelOne.split(',').map(empID => findEmployeeById(empID)) : [],
            'Level 2': teamData.levelTwo ? teamData.levelTwo.split(',').map(empID => findEmployeeById(empID)) : [],
            'Level 3': teamData.levelThree ? teamData.levelThree.split(',').map(empID => findEmployeeById(empID)) : []
        });

        setActiveScreen("TeamEdit");
    };


    const handleCheckboxChange = (id, setSelectedIds) => {
        setSelectedIds(prevState => {
            const newState = new Set(prevState); // Use Set to remove duplicates
            const stringId = id.toString(); // Ensure id is treated as a string
            if (newState.has(stringId)) {
                newState.delete(stringId); // Remove the ID if it's already present
            } else {
                newState.add(stringId); // Add the ID if it's not present
            }
            return Array.from(newState); // Convert Set back to Array
        });
    };

    const handleEmployeeSearchChange = (e) => {
        setSearchQueryEmployee(e.target.value);
        setSelectedEmployee(null);
    };

    const handleEmployeeSelect = (employee) => {
        // Check if the employee ID is already in the selected list
        if (!selectedEmployeesIds.includes(employee.id)) {
            // Add the employee ID to the selected list
            setSelectedEmployeesIds(prevSelected => [...prevSelected, employee.id]);
            setSelectedEmployee(employee);
            setSearchQueryEmployee('');
        } else {
            toast.error("This user is already a member of this Team. Please choose a different user to add.");
        }
    };

    const handleLevelChange = (e) => {
        setSelectedLevel(e.target.value);
    };

    const addNewEmployee = () => {
        if (selectedEmployee) {
            setLevelEmployees(prevState => ({
                ...prevState,
                [selectedLevel]: [...(prevState[selectedLevel] || []), selectedEmployee]
            }));
            setSelectedEmployee(null);
        }
    };

    const removeEmployee = (level, employeeID) => {
        const updatedEmployees = levelEmployees[level]?.filter(employee => employee.id !== employeeID);
        setLevelEmployees({
            ...levelEmployees,
            [level]: updatedEmployees
        });
        setSelectedEmployeesIds(selectedEmployeesIds?.filter(id => id !== employeeID));
    };

    const closeTeam = () => {
        setIsTeamView(false);
        resetTeam();
        setActiveScreen("AccountDetails");
        setSelectedEmployee(null);
        setSelectedEmployeesIds([]);
        setLevelEmployees({ 'Level 1': [], 'Level 2': [], 'Level 3': [] });
        setSearchQueryAll('');
        setSearchQueryEmployee('');
        setSelectedUnitIds([]);
        setSelectedBranchIds([]);
        setSelectedDepartmentIds([]);
        setSelectedSubDepartmentIds([]);
    };

    const DeleteTeam = async (teamID) => {
        if (!userScreens["Team"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const response = await deleteTeam(teamID);
            if (response === "Deleted") {
                toast.success("Team Deleted Successfully");
                fetchData();
            } else {
                toast.error(response);
            }
            setIsLoading(false);
        }
    };

    const handleTeamFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            let teamArrayData = [];
            let teamErrorArrayCount = [];

            jsonData.forEach((e) => {
                const departmentExists = allDepartmentArray.find(x => x?.departmentName === e.DepartmentName);
                if (!departmentExists) {
                    toast.error(`Department name doesn't exist.`);
                    teamErrorArrayCount.push(e);
                } else {
                    const subDepartmentExists = allSubDepartmentArray.find(x => x.subDepartmentName === e.SubDepartmentName && x.departmentId === departmentExists.id);
                    if (!subDepartmentExists) {
                        toast.error(`Sub-Department name doesn't exist.`);
                        teamErrorArrayCount.push(e);
                    } else {
                        const teamExists = teamArray?.some(x => x.teamName === e.TeamName?.trim() && x.subDepartmentId === subDepartmentExists.id);
                        if (teamExists) {
                            toast.error(`${e.TeamName} already exists.`);
                            teamErrorArrayCount.push(e);
                        } else {
                            // Combine all employee IDs from Level 1, Level 2, and Level 3
                            const levels = ['Level 1', 'Level 2', 'Level 3'];
                            const allEmployeeIds = levels.flatMap(level => (levelEmployees[level] ? levelEmployees[level]?.map(emp => emp.employeeID) : [])).join(',');

                            teamArrayData.push({
                                id: e.TeamId || 0,
                                teamName: e.TeamName || "",
                                unitId: e.UnitIds?.join(',') || "All",
                                branchId: e.BranchIds?.join(',') || "All",
                                departmentId: e.DepartmentIds?.join(',') || "All",
                                subDepartmentId: e.SubDepartmentIds?.join(',') || "All",
                                empId: allEmployeeIds || "",
                                teamDescription: e.Description?.trim() || "",
                                levelOne: levelEmployees['Level 1']?.map(emp => emp.employeeID).join(',') || "",
                                levelTwo: levelEmployees['Level 2']?.map(emp => emp.employeeID).join(',') || "",
                                levelThree: levelEmployees['Level 3']?.map(emp => emp.employeeID).join(',') || "",
                                status: e.Status ? 'Active' : 'Inactive'
                            });
                        }
                    }
                }
            });

            event.target.value = null;
            if (teamArrayData.length > 0 && teamErrorArrayCount.length === 0) {
                uploadTeamData(teamArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadTeamData = async (data) => {
        try {
            setIsLoading(true);
            const uploadPromises = data?.map(async (e) => {
                // Combine all employee IDs from Level 1, Level 2, and Level 3
                const levels = ['Level 1', 'Level 2', 'Level 3'];
                const allEmployeeIds = levels.flatMap(level => (levelEmployees[level] ? levelEmployees[level]?.map(emp => emp.employeeID) : [])).join(',');

                const payload = {
                    id: e.id || 0,
                    teamName: e.teamName || "",
                    unitId: e.unitId?.join(',') || "All",
                    branchId: e.branchId?.join(',') || "All",
                    departmentId: e.departmentId?.join(',') || "All",
                    subDepartmentId: e.subDepartmentId?.join(',') || "All",
                    empId: allEmployeeIds || "",
                    teamDescription: e.teamDescription?.trim() || "",
                    levelOne: levelEmployees['Level 1']?.map(emp => emp.employeeID).join(',') || "",
                    levelTwo: levelEmployees['Level 2']?.map(emp => emp.employeeID).join(',') || "",
                    levelThree: levelEmployees['Level 3']?.map(emp => emp.employeeID).join(',') || "",
                    status: e.status ? 'Active' : 'Inactive'
                };
                const response = await createTeam(payload);
                if (response !== "Inserted") throw new Error("Failed to upload data");
            });
            await Promise.all(uploadPromises);
            fetchData();
            closeTeam();
            setPageTeam(0);
            toast.success("Data uploaded successfully.");
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleTeamImportClick = () => {
        if (!userScreens["Team"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        fileTeamInputRef.current.click();
    };

    const exportTeam = (event) => {
        if (!userScreens["Team"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportTeam(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportTeam = (format) => {
        exportDataTeam(teamArray, format, allDepartmentArray, allSubDepartmentArray);
    };


    // Department Pagination
    const handleDepartmentSearchChange = (event) => {
        setSearchQueryDepartment(event.target.value);
        setPageDepartment(0);
    };

    const filteredDepartment = allDepartmentArray?.filter((department) =>
        department?.departmentName?.toLowerCase()?.includes(searchQueryDepartment?.toLowerCase()) ||
        department?.departmentCode?.toLowerCase()?.includes(searchQueryDepartment?.toLowerCase()) ||
        department?.description?.toLowerCase()?.includes(searchQueryDepartment?.toLowerCase())
    );

    const handleRowsPerPageChange = (event) => {
        setRowsDepartmentPerPage(parseInt(event.target.value));
        setPageDepartment(0);
    };

    const handleDepartmentFirstPage = () => {
        handleDepartmentChangePagePagination({ selected: 0 });
    };

    const handleDepartmentLastPage = () => {
        handleDepartmentChangePagePagination({ selected: Math.ceil(filteredDepartment.length / rowsDepartmentPerPage) - 1 });
    };

    const handleDepartmentChangePagePagination = ({ selected }) => {
        setPageDepartment(selected);
    };

    const totalDepartment = filteredDepartment?.length;
    const startDepartmentEntry = pageDepartment * rowsDepartmentPerPage + 1;
    const endDepartmentEntry = Math.min((pageDepartment + 1) * rowsDepartmentPerPage, totalDepartment);


    // SubDepartment Pagination
    const handleSubDepartmentSearchChange = (event) => {
        setSearchQuerySubDepartment(event.target.value);
        setPageSubDepartment(0);
    };

    const filteredSubDepartment = allSubDepartmentArray?.filter((subDepartment) =>
        subDepartment?.departmentName?.toLowerCase()?.includes(searchQuerySubDepartment?.toLowerCase()) ||
        subDepartment?.subDepartmentName?.toLowerCase()?.includes(searchQuerySubDepartment?.toLowerCase()) ||
        subDepartment?.subDepartmentCode?.toLowerCase()?.includes(searchQuerySubDepartment?.toLowerCase()) ||
        subDepartment?.description?.toLowerCase()?.includes(searchQuerySubDepartment?.toLowerCase())
    );

    const handleRowsPerpageSubDepartmentChange = (event) => {
        setRowsSubDepartmentPerPage(parseInt(event.target.value));
        setPageSubDepartment(0);
    };

    const handleSubDepartmentFirstPage = () => {
        handleSubDepartmentChangePagePagination({ selected: 0 });
    };

    const handleSubDepartmentLastPage = () => {
        handleSubDepartmentChangePagePagination({ selected: Math.ceil(filteredSubDepartment.length / rowsSubDepartmentPerPage) - 1 });
    };

    const handleSubDepartmentChangePagePagination = ({ selected }) => {
        setPageSubDepartment(selected);
    };

    const totalSubDepartment = filteredSubDepartment?.length;
    const startSubDepartmentEntry = pageSubDepartment * rowsSubDepartmentPerPage + 1;
    const endSubDepartmentEntry = Math.min((pageSubDepartment + 1) * rowsSubDepartmentPerPage, totalSubDepartment);


    // Team Pagination
    const handleTeamSearchChange = (event) => {
        setSearchQueryTeam(event.target.value);
        setPageTeam(0);
    };

    const filteredTeam = teamArray?.filter((team) =>
        team?.teamName?.toLowerCase()?.includes(searchQueryTeam?.toLowerCase()) ||
        team?.status?.toLowerCase()?.startsWith(searchQueryTeam?.toLowerCase())
    );

    const handleRowsPerpageTeamChange = (event) => {
        setRowsTeamPerPage(parseInt(event.target.value));
        setPageTeam(0);
    };

    const handleTeamFirstPage = () => {
        handleTeamChangePagePagination({ selected: 0 });
    };

    const handleTeamLastPage = () => {
        handleTeamChangePagePagination({ selected: Math.ceil(filteredTeam.length / rowsTeamPerPage) - 1 });
    };

    const handleTeamChangePagePagination = ({ selected }) => {
        setPageTeam(selected);
    };

    const totalTeam = filteredTeam?.length;
    const startTeamEntry = pageTeam * rowsTeamPerPage + 1;
    const endTeamEntry = Math.min((pageTeam + 1) * rowsTeamPerPage, totalTeam);


    const handleAllSearchChange = (event) => {
        setSearchQueryAll(event.target.value);
    };

    const filteredUnitsToSelect = allBusinessUnitArray?.filter((unit) =>
        unit?.bU_NAME?.toLowerCase()?.includes(searchQueryAll?.toLowerCase())
    );

    const filteredBranchesToSelect = allBusinessBranchArray?.filter((branch) =>
        branch?.bR_NAME?.toLowerCase()?.includes(searchQueryAll?.toLowerCase())
    );

    const filteredDepartmentsToSelect = allDepartmentArray?.filter((department) =>
        department?.departmentName?.toLowerCase()?.includes(searchQueryAll?.toLowerCase())
    );

    const filteredSubDepartmentsToSelect = allSubDepartmentArray?.filter((subDepartment) =>
        subDepartment?.subDepartmentName?.toLowerCase()?.includes(searchQueryAll?.toLowerCase())
    );

    const filteredEmployees = filteredEmployeeArray?.filter((employee) =>
        employee?.employeeName?.toLowerCase()?.includes(searchQueryEmployee?.toLowerCase()) ||
        employee?.employeeID?.toLowerCase()?.includes(searchQueryEmployee?.toLowerCase())
    );

    console.log('filteremp', filteredEmployees)

    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleDepartment">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> Account Details &gt;&gt; </span>
                                {tabStatusActive}
                            </span>
                            <div>
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    </div>
                    {activeScreen === "AccountDetails" && (
                        <div className="card mt-4">
                            <div className="card-body-department">
                                <div className="CustomBoxShadow" style={{ margin: "20px" }}>
                                    <div className="HeaderTab">

                                        {userScreens["Department"]?.View && (
                                            <a href="#Department" style={{ marginLeft: "40px", width: "137px", height: "40px", textAlign: "center" }} className={`${tabActive === 'Department' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('Department')}>
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    Department
                                                </div>
                                            </a>
                                        )}

                                        {userScreens["Sub Department"]?.View && (
                                            <a href="#SubDepartment" style={{ width: "177px", height: "40px", textAlign: "center" }} className={`${tabActive === 'SubDepartment' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('SubDepartment')}>
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    Sub Department
                                                </div>
                                            </a>
                                        )}

                                        {userScreens["Team"]?.View && (
                                            <a href="#Team" style={{ marginRight: "40px", width: "80px", height: "40px", textAlign: "center" }} className={`${tabActive === 'Team' ? 'activeTab' : 'InactiveTab'}`} onClick={() => selectedTab('Team')}>
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    Team
                                                </div>
                                            </a>
                                        )}
                                    </div>

                                    {(userScreens["Department"]?.View && tabActive === 'Department') && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsDepartmentPerPage} onChange={handleRowsPerPageChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryDepartment}
                                                            onChange={handleDepartmentSearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileDepartmentInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleDepartmentFileChange}
                                                    />

                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewDepartment()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleDepartmentImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportDepartment} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "20%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "25%" }}>Department Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Code</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Description</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredDepartment.length > 0 ? (
                                                            filteredDepartment.slice(pageDepartment * rowsDepartmentPerPage, (pageDepartment + 1) * rowsDepartmentPerPage).map((department, index) => (
                                                                <tr key={department.Rowid} className="text-left">
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{department.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={department.departmentName}>{department.departmentName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={department.departmentCode}>{department.departmentCode}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={department.description}>{department.description}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewDepartment" onClick={() => viewOrEditDepartment(department.id, true)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editDepartment" onClick={() => viewOrEditDepartment(department.id, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteDepartment" onClick={() => DeleteDepartment(department.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {(userScreens['Sub Department']?.View && tabActive === 'SubDepartment') && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsSubDepartmentPerPage} onChange={handleRowsPerpageSubDepartmentChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQuerySubDepartment}
                                                            onChange={handleSubDepartmentSearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileSubDepartmentInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleSubDepartmentFileChange}
                                                    />
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewSubDepartment()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleSubDepartmentImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportSubDepartment} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16 " style={{ backgroundColor: '#F6F6F6', width: "15%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Department</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "23%" }}>Sub Department</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Code</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Description</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredSubDepartment.length > 0 ? (
                                                            filteredSubDepartment.slice(pageSubDepartment * rowsSubDepartmentPerPage, (pageSubDepartment + 1) * rowsSubDepartmentPerPage).map((subDepartment, index) => (
                                                                <tr key={subDepartment.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{subDepartment.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subDepartment.departmentName}>{subDepartment.departmentName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subDepartment.subDepartmentName}>{subDepartment.subDepartmentName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subDepartment.departmentCode}>{subDepartment.subDepartmentCode}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={subDepartment.description}>{subDepartment.description}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewSubDepartment" onClick={() => viewOrEditSubDepartment(subDepartment.id, true)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editSubDepartment" onClick={() => viewOrEditSubDepartment(subDepartment.id, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteSubDepartment" onClick={() => DeleteSubDepartment(subDepartment.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {(userScreens['Team']?.View && tabActive === 'Team') && (
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsTeamPerPage} onChange={handleRowsPerpageTeamChange}>
                                                            {[5, 10, 15].map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                            Entries
                                                        </span>
                                                        <input
                                                            type="text"
                                                            placeholder="Search..."
                                                            className="searchTextBox"
                                                            value={searchQueryTeam}
                                                            onChange={handleTeamSearchChange}
                                                            style={{ marginLeft: '28px' }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="addbuttondiv">
                                                    <input
                                                        type="file"
                                                        ref={fileTeamInputRef}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx"
                                                        onChange={handleTeamFileChange}
                                                    />

                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={() => addNewTeam()}> Add New </button>
                                                    <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleTeamImportClick}> Import </button>
                                                    <select className="fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportTeam} >
                                                        <option value="" hidden={true}>Export</option>
                                                        <option value="csv">CSV</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="fontFamily fontweightMedium fontsize16" style={{ backgroundColor: '#F6F6F6', width: "25%", padding: "2px 2px 2px 3%" }}>SI.No</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "25%" }}>Team Name</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "20%" }}>Users</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%" }}>Status</th>
                                                            <th className="fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredTeam.length > 0 ? (
                                                            filteredTeam.slice(pageTeam * rowsTeamPerPage, (pageTeam + 1) * rowsTeamPerPage).map((team, index) => (
                                                                <tr key={team.Rowid} >
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14  ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{team.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={team.teamName}>{team.teamName}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{team.employeeCount}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ color: team.status === 'Active' ? '#2ED47A' : '#EC2020' }}>{team.status}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ cursor: 'pointer' }}><a href="#viewTeam" onClick={() => viewOrEditTeam(team.id, true)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#editTeam" onClick={() => viewOrEditTeam(team.id, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#DeleteTeam" onClick={() => DeleteTeam(team.id)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {(userScreens["Department"]?.View && tabActive === 'Department') && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startDepartmentEntry} to ${endDepartmentEntry} of ${totalDepartment} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleDepartmentFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredDepartment.length / rowsDepartmentPerPage)}
                                                    forcePage={pageDepartment}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleDepartmentChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleDepartmentLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {(userScreens['Sub Department']?.View && tabActive === 'SubDepartment') && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startSubDepartmentEntry} to ${endSubDepartmentEntry} of ${totalSubDepartment} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleSubDepartmentFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredSubDepartment.length / rowsSubDepartmentPerPage)}
                                                    forcePage={pageSubDepartment}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleSubDepartmentChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleSubDepartmentLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}

                                {(userScreens['Team']?.View && tabActive === 'Team') && (
                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginLeft: '20px' }}>
                                        <div >
                                            <div className="grayTextColor fontFamily fontsize16 fontweightMedium">
                                                {`Showing ${startTeamEntry} to ${endTeamEntry} of ${totalTeam} entries`}
                                            </div>
                                        </div>
                                        <div >
                                            <div className="pagination-container d-flex align-items-center">
                                                <button onClick={handleTeamFirstPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                </button>
                                                <ReactPaginate
                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={Math.ceil(filteredTeam.length / rowsTeamPerPage)}
                                                    forcePage={pageTeam}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handleTeamChangePagePagination}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    renderOnZeroPageCount={null}
                                                />
                                                <button onClick={handleTeamLastPage} className="btn btn-link">
                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ padding: "0px 100px" }}>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {(userScreens["Department"]?.View && activeScreen === "DepartmentEdit") && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <form className="p-2" noValidate onSubmit={handleSubmitDepartment(handleDepartmentSubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Department Name<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomDept"
                                                placeholder="Enter Department Name"
                                                {...registerDepartment('departmentName', { required: 'Please Enter Department Name' })}
                                                disabled={isDepartmentView}
                                            />
                                            {errorsDepartment.departmentName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsDepartment.departmentName.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Department Code<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomDept"
                                                placeholder="Enter Department Code"
                                                {...registerDepartment('departmentCode', {
                                                    required: 'Please Enter Department Code',
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Code cannot exceed 10 characters'
                                                    }
                                                })}
                                                maxLength={10}
                                                disabled={isDepartmentView}
                                            />
                                            {errorsDepartment.departmentCode && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsDepartment.departmentCode.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row pTop24">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Description</div>
                                            <textarea
                                                placeholder=""
                                                className="textareaCustom"
                                                {...registerDepartment('departmentDescription')}
                                                disabled={isDepartmentView}
                                            />
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6" style={{ paddingTop: '10px' }}>
                                            <div>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                    Status
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                    <a
                                                        href="#status"
                                                        className="switch-container"
                                                        onClick={!isDepartmentView ? () => setValueDepartment('departmentStatus', !statusWatchDepartment) : undefined}
                                                        style={{ cursor: isDepartmentView ? 'not-allowed' : 'pointer' }}
                                                        disabled={isDepartmentView}
                                                    >
                                                        <div className={`switch ${statusWatchDepartment ? 'active' : ''}`}>
                                                            <div className="handle"></div>
                                                        </div>
                                                    </a>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                        {statusWatchDepartment ? 'Active' : 'Inactive'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "15%" }}>
                                        {!isDepartmentView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeDepartment}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {(userScreens["Sub Department"]?.View && activeScreen === "SubDepartmentEdit") && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <form className="p-2" noValidate onSubmit={handleSubmitSubDepartment(handleSubDepartmentSubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Select Department<span className="mandatoryCss"> *</span>
                                            </div>
                                            <Controller
                                                name="departmentSelectID"
                                                control={controlSUBDepartmentName}
                                                rules={{ required: 'Please select a department' }}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        value={allDepartmentArray.find(dept => dept.id === field.value) || null}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption ? selectedOption.id : null);
                                                            setDepartmentSelectID(selectedOption ? selectedOption.id : null);
                                                        }}
                                                        options={allDepartmentArray}
                                                        getOptionLabel={(option) => option.departmentName}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Department"
                                                        isClearable
                                                        isSearchable
                                                        className={`${errorsSubDepartment.departmentSelectID ? 'is-invalid' : ''}`}
                                                        isDisabled={isSubDepartmentView}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            />
                                            {errorsSubDepartment.departmentSelectID && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsSubDepartment.departmentSelectID.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                Sub Department Name<span className="mandatoryCss"> *</span>
                                            </div>
                                            <input
                                                className="textinputCustomDept"
                                                placeholder="Enter Sub Department Name"
                                                {...registerSubDepartment('subDepartmentName', { required: 'Please Enter Sub Department Name' })}
                                                disabled={isSubDepartmentView}
                                            />
                                            {errorsSubDepartment.subDepartmentName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsSubDepartment.subDepartmentName.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row pTop24">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Sub Department Code<span className="mandatoryCss"> *</span>
                                            </div>
                                            <input
                                                className="textinputCustomDept"
                                                placeholder="Enter Sub Department Code"
                                                {...registerSubDepartment('subDepartmentCode', {
                                                    required: 'Please Enter Sub Department Code',
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Code cannot exceed 10 characters'
                                                    }
                                                })}
                                                maxLength={10}
                                                disabled={isSubDepartmentView}
                                            />
                                            {errorsSubDepartment.subDepartmentCode && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsSubDepartment.subDepartmentCode.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                Description
                                            </div>
                                            <textarea
                                                placeholder=""
                                                className="textareaCustom"
                                                {...registerSubDepartment('subDepartmentDescription')}
                                                disabled={isSubDepartmentView}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: '20px' }}>
                                        <div>
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                Status
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <div
                                                    className="switch-container"
                                                    onClick={!isSubDepartmentView ? () => setValueSubDepartment('subDepartmentStatus', !statusWatchSubDepartment) : undefined}
                                                    onKeyDown={!isSubDepartmentView ? (e) => {
                                                        if (e.key === 'Enter' || e.key === ' ') {
                                                            setValueSubDepartment('subDepartmentStatus', !statusWatchSubDepartment);
                                                        }
                                                    } : undefined}
                                                    tabIndex={isSubDepartmentView ? -1 : 0}
                                                    style={{ cursor: isSubDepartmentView ? 'not-allowed' : 'pointer' }}
                                                    role="switch"
                                                    aria-checked={statusWatchSubDepartment}
                                                >
                                                    <div className={`switch ${statusWatchSubDepartment ? 'active' : ''}`}>
                                                        <div className="handle"></div>
                                                    </div>
                                                </div>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                    {statusWatchSubDepartment ? 'Active' : 'Inactive'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                        {!isSubDepartmentView && (
                                            <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                        )}
                                        <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeSubDepartment}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {(userScreens["Team"]?.View && activeScreen === "TeamEdit") && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body">
                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                    Add Team
                                </div>
                                <form className="p-2" noValidate onSubmit={handleSubmitTeam(handleTeamSubmit)}>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Team Name<span className="mandatoryCss"> *</span></div>
                                            <input
                                                className="textinputCustomDept"
                                                placeholder="Enter Team Name"
                                                {...registerTeam('teamName', { required: 'Please Enter Team Name' })}
                                                disabled={isTeamView}
                                            />
                                            {errorsTeam.teamName && (
                                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                                    {errorsTeam.teamName.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                        </div>
                                    </div>

                                    <div className="row pTop24">
                                        <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>Description</div>
                                            <textarea
                                                placeholder=""
                                                className="textareaCustom"
                                                {...registerTeam('teamDescription')}
                                                disabled={isTeamView}
                                            />
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6" style={{ paddingTop: '10px' }}>
                                            <div>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingTop: '4px' }}>
                                                    Status
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                    <div
                                                        className="switch-container"
                                                        onClick={!isTeamView ? () => setValueTeam('teamStatus', !statusWatchTeam) : undefined}
                                                        onKeyDown={!isTeamView ? (e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                setValueTeam('teamStatus', !statusWatchTeam);
                                                            }
                                                        } : undefined}
                                                        tabIndex={isTeamView ? -1 : 0}
                                                        style={{ cursor: isTeamView ? 'not-allowed' : 'pointer' }}
                                                        role="switch"
                                                        aria-checked={statusWatchTeam}
                                                    >

                                                        <div className={`switch ${statusWatchTeam ? 'active' : ''}`}>
                                                            <div className="handle"></div>
                                                        </div>
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '20px' }}>
                                                        {statusWatchTeam ? 'Active' : 'Inactive'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mt-4" style={{ maxHeight: '350px' }}>
                                        <div style={{ padding: '1px' }}>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                className="searchTextBox"
                                                value={searchQueryAll}
                                                onChange={handleAllSearchChange}
                                                style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '16px' }}
                                                disabled={isTeamView}
                                            />
                                        </div>
                                        <div className="customCardsTeam" style={{ display: 'flex', alignItems: 'center' }}>
                                            {/* Unit Card */}
                                            <div className="card mt-4" style={{ width: '257px', height: '224px', overflowY: 'auto', scrollbarWidth: 'thin', marginLeft: '20px', marginRight: '40px' }}>
                                                <div className="card-body smallCardBodyCustomTeam">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Unit
                                                        </div>
                                                    </div>
                                                    {filteredUnitsToSelect?.map((unit) => (
                                                        <div key={unit.Rowid} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                style={{ marginRight: '10px' }}
                                                                onChange={() => handleCheckboxChange(unit.bU_ID, setSelectedUnitIds)}
                                                                checked={selectedUnitIds.includes(unit.bU_ID.toString())}
                                                                disabled={isTeamView}
                                                            />
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px' }}>
                                                                {unit.bU_NAME}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* Branch Card */}
                                            <div className="card mt-4" style={{ width: '257px', height: '224px', overflowY: 'auto', scrollbarWidth: 'thin', marginRight: '40px' }}>
                                                <div className="card-body smallCardBodyCustomTeam">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Branch
                                                        </div>
                                                    </div>
                                                    {filteredBranchesToSelect?.map((branch) => (
                                                        <div key={branch.Rowid} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                style={{ marginRight: '10px' }}
                                                                onChange={() => handleCheckboxChange(branch.bR_ID, setSelectedBranchIds)}
                                                                checked={selectedBranchIds.includes(branch.bR_ID.toString())}
                                                                disabled={isTeamView}
                                                            />
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px' }}>
                                                                {branch.bR_NAME}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* Department Card */}
                                            <div className="card mt-4" style={{ width: '257px', height: '224px', overflowY: 'auto', scrollbarWidth: 'thin', marginRight: '40px' }}>
                                                <div className="card-body smallCardBodyCustomTeam">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Department
                                                        </div>
                                                    </div>
                                                    {filteredDepartmentsToSelect?.map((department) => (
                                                        <div key={department.Rowid} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                style={{ marginRight: '10px' }}
                                                                onChange={() => handleCheckboxChange(department.id, setSelectedDepartmentIds)}
                                                                checked={selectedDepartmentIds.includes(department.id.toString())}
                                                                disabled={isTeamView}
                                                            />
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px' }}>
                                                                {department.departmentName}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* Sub Department Card */}
                                            <div className="card mt-4" style={{ width: '257px', height: '224px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <div className="card-body smallCardBodyCustomTeam">
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Sub Department
                                                        </div>
                                                    </div>
                                                    {filteredSubDepartmentsToSelect?.map((subDepartment) => (
                                                        <div key={subDepartment.Rowid} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                style={{ marginRight: '10px' }}
                                                                onChange={() => handleCheckboxChange(subDepartment.id, setSelectedSubDepartmentIds)}
                                                                checked={selectedSubDepartmentIds.includes(subDepartment.id.toString())}
                                                                disabled={isTeamView}
                                                            />
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px' }}>
                                                                {subDepartment.subDepartmentName}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card mt-4">
                                        <div className="custom-container" style={{ alignItems: 'center', marginLeft: '28px', marginTop: '40px' }}>
                                            <div className="dropdownCreate-container">
                                                <select
                                                    value={selectedLevel}
                                                    onChange={handleLevelChange}
                                                    className="dropdownCreate"
                                                >
                                                    <option value="Level 1" className="fontFamily fontweightRegular fontsize14">Level 1</option>
                                                    <option value="Level 2" className="fontFamily fontweightRegular fontsize14">Level 2</option>
                                                    <option value="Level 3" className="fontFamily fontweightRegular fontsize14">Level 3</option>
                                                </select>
                                                <FontAwesomeIcon icon={faChevronDown} className="dropdownCreate-icon" />
                                            </div>
                                            <div>
                                                {selectedEmployee ? (
                                                    <div className="employee-detailsShow card" style={{ marginLeft: "20px" }}>
                                                        <span className="fontFamily fontweightRegular fontsize16 blackTextColor employee-info">
                                                            {selectedEmployee?.firstName} {selectedEmployee?.lastName}
                                                        </span>
                                                        <a href="#remove-icon" className="remove-icon" onClick={() => setSelectedEmployee(null)}>
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        className="searchTextBox"
                                                        value={searchQueryEmployee}
                                                        onChange={handleEmployeeSearchChange}
                                                        style={{ marginLeft: "20px" }}
                                                        disabled={isTeamView}
                                                    />
                                                )}
                                            </div>
                                            <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: selectedEmployee ? "40px" : "20px" }} onClick={addNewEmployee}> Add New </button>
                                        </div>

                                        {searchQueryEmployee && filteredEmployees.length > 0 && !selectedEmployee && (
                                            <div className="card mt-2" style={{ marginLeft: '250px', width: '257px', height: '224px', overflowY: 'auto', scrollbarWidth: 'thin', overflowX: 'none' }}>
                                                <div className="searchResults">
                                                    {filteredEmployees?.map(employee => (
                                                        <a href="#employee-detailsList" key={employee.id} className="employee-detailsList card mt-2" onClick={() => handleEmployeeSelect(employee)}>
                                                            <div className="searchResultItem fontFamily fontweightRegular fontsize16">
                                                                {employee?.firstName} {employee?.lastName}
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {levels?.map((level) => (
                                            <React.Fragment key={level}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium" style={{ marginLeft: '20px', marginTop: '15px' }}>
                                                        {level}
                                                    </div>
                                                    <div className="blackTextColor fontFamily fontsize14 fontweightRegular" style={{ marginLeft: '20px', marginTop: '15px' }}>
                                                        {levelEmployees[level]?.length || 0} Members
                                                    </div>
                                                </div>

                                                <div className="card mt-2" style={{ height: '158px', overflowY: 'auto', scrollbarWidth: 'thin', marginRight: '20px', marginLeft: '20px' }}>
                                                    <div className="employee-container">
                                                        {levelEmployees[level]?.map((employee) => (
                                                            <div key={employee.id} className="employee-details card">
                                                                <div className="employee-info">
                                                                    <span className="employee-name" title={`${employee?.firstName} ${employee?.lastName} (${employee?.employeeID})`}>
                                                                        {employee?.firstName}
                                                                    </span>
                                                                    <span className="employee-ID">({employee?.employeeID})</span>
                                                                </div>
                                                                <a href="#remove-icon" className="remove-icon" onClick={!isTeamView ? () => removeEmployee(level, employee?.id) : null}>
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}

                                        <div className="mb-2 text-center" style={{ marginTop: "5%" }}>
                                            {!isTeamView && (
                                                <button type="submit" className="coloredButtonSave">{isLoading ? 'Loading...' : 'Save'}</button>
                                            )}
                                            <button type="button" className="outlineButton" style={{ marginLeft: '18px' }} onClick={closeTeam}>Cancel</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    )}

                </div >
            )}
        </div >
    );
};

export default DepartmentsAndTeam;

const GetUserActionOnAsset = () => {
    return new Promise((resolve, reject) => {
        try {
            const rawScreens = localStorage.getItem('UserActionsOnAsset');
            const screens = JSON.parse(rawScreens);

            const filteredScreens = Object.keys(screens).reduce((acc, key) => {
                if (screens[key]) {
                    acc[key] = screens[key];
                }
                return acc;
            }, {});

            resolve(filteredScreens);
        } catch (error) {
            console.error('Error fetching user action data:', error);
            reject(error);
        }
    });
};

export default GetUserActionOnAsset;

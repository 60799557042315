import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { differenceInMonths, isBefore, format, parse } from 'date-fns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import GetUserAccess from '../../../../CommomPages/GetUserAccess';
import ConfirmDelete from "../../../../CommomPages/ConfirmDelete";
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { downloadSampleExcelInsurance } from "../ExportProcessFormConfiguration/ExportProcessFormConfiguration";

import { getDocument } from "../../../../Services/GeneralSettingsService";
import { SampleExcelDownload } from "../../ActionFormPopup/ActionFormFormPopup";
import { getAsset, CreateAssetHistory } from "../../../../Services/AssetSettingsService";
import { getDateFormat, getRegexForDateFormat, getAssetPrefix, getAssetPadding } from '../../../../CommomPages/Config';
import { getProFormInsurance, createProFormInsurance, updateProFormInsurance, deleteProFormInsurance } from '../../../../Services/ProcessFormConfigurationService';

import viewsvgIcon from '../../../../assets/icon/Eye.svg';
import editsvgIcon from "../../../../assets/icon/Edit.svg";
import redXmark from "../../../../assets/icon/RedXmark.svg";
import XMarksvgIcon from '../../../../assets/icon/X mark.svg';
import deletesvgIcon from "../../../../assets/icon/Delete.svg";
import nodataFound from "../../../../assets/img/nodatafound.png";
import greenTickMark from "../../../../assets/icon/GreenTickMark.svg";
import calendarsvgIcon from '../../../../assets/icon/calendarDate.svg';

const ProcessFormConfigurationInsurance = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        watch,
        resetField
    } = useForm({
        defaultValues: {
            assetTagId: '',
            assetName: '',
            insuranceName: '',
            insuranceCompany: '',
            contactPerson: '',
            policyNumber: '',
            phone: '',
            coverage: '',
            email: '',
            limits: '',
            insuranceStartDate: null,
            deductible: '',
            insuranceEndDate: null,
            premium: '',
            document: '',
            documentLink: '',
            Status: true,
        }
    });

    const location = useLocation();
    const [userScreens, setUserScreens] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState('');
    const [dateFormat, setDateFormat] = useState('');
    const [activeScreen, setActiveScreen] = useState('InsuranceView');
    const fileAssetInputRef = useRef(null);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");

    const [assetArray, setAssetArray] = useState([]);
    const [assetInsuranceArray, setAssetInsuranceArray] = useState([]);
    const [filteredInsuranceArray, setFilteredInsuranceArray] = useState([]);
    const [selectedInsuranceValue, setSelectedInsuranceValue] = useState('');
    const [isInsuranceView, setIsInsuranceView] = useState(false);
    const [enteredNumber, setEnteredNumber] = useState(null);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [selectedAssetIds, setSelectedAssetIds] = useState([]);
    const [showSampleExcelDownload, setShowSampleExcelDownload] = useState(false);
    const [insuranceDocID, setInsuranceDocID] = useState(0);
    const [insuranceTagID, setInsuranceTagID] = useState('');
    const [allActiveInsurance, setAllActiveInsurance] = useState(0);
    const [allExpiriedInsurance, setAllExpiriedInsurance] = useState(0);
    const [allNearlyExpiryInsurance, setAllNearlyExpiryInsurance] = useState(0);
    const [selectedCardTab, setSelectedCardTab] = useState('active');

    let localAssetDate = new Date();
    let offset = localAssetDate.getTimezoneOffset(); // Offset in minutes
    // Adjust for local time
    localAssetDate.setMinutes(localAssetDate.getMinutes() - offset);
    // Convert to ISO format without the 'Z'
    let localISODate = localAssetDate.toISOString().slice(0, -1);

    const documentLink = watch('documentLink');
    const assetTagId = watch('assetTagId');
    const statusWatch = watch('Status');

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    useEffect(() => {
        fetchUserScreens();
        fetchProFormInsurance();
        getInsuranceDocNo();
        const user = localStorage.getItem('UserName');
        setUserName(user);
        const queryParams = getQueryParams(location.search);
        const tabName = queryParams.get('tab');
        if (tabName == "AddNew") {
            handleInsuranceAdd();
        }
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    useEffect(() => {
        if (assetTagId) {
            console.log('assetTagId', assetTagId);
            const selectedAsset = assetArray?.find(asset => asset.assetID === assetTagId);
            console.log('selectedAsset', selectedAsset);
            if (selectedAsset) {
                console.log('selectedAsset.assetName', selectedAsset.assetName);
                setValue('assetName', selectedAsset.assetName);
            } else {
                resetField('assetName');
            }
        } else {
            resetField('assetName');
        }
    }, [assetTagId, setValue]);

    const formatDates = (dateString, dateCurrentFormat) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            return null;
        }
        return format(date, dateCurrentFormat);  // Converts to the desired format
    };

    const getInsuranceDocNo = async () => {
        try {
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 7);
            const newId = maxId.documentNo + 1;
            setInsuranceDocID(newId);

            const assetInsurancePrefix = 'INS';
            const assetPadding = await getAssetPadding();
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            setInsuranceTagID(`${assetInsurancePrefix.toUpperCase()}${zeros}${newId}`);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchProFormInsurance = async () => {
        setIsLoading(true);
        const dateCurrentFormat = await getDateFormat();
        setDateFormat(dateCurrentFormat);

        const assetData = await getAsset();
        setAssetArray(assetData);

        const insuranceData = await getProFormInsurance();

        const currentDate = new Date();
        const oneMonthLater = new Date();
        oneMonthLater.setMonth(currentDate.getMonth() + 1);

        let activeCount = 0;
        let expiredCount = 0;
        let nearlyExpiringCount = 0;

        const finalData = insuranceData?.map((insurance, index) => {
            const endDate = new Date(insurance?.insuranceEndDate);

            // Determine if the insurance is active, expired, or nearly expiring
            let currentStatus = false;
            let currentStatusString = '';

            if (endDate > currentDate) {
                // Active Insurance
                currentStatus = true;
                currentStatusString = 'active';
                activeCount++;

                // Check if it is nearly expiring (within 1 month)
                if (endDate <= oneMonthLater) {
                    // Nearly Expiring Insurance
                    currentStatusString = 'nearlyExpiry';
                    nearlyExpiringCount++;
                }
            } else {
                // Expired Insurance
                currentStatusString = 'expired';
                expiredCount++;
            }

            const selectedAssetIds = insurance.selectedAssetIds ? JSON.parse(insurance.selectedAssetIds) : [];

            return {
                ...insurance,
                Rowid: index + 1,
                formattedStartDate: formatDates(insurance?.insuranceStartDate, dateCurrentFormat),
                formattedEndDate: formatDates(insurance?.insuranceEndDate, dateCurrentFormat),
                selectedAssetIds: selectedAssetIds,
                currentStatus,
                currentStatusString,
            };
        });

        setAllActiveInsurance(activeCount);
        setAllExpiriedInsurance(expiredCount);
        setAllNearlyExpiryInsurance(nearlyExpiringCount);
        localStorage.setItem('InsuranceCount', nearlyExpiringCount);

        setAssetInsuranceArray(finalData);
        const activeData = finalData.filter((e) => e.currentStatusString == 'active' || e.currentStatusString == 'nearlyExpiry');
        setFilteredInsuranceArray(activeData);

        setIsLoading(false);
    };

    const handleSelectNumber = (event) => {
        const number = parseInt(event.target.value, 10);
        if (number >= 1 && number <= 365) {
            setEnteredNumber(number);
        } else {
            setEnteredNumber(null);
        }
    };

    const handleAddDate = () => {
        if (isInsuranceView) {
            return;
        }
        if (enteredNumber !== null) {
            // Ensure selectedNumbers is always an array before using includes()
            if (!selectedNumbers?.includes(enteredNumber)) {
                const updatedSelectedNumbers = [...selectedNumbers, enteredNumber];
                setSelectedNumbers(updatedSelectedNumbers);
                setValue("selectedNumbers", updatedSelectedNumbers);
                setEnteredNumber(null);
            } else {
                toast.error("You have already entered this number");
            }
        } else {
            toast.error("Please enter a number between 1 and 365");
        }
    };

    const handleRemoveDate = (number) => {
        if (isInsuranceView) {
            return;
        }
        setSelectedNumbers(prevSelected => prevSelected?.filter(selected => selected !== number));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check file size (limit to 2MB)
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            // Disallow PDFs and only allow JPEG, JPG, and PNG
            if (!['image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG, and PNG files are allowed.');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;

            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setValue('document', base64String);
                setValue('documentLink', fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const clearFileInput = () => {
        setValue('document', '');
        setValue('documentLink', '');
        document.getElementById('fileInput').value = null;
    };

    const onCancel = () => {
        setActiveScreen('InsuranceView');
        reset();
        setEnteredNumber(null);
        setSelectedNumbers([]);
        setSelectedAssetIds([]);
        setIsInsuranceView(false);
        setSelectedInsuranceValue('');
    };

    const handleInsuranceAdd = () => {
        if (!userScreens["Insurance"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setEnteredNumber(null);
        setSelectedNumbers([]);
        setSelectedAssetIds([]);
        setIsInsuranceView(false);
        setSelectedInsuranceValue('');
        setActiveScreen('InsuranceEdit');
    };

    const handleInsuranceViewOrEdit = (selectedValue, viewStatus) => {
        if (!viewStatus) {
            if (!userScreens["Insurance"]?.Edit) {
                toast.error('You do not have permission to this Action.');
                return;
            }
        }
        console.log('inside insurance view or edit', selectedValue, viewStatus)
        setIsInsuranceView(viewStatus);
        setActiveScreen('InsuranceEdit');
        setValue('assetTagId', selectedValue?.assetTagId);
        setValue('assetName', selectedValue?.assetName);
        setValue('insuranceName', selectedValue?.insuranceName);
        setValue('insuranceCompany', selectedValue?.insuranceCompany);
        setValue('contactPerson', selectedValue?.contactPerson);
        setValue('policyNumber', selectedValue?.policyNumber);
        setValue('phone', selectedValue?.phone);
        setValue('coverage', selectedValue?.coverage);
        setValue('email', selectedValue?.email);
        setValue('limits', selectedValue?.limits);
        setValue('insuranceStartDate', selectedValue?.insuranceStartDate?.split('T')[0]);
        setValue('deductible', selectedValue?.deductible);
        setValue('insuranceEndDate', selectedValue?.insuranceEndDate?.split('T')[0]);
        setValue('premium', selectedValue?.premium);
        setValue('document', selectedValue?.document);
        setValue('documentLink', selectedValue?.documentLink);
        setValue('Status', selectedValue?.currentStatus);
        if (selectedValue?.setReminders) {
            const remindersArray = selectedValue?.setReminders?.split(',').map(Number);
            setSelectedNumbers(remindersArray);
        }
        if (selectedValue?.selectedAssetIds.length > 0) {
            setSelectedAssetIds(selectedValue.selectedAssetIds);
        }
        setSelectedInsuranceValue(selectedValue);
    };

    const handleInsuranceSubmit = async (data) => {
        if (!userScreens["Insurance"]?.Add || !userScreens["Insurance"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            setIsLoading(true);
            // Find the selected asset based on assetTagId
            const selectedAsset = assetArray?.find(asset => asset.assetID === data?.assetTagId);

            // Construct the newSelectedAssetIds as an array
            const newSelectedAssetIds = !selectedInsuranceValue
                ? selectedAsset?.id ? [selectedAsset.id] : [] // Start a new array if no selectedInsuranceValue
                : [...new Set([...selectedAssetIds, selectedAsset?.id].filter(Boolean))]; // Ensure no duplicates and no undefined

            // Construct the payload
            const payload = {
                id: !selectedInsuranceValue ? 0 : selectedInsuranceValue.id,
                assetId: selectedAsset ? selectedAsset?.id : 0,
                assetTagId: selectedAsset ? selectedAsset?.assetID : '',
                assetName: selectedAsset ? selectedAsset?.assetName : '',
                insuranceTagId: !selectedInsuranceValue ? insuranceTagID : selectedInsuranceValue?.insuranceTagId,
                insuranceName: data?.insuranceName || '',
                insuranceCompany: data?.insuranceCompany || '',
                contactPerson: data?.contactPerson || '',
                phone: data?.phone || '',
                email: data?.email || '',
                policyNumber: data?.policyNumber || '',
                coverage: data?.coverage || '',
                deductible: data?.deductible || '',
                premium: data?.premium || '',
                insuranceStartDate: data?.insuranceStartDate || null,
                insuranceEndDate: data?.insuranceEndDate || null,
                document: data?.document || '',
                documentLink: data?.documentLink || '',
                setReminders: !selectedInsuranceValue ? data?.selectedNumbers?.join(',') : selectedNumbers?.join(','),
                selectedAssetIds: newSelectedAssetIds.length > 0 ? JSON.stringify(newSelectedAssetIds) : '',
                status: statusWatch ? "Active" : "Inactive",
                NextID: insuranceDocID
            };

            const response = selectedInsuranceValue == '' ? await createProFormInsurance(payload) : await updateProFormInsurance(payload);
            if (response === (selectedInsuranceValue == '' ? "Inserted" : "Updated")) {
                fetchProFormInsurance();
                onCancel();
                toast.success(`Insurance ${selectedInsuranceValue == '' ? "Inserted" : "Updated"} Successfully`);

                if (payload.assetId) {
                    const changedFields = [];
                    // Compare each field in the payload with the corresponding field in currentAssetData
                    for (const key in payload) {
                        if (payload.hasOwnProperty(key) && selectedInsuranceValue[key] !== payload[key] && key !== 'document' && key !== 'selectedAssetIds' && key !== 'NextID') {
                            let changedFrom = selectedInsuranceValue[key] || '';
                            let changedTo = payload[key] || '';
                            if (changedFrom !== changedTo) {
                                changedFields.push({
                                    assetField: key,
                                    assetChangedFrom: changedFrom,
                                    assetChangedTo: changedTo
                                });
                            }
                        }
                    }
                    for (const change of changedFields) {
                        const historyPayload = {
                            id: 0,
                            assetId: payload.assetId,
                            assetDate: localISODate,
                            assetEvent: "Insurance " + (selectedInsuranceValue == '' ? "Inserted" : "Updated"),
                            assetField: change.assetField,
                            assetChangedFrom: String(change.assetChangedFrom),
                            assetChangedTo: String(change.assetChangedTo),
                            assetActionBy: userName || '',
                        };
                        await CreateAssetHistory(historyPayload);
                    };
                }
            } else {
                toast.error(response);
            }
        } catch (error) {
            toast.error("Failed to Save Insurance");
        } finally {
            onCancel();
            setIsLoading(false);
        }
    };

    const handleDeleteInsurance = async (insurance) => {
        if (!userScreens["Insurance"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            const assetIds = insurance.selectedAssetIds
                .split(',')
                .map(id => Number(id.trim()))
                .filter(id => !isNaN(id));

            if (assetIds && assetIds.length > 0) {
                toast.error(`Cannot delete the insurance as it is associated with one or more assets.`);
                return;
            }

            const isConfirmed = await ConfirmDelete();
            if (isConfirmed) {
                setIsLoading(true);
                const response = await deleteProFormInsurance(insurance.id);
                if (response === "Deleted") {
                    toast.success("Insurance Deleted Successfully");
                    if (insurance.assetId) {
                        const historyPayload = {
                            id: 0,
                            assetId: insurance.assetId,
                            assetDate: localISODate,
                            assetEvent: "Insurance Delete",
                            assetField: '',
                            assetChangedFrom: '',
                            assetChangedTo: '',
                            assetActionBy: userName || '',
                        };
                        await CreateAssetHistory(historyPayload);
                    }
                    fetchProFormInsurance();
                } else {
                    toast.error(response);
                }
            }
        } catch (error) {
            console.error("Error deleting insurance:", error);
            toast.error(`An error occurred while deleting the insurance.`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAssetFileChange = async (event) => {
        const file = event.target.files[0];
        const fileType = file?.name.split('.').pop();

        if (file && (fileType === "xlsx" || fileType === "xls")) {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" }); // Default empty string for missing cells

            // Function to trim both the keys (headers) and their corresponding values
            const trimHeadersAndValues = (obj) => {
                const trimmedObj = {};
                Object.keys(obj).forEach(key => {
                    const trimmedKey = key.trim(); // Trim spaces in header names
                    const trimmedValue = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
                    trimmedObj[trimmedKey] = trimmedValue; // Assign trimmed key and value
                });
                return trimmedObj;
            };

            // Map through jsonData and apply trimHeadersAndValues to each row
            const trimmedJsonData = jsonData.map(trimHeadersAndValues);

            let insuranceArrayData = [];
            let insuranceErrorArrayCount = [];

            const assetPrefix = 'INS';
            // const assetPrefix = await getAssetPrefix();
            const assetPadding = await getAssetPadding();

            // Process each row of trimmed data
            trimmedJsonData.forEach((e) => {
                const insuranceExists = assetArray.some(x => x?.insuranceTagId === e?.['Insurance ID']?.trim());
                const zeros = '0'.repeat(parseInt(assetPadding, 10));
                const insuranceTagID = e?.['Insurance ID']?.trim()?.toUpperCase();

                // Create a regular expression to match the pattern
                const regex = new RegExp(`^${assetPrefix.toUpperCase()}${zeros}\\d+$`);
                if (insuranceExists) {
                    toast.error(`${e?.['Insurance ID']?.trim()} already exists.`);
                    insuranceErrorArrayCount.push(e);
                } else if (!regex.test(insuranceTagID)) {
                    toast.error(`${e?.['Insurance ID']?.trim()} this pattern not matches with configured pattern.`);
                } else {
                    insuranceArrayData.push(e);
                }
                const selectedAsset = assetArray?.find(asset => asset.assetID === e?.['Asset ID']);
                if (!selectedAsset) {
                    toast.error(`Asset ${e?.['Asset ID']} Not Exists`);
                }
            });

            event.target.value = null;
            if (insuranceArrayData.length > 0 && insuranceErrorArrayCount.length === 0) {
                uploadInsuranceData(insuranceArrayData);
            }
        } else {
            toast.error("Invalid file type. Please upload an Excel file.");
        }
    };

    const uploadInsuranceData = async (data) => {
        try {
            setIsLoading(true);
            let assetUploadErrorArrayCount = [];
            const assetInsurancePrefix = 'INS';
            const assetPadding = await getAssetPadding();
            const zeros = '0'.repeat(parseInt(assetPadding, 10));
            const documentData = await getDocument();
            const maxId = documentData.find(doc => doc.id === 7);
            let newId = maxId.documentNo + 1;

            const uploadPromises = data.map(async (e) => {
                try {
                    const selectedAsset = assetArray?.find(asset => asset.assetID === e?.['Asset ID']);
                    console.log('selectedAsset', selectedAsset);
                    const startDate = e?.['Start Date'] ? formatDateToISO(e['Start Date']) : null;
                    const endDate = e?.['End Date'] ? formatDateToISO(e['End Date']) : null;
                    const newSelectedAssetIds = selectedAsset?.id ? [selectedAsset.id] : [] // Start a new array if no selectedInsuranceValue

                    const payload = {
                        id: 0,
                        assetId: selectedAsset ? selectedAsset?.id : 0,
                        assetTagId: selectedAsset ? selectedAsset?.assetID : '',
                        assetName: selectedAsset ? selectedAsset?.assetName : '',
                        insuranceTagId: `${assetInsurancePrefix.toUpperCase()}${zeros}${newId}` || '',
                        insuranceName: e?.['Insurance Name'] || '',
                        insuranceCompany: e?.['Insurance Co.'] || '',
                        contactPerson: e?.['Contact Person'] || '',
                        phone: data?.Phone || '',
                        email: data?.Email || '',
                        policyNumber: e?.['Policy No'] || '',
                        coverage: data?.Coverage || '',
                        deductible: data?.Deducible || '',
                        premium: data?.Premium || '',
                        insuranceStartDate: startDate,
                        insuranceEndDate: endDate,
                        document: e?.['Document Name'] || '',
                        documentLink: e?.['Document (Base64)'] || '',
                        setReminders: e?.Reminders || '',
                        selectedAssetIds: newSelectedAssetIds.length > 0 ? JSON.stringify(newSelectedAssetIds) : '',
                        status: "Active",
                        NextID: newId
                    };
                    await createProFormInsurance(payload);
                    newId++;
                } catch (error) {
                    assetUploadErrorArrayCount.push(e);
                    toast.error(`Failed to upload Asset Insurance with ID: ${e?.['Insurance ID']}`);
                }
            });

            await Promise.all(uploadPromises);
            if (assetUploadErrorArrayCount.length > 0) {
                toast.error(`${assetUploadErrorArrayCount.length} asset insurances failed to upload.`);
            } else {
                toast.success("One or More Asset Insurance uploaded successfully.");
            }
            fetchProFormInsurance();
            setPageAsset(0);
        } catch (error) {
            toast.error("An error occurred while uploading data.");
        } finally {
            setIsLoading(false);
        }
    };

    // Function to reformat the date
    const formatDateToISO = (dateString) => {
        // List of possible date formats
        const possibleFormats = ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd'];

        let parsedDate;

        // Try each format until one successfully parses the date
        for (const formatString of possibleFormats) {
            try {
                parsedDate = parse(dateString, formatString, new Date());

                // Check if the parsed date is valid
                if (parsedDate instanceof Date && !isNaN(parsedDate)) {
                    return format(parsedDate, 'yyyy-MM-dd'); // Return ISO formatted date
                }
            } catch (error) {
                // Ignore the error and try the next format
            }
        }
        // Return null or throw an error if no format matched
        return null;
    };

    const handleAssetImportClick = () => {
        if (!userScreens["Insurance"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setShowSampleExcelDownload(true);
    };

    const closeSampleExcelDownload = () => {
        setShowSampleExcelDownload(false);
    };

    const handleDownloadSampleExcel = () => {
        downloadSampleExcelInsurance();
    };

    const onClickFileAssetInputRef = () => {
        fileAssetInputRef.current.click();
    };

    const handleCardChange = (selectedCard) => {
        if (!userScreens["Insurance"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        if (selectedCard == 'active') {
            const activeData = assetInsuranceArray.filter((e) => e.currentStatusString == 'active' || e.currentStatusString == 'nearlyExpiry');
            setFilteredInsuranceArray(activeData);
            setSelectedCardTab('active');
        } else if (selectedCard == 'expired') {
            const expiredData = assetInsuranceArray.filter((e) => e.currentStatusString == 'expired');
            setFilteredInsuranceArray(expiredData);
            setSelectedCardTab('expired');
        } else if (selectedCard == 'nearlyExpiry') {
            const nearlyExpiryData = assetInsuranceArray.filter((e) => e.currentStatusString == 'nearlyExpiry');
            setFilteredInsuranceArray(nearlyExpiryData);
            setSelectedCardTab('nearlyExpiry');
        }
    };

    // Asset Section Search and Pagination
    const handleSearchChangeAsset = (event) => {
        const searchValue = event.target.value;
        setSearchQueryAsset(searchValue);
        const filteredInsurance = searchValue != '' ? assetInsuranceArray?.filter((insurance) =>
            insurance?.assetTagId?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            insurance?.insuranceTagId?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            insurance?.insuranceName?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            insurance?.insuranceCompany?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            insurance?.formattedStartDate?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            insurance?.formattedEndDate?.toLowerCase()?.includes(searchQueryAsset?.toLowerCase()) ||
            insurance?.status?.toLowerCase()?.startsWith(searchQueryAsset?.toLowerCase())
        ) : assetInsuranceArray;
        setFilteredInsuranceArray(filteredInsurance);
        setPageAsset(0);
    };

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(filteredInsuranceArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const totalEntriesAsset = filteredInsuranceArray?.length;
    const startEntryAsset = pageAsset * rowsPerPageAsset + 1;
    const endEntryAsset = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesAsset);


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            {/* <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span> Asset - Process Form Configuration - Insurance </span>
                            </span> */}
                            <span>
                                <span>Inbox &gt;&gt;</span>
                                <span> Asset - Process Form Configuration - Insurance </span>
                            </span>
                            <div><InfoOutlinedIcon /></div></div>
                    </div>
                    {(userScreens["Insurance"]?.View && activeScreen === "InsuranceView") && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="card-body-category">
                                    <div id="printableContent">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mt-4 pLeft20">
                                            Manage Insurance
                                        </div>
                                        <div className="grayTextColor fontFamily fontsize16 fontweightRegular mt-4 pLeft20 pRight150">
                                            The list below displays insurance policies associated with your organization. Entries in red indicate expired policies. To create a new contract, click New Insurance Policy and Enter the necessary information.
                                        </div>
                                        <div className='' style={{ display: 'flex', justifyContent: 'flex-start', margin: '20px' }}>
                                            <div className="CustomBoxShadow" style={{ width: '10%', padding: '2% 1%', cursor: 'pointer', background: selectedCardTab == 'active' ? '#2ED47A' : 'none' }} onClick={() => handleCardChange('active')}>
                                                <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ color: selectedCardTab == 'active' ? 'white' : '#808093' }}>
                                                    Active
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {allActiveInsurance}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '10%', padding: '2% 1%', marginLeft: '2%', cursor: 'pointer', background: selectedCardTab == 'expired' ? '#2ED47A' : 'none' }} onClick={() => handleCardChange('expired')}>
                                                <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ color: selectedCardTab == 'expired' ? 'white' : '#808093' }}>
                                                    Expired
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {allExpiriedInsurance}
                                                </div>
                                            </div>
                                            <div className="CustomBoxShadow" style={{ width: '10%', padding: '2% 1%', marginLeft: '2%', cursor: 'pointer', background: selectedCardTab == 'nearlyExpiry' ? '#2ED47A' : 'none' }} onClick={() => handleCardChange('nearlyExpiry')}>
                                                <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ color: selectedCardTab == 'nearlyExpiry' ? 'white' : '#808093' }}>
                                                    Near Expiry
                                                </div>
                                                <div className="blackTextColor fontFamily fontsize20 fontweightMedium textAlignCenter mt-4">
                                                    {allNearlyExpiryInsurance}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center" style={{ padding: "0px 20px" }}>
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                        Entries
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchQueryAsset}
                                                    onChange={handleSearchChangeAsset}
                                                    className="searchTextBox"
                                                />
                                            </div>

                                            <div className="addbuttondiv">
                                                <input
                                                    type="file"
                                                    ref={fileAssetInputRef}
                                                    style={{ display: 'none' }}
                                                    accept=".xlsx"
                                                    onChange={handleAssetFileChange}
                                                />
                                                <button
                                                    type="button"
                                                    id="BtnaddUnit"
                                                    className="fontFamily fontweightRegular fontsize16 coloredButtonAdd"
                                                    onClick={handleInsuranceAdd}
                                                >
                                                    New Insurance
                                                </button>
                                                <button
                                                    type="button"
                                                    className="fontFamily fontweightRegular fontsize16 importBtn"
                                                    onClick={handleAssetImportClick}
                                                >
                                                    Import Insurances
                                                </button>
                                            </div>
                                        </div>

                                        <div className="p-1" style={{ margin: "15px" }}>
                                            <div className="EmpBoxShadow">
                                                <div className="" style={{ padding: "10px" }}>
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Asset ID </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "14%" }} > Insurance ID </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "13%" }} > Insurance Name </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "14%" }} > Insurance Co. </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "14%" }} > Start Date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "14%" }} > End Date </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 paddingForUniqueRow" style={{ width: "10%" }} > Status </th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }} > Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredInsuranceArray.length > 0 ? (
                                                                filteredInsuranceArray.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((Insurance, index) => (
                                                                    <tr key={Insurance.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} ><a href={`/AssetDetailsView?id=${Insurance.assetId}`}><span style={{ color: "#605BFF" }}>{Insurance.assetTagId}</span></a></td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} ><a href={`/AssetDetailsView?id=${Insurance.assetId}`}><span style={{ color: "#605BFF" }}>{Insurance.insuranceTagId}</span></a></td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Insurance?.insuranceName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Insurance?.insuranceCompany}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Insurance?.formattedStartDate}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Insurance?.formattedEndDate}</td>
                                                                        <td className={`${index % 2 === 0 ? "" : "tableDataBG"} p-2 paddingForUniqueRow`} style={{ color: Insurance.currentStatus === true ? '#2ED47A' : '#EC2020' }}>
                                                                            {Insurance.status}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? "" : "tableDataBG"}`} >
                                                                            <span style={{ cursor: "pointer" }}><a href="#viewInsurance" onClick={() => handleInsuranceViewOrEdit(Insurance, true)}><img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href="#editInsurance" onClick={() => handleInsuranceViewOrEdit(Insurance, false)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: "10px", cursor: "pointer", }}><a href="#deleteInsurance" onClick={() => handleDeleteInsurance(Insurance)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntriesAsset > 0 && (
                                                <div>
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntryAsset} to {endEntryAsset} of {totalEntriesAsset} entries
                                                        </div>
                                                        <div>
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={"..."}
                                                                    breakClassName={"break-me"}
                                                                    pageCount={Math.ceil(totalEntriesAsset / rowsPerPageAsset)}
                                                                    forcePage={pageAsset}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePaginationAsset}
                                                                    containerClassName={"pagination"}
                                                                    subContainerClassName={"pages pagination"}
                                                                    activeClassName={"active"}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {(userScreens["Insurance"]?.View && activeScreen === "InsuranceEdit") && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                        Insurance - {selectedInsuranceValue == '' ? insuranceTagID : selectedInsuranceValue.insuranceTagId}</div>
                                    <form className="p-2" noValidate onSubmit={handleSubmit(handleInsuranceSubmit)}>
                                        <div className="row pTop24">
                                            <div className="w-100">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Insurance Name<span className="mandatoryCss"> *</span></div>
                                                <input
                                                    type="text"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('insuranceName', { required: "Insurance Name is required" })}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.insuranceName && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.insuranceName.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="w-100">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Insurance Company<span className="mandatoryCss"> *</span></div>
                                                <input
                                                    type="text"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('insuranceCompany', { required: "Insurance Company is required" })}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.insuranceCompany && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.insuranceCompany.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Asset ID</div>
                                                <Controller
                                                    name="assetTagId"
                                                    control={control}
                                                    // rules={{ required: 'Asset ID field is required' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={assetArray.find(repo => repo.assetID === field.value) || null}
                                                            onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.assetID : null) }}
                                                            options={assetArray}
                                                            placeholder="Select Asset ID"
                                                            getOptionLabel={(option) => option.assetID + " - " + option.assetName}
                                                            getOptionValue={(option) => option.assetID}
                                                            isClearable
                                                            isSearchable
                                                            isDisabled={isInsuranceView}
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                                {errors.assetTagId && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.assetTagId.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Asset Name</div>
                                                <input
                                                    type="text"
                                                    className="textBoxNewView"
                                                    {...register('assetName')}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Contact Person</div>
                                                <input
                                                    type="text"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('contactPerson')}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.contactPerson && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.contactPerson.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Policy No<span className="mandatoryCss"> *</span></div>
                                                <input
                                                    type="text"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('policyNumber', { required: "Policy Number is required" })}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.policyNumber && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.policyNumber.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Phone</div>
                                                <input
                                                    type="number"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('phone')}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.phone && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.phone.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Coverage</div>
                                                <input
                                                    type="text"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('coverage')}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.coverage && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.coverage.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Email</div>
                                                <input
                                                    type="text"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('email', {
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: 'Please Enter a Valid Email Address'
                                                        }
                                                    })}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.email && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.email.message}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Limits</div>
                                                <input
                                                    type="text"
                                                    className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                    {...register('limits')}
                                                    disabled={isInsuranceView}
                                                />
                                                {errors.limits && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.limits.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                    Start Date<span className="mandatoryCss"> *</span>
                                                </div>
                                                <div className='position-relative'>
                                                    <Controller
                                                        name="insuranceStartDate"
                                                        control={control}
                                                        rules={{
                                                            required: "Start Date is required",
                                                            validate: {
                                                                validDate: (value) => {
                                                                    if (!value) return "Start Date is required";
                                                                    if (!selectedInsuranceValue.insuranceStartDate) {
                                                                        // Check if the value is a valid date object
                                                                        const dateValue = new Date(value);
                                                                        if (isNaN(dateValue.getTime())) {
                                                                            return "Invalid date value";
                                                                        }

                                                                        // Format the date and validate it
                                                                        const formattedDate = format(dateValue, dateFormat);
                                                                        const regex = getRegexForDateFormat(dateFormat);

                                                                        // Check for valid date format
                                                                        if (!regex.test(formattedDate)) {
                                                                            return "Invalid date format (" + dateFormat + ")";
                                                                        }
                                                                    }

                                                                    const selectedDate = new Date(value);
                                                                    const today = new Date();
                                                                    today.setHours(0, 0, 0, 0); // Set time to 0 for accurate comparison

                                                                    // Check if date is in the future
                                                                    if (selectedDate > today) {
                                                                        return "Start Date cannot be a future date";
                                                                    }

                                                                    // Check if Start Date is before End Date
                                                                    if (selectedInsuranceValue.insuranceEndDate) {
                                                                        const endDate = new Date(selectedInsuranceValue.insuranceEndDate);
                                                                        if (selectedDate > endDate) {
                                                                            return "Start Date must be before End Date";
                                                                        }
                                                                    }

                                                                    return true;
                                                                }
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                selected={field.value}
                                                                onChange={(date) => field.onChange(date)}
                                                                dateFormat={dateFormat}
                                                                placeholderText={dateFormat.toUpperCase()}
                                                                className={isInsuranceView ? "DatePickerDisable" : "DatePicker"}
                                                                disabled={isInsuranceView}
                                                                ref={(input) => field.ref(input?.inputElement)}
                                                            />
                                                        )}
                                                    />
                                                    <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                                </div>
                                                {errors.insuranceStartDate && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.insuranceStartDate.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Deductible</div>
                                                <Controller
                                                    name="deductible"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            isNumeric: (value) => {
                                                                if (value && isNaN(value)) {
                                                                    return "Deductible must be a numeric value";
                                                                }
                                                                if (value && value <= 0) {
                                                                    return "Deductible must be greater than zero";
                                                                }
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <input
                                                            type="text"
                                                            className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                const rawValue = e.target.value.replace(/[^0-9.]/g, ""); // Strip non-numeric characters except "."
                                                                field.onChange(rawValue); // Store raw numeric value in form state
                                                            }}
                                                            disabled={isInsuranceView}
                                                        />
                                                    )}
                                                />
                                                {errors.deductible && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.deductible.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                                    End Date<span className="mandatoryCss"> *</span>
                                                </div>
                                                <div className='position-relative'>
                                                    <Controller
                                                        name="insuranceEndDate"
                                                        control={control}
                                                        rules={{
                                                            required: "End Date is required",
                                                            validate: {
                                                                validDate: (value) => {
                                                                    if (!value) return "End Date is required";
                                                                    if (!selectedInsuranceValue.insuranceEndDate) {
                                                                        // Check if the value is a valid date object
                                                                        const dateValue = new Date(value);
                                                                        if (isNaN(dateValue.getTime())) {
                                                                            return "Invalid date value";
                                                                        }

                                                                        // Format the date and validate it
                                                                        const formattedDate = format(dateValue, dateFormat);
                                                                        const regex = getRegexForDateFormat(dateFormat);

                                                                        // Check for valid date format
                                                                        if (!regex.test(formattedDate)) {
                                                                            return "Invalid date format (" + dateFormat + ")";
                                                                        }
                                                                    }

                                                                    const selectedDate = new Date(value);
                                                                    const startDate = new Date(watch("insuranceStartDate"));

                                                                    // Check if End Date is after Start Date
                                                                    if (startDate && selectedDate < startDate) {
                                                                        return "End Date should be after the Start Date";
                                                                    }

                                                                    return true;
                                                                }
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                selected={field.value}
                                                                onChange={(date) => field.onChange(date)}
                                                                dateFormat={dateFormat}
                                                                placeholderText={dateFormat.toUpperCase()}
                                                                className={isInsuranceView ? "DatePickerDisable" : "DatePicker"}
                                                                disabled={isInsuranceView}
                                                                ref={(input) => field.ref(input?.inputElement)}
                                                            />
                                                        )}
                                                    />
                                                    <img className="eyeContent" src={calendarsvgIcon} alt="calendarsvgIcon" />
                                                </div>
                                                {errors.insuranceEndDate && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.insuranceEndDate.message}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Premium</div>
                                                <Controller
                                                    name="premium"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            isNumeric: (value) => {
                                                                if (value && isNaN(value)) {
                                                                    return "Premium must be a numeric value";
                                                                }
                                                                if (value && value <= 0) {
                                                                    return "Premium must be greater than zero";
                                                                }
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <input
                                                            type="text"
                                                            className={isInsuranceView ? "textBoxNewView" : "textBoxNew"}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                const rawValue = e.target.value.replace(/[^0-9.]/g, ""); // Strip non-numeric characters except "."
                                                                field.onChange(rawValue); // Store raw numeric value in form state
                                                            }}
                                                            disabled={isInsuranceView}
                                                        />
                                                    )}
                                                />
                                                {errors.premium && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.premium.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div className="flexContainer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                                                    <div className="fontFamily fontweightRegular fontsize16 blackTextColor">
                                                        Set Reminder before insurance expiry (in days)
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            min={1}
                                                            max={365}
                                                            value={enteredNumber || ''}
                                                            className="custom-textBox blackTextColor fontFamily fontweightRegular fontsize12"
                                                            onChange={handleSelectNumber}
                                                            placeholder="Enter Count"
                                                            disabled={isInsuranceView}
                                                        />
                                                    </div>
                                                    <div className="flexEnd" style={{ marginLeft: '16px' }}>
                                                        <button
                                                            type="button"
                                                            className="fontFamily fontweightRegular fontsize16 coloredButton"
                                                            onClick={handleAddDate}
                                                            style={{ cursor: isInsuranceView ? 'not-allowed' : 'pointer' }}
                                                            disabled={isInsuranceView}
                                                        >
                                                            + Add
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="card mt-2" style={{ height: '158px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                    <div className="employee-container">
                                                        {selectedNumbers.map((number) => (
                                                            <div key={number} className="smallCard Card">
                                                                <div className="employee-info">
                                                                    <span className="numberInCard">{number}</span>
                                                                </div>
                                                                <a href="#remove-icon" className="remove-icon" onClick={() => handleRemoveDate(number)}>
                                                                    <FontAwesomeIcon icon={faTimes} color='red' />
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12 pTop24">
                                                <div className="fontFamily fontweightRegular fontsize16 blackTextColor" style={{ marginTop: '10px' }}>
                                                    Attach Document
                                                </div>
                                                <div className="card mt-2" style={{ height: '100px' }}>
                                                    <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <button
                                                            type="button"
                                                            style={{ cursor: isInsuranceView ? 'not-allowed' : 'pointer', margin: '15px 0px 10px 10px' }}
                                                            disabled={isInsuranceView}
                                                            className="coloredButtonSave"
                                                            onClick={() => document.getElementById('fileInput').click()}
                                                        >
                                                            Browse
                                                        </button>
                                                        <div className="fontFamily fontweightRegular grayTextColor fontsize14 mLeft8 mTop12">
                                                            Only (JPG, PNG) Max file size 2mb
                                                        </div>
                                                    </div>
                                                    <input
                                                        id="fileInput"
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        accept=".jpg,.jpeg,.png"
                                                        onChange={handleFileChange}
                                                    />
                                                    {documentLink && <div style={{ position: 'relative', margin: "10px" }}>
                                                        <input
                                                            {...register('documentLink')}
                                                            style={{
                                                                padding: '12px',
                                                                backgroundColor: isInsuranceView ? '#FAFAFB' : '#FFFFFF',
                                                                border: '#00000014 1px solid',
                                                                borderRadius: '5px',
                                                                fontFamily: '"Nunito", sans-serif',
                                                                fontWeight: '400',
                                                                fontSize: '16px',
                                                                width: '100%',
                                                                outline: 'none',
                                                                height: '32px',
                                                            }}
                                                            disabled={isInsuranceView}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={clearFileInput}
                                                            disabled={isInsuranceView}
                                                            style={{
                                                                position: 'absolute',
                                                                right: '8px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                background: 'none',
                                                                border: 'none',
                                                                cursor: isInsuranceView ? 'not-allowed' : 'pointer',
                                                            }}
                                                        >
                                                            <img src={XMarksvgIcon} alt="clear" />
                                                        </button>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                <div>
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor pTop24">
                                                        Status
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                        <a
                                                            href="#status"
                                                            className="switch-container"
                                                            onClick={!isInsuranceView ? () => setValue('Status', !statusWatch) : undefined}
                                                            style={{ cursor: isInsuranceView ? 'not-allowed' : 'pointer' }}
                                                            disabled={isInsuranceView}
                                                        >
                                                            <div className={`switch ${statusWatch ? 'active' : ''}`}>
                                                                <div className="handle"></div>
                                                            </div>
                                                        </a>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor statusPadding">
                                                            {statusWatch ? 'Active' : 'Inactive'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6 col-sm-12"></div>
                                        </div>


                                        <div className="mb-2 text-center" style={{ marginTop: "10%" }}>
                                            {!isInsuranceView && (
                                                <button type="submit" className="coloredButtonSave">Save</button>
                                            )}
                                            <button type="button" className="outlineButton" onClick={onCancel} style={{ marginLeft: '18px' }} >Cancel</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {showSampleExcelDownload && (
                        <SampleExcelDownload
                            showModal={showSampleExcelDownload}
                            closeModal={closeSampleExcelDownload}
                            handleDownloadSampleExcel={handleDownloadSampleExcel}
                            onClickFileAssetInputRef={onClickFileAssetInputRef}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ProcessFormConfigurationInsurance;

import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import GetUserAccess from '../../../../CommomPages/GetUserAccess';
import ConfirmDelete from '../../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { exportEmployeeData } from './ExportEmployeeManagementView';
import { getAsset } from '../../../../Services/AssetSettingsService';
import { getTeam } from '../../../../Services/DepartmentsAndTeamService';
import { getUserAccessPermission } from '../../../../Services/UserAccessPermissionService';
import { getRepair, getCheckOut } from '../../../../Services/ActionFormConfigurationService';
import { getEmployee, deleteEmployee } from '../../../../Services/EmployeeManagementService';

import './EmployeeManagementView.css';

import viewsvgIcon from '../../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../../assets/icon/Delete.svg';
import nodataFound from '../../../../assets/img/nodatafound.png';


const EmployeeManagementView = () => {
    const [userScreens, setUserScreens] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [employeeArray, setEmployeeArray] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const paginateArray = [5, 10, 15];


    useEffect(() => {
        fetchUserScreens();
        getEmployeeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const getEmployeeData = async () => {
        setIsLoading(true);
        const data = await getEmployee();
        const finalData = data.map((Emp, index) => {
            return {
                ...Emp,
                Rowid: index + 1,
                EmployeeName: Emp.firstName + " " + Emp.lastName,
                EmployeeStatus: Emp.status === true ? "Active" : "Inactive"
            };
        });
        setEmployeeArray(finalData);
        setPage(0);
        setIsLoading(false);
    }

    const handleAddButtonClick = () => {
        if (!userScreens["Employee Management"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        navigate('/EmployeeManagementAdd?id=0&view=0');
    }

    const exportEmployee = (event) => {
        if (!userScreens["Employee Management"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportEmployee(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportEmployee = (format) => {
        exportEmployeeData(employeeArray, format);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredEmployee = employeeArray.filter((employee) =>
        employee &&
        (
            employee?.EmployeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            employee?.employeeID?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            employee?.email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            employee?.EmployeeStatus?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()) ||
            employee?.phoneNumber?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
    );

    const deleteEmployeeId = async (empID) => {
        if (!userScreens["Employee Management"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const teamData = await getTeam();
        for (let team of teamData) {
            const empIds = team.empId.split(',');
            if (empIds.includes(empID.employeeID)) {
                toast.error(`This Employee  is associated with teams. You can't delete it.`);
                return;
            }
        }

        const userData = await getUserAccessPermission();
        for (let user of userData) {
            if (user.employeeId === empID.id) {
                toast.error(`This Employee is associated with User. You can't delete it.`);
                return;
            }
        }

        const checkOutData = await getCheckOut();
        for (let co of checkOutData) {
            if (co.assignTo === empID.id) {
                toast.error(`This Employee is associated with ActionFormConfiguration - CheckOut. You can't delete it.`);
                return;
            }
        }

        const repairData = await getRepair();
        for (let co of repairData) {
            if (co.assignTo === empID.id) {
                toast.error(`This Employee is associated with ActionFormConfiguration - Repair. You can't delete it.`);
                return;
            }
        }

        const assetData = await getAsset();
        for (let asset of assetData) {
            if (asset.employeeId === empID.id) {
                toast.error(`This Employee is associated with Asset. You can't delete it.`);
                return;
            }
        }

        const result = await ConfirmDelete();
        if (result) {
            setIsLoading(true);
            const response = await deleteEmployee(empID.id);
            if (response === "Deleted") {
                getEmployeeData();
                setIsLoading(false);
                toast.success("Employee Deleted Sucessfully");
            } else {
                setIsLoading(false);
                toast.error(response);
            }

        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleFirstPage = () => {
        handleChangePagePagination({ selected: 0 });
    };

    const handleChangePagePagination = ({ selected }) => {
        setPage(selected);
    };

    const handleLastPage = () => {
        handleChangePagePagination({ selected: Math.ceil(employeeArray.length / rowsPerPage) - 1 });
    };

    const totalEntries = filteredEmployee.length;
    const startEntry = page * rowsPerPage + 1;
    const endEntry = Math.min((page + 1) * rowsPerPage, totalEntries);

    const handleEmployeeImportClick = () => {
        if (!userScreens["Employee Management"]?.Import) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        // navigate('/EmployeeManagementImport');
    }

    const viewOrEditEmployee = (empId, viewStatus) => {
        if (viewStatus == 0) {
            if (!userScreens["Employee Management"]?.Edit) {
                toast.error('You do not have permission to this Action.');
                return;
            } else {
                navigate('/EmployeeManagementAdd?id=' + empId + '&view=0');
            }
        } else {
            if (!userScreens["Employee Management"]?.View) {
                toast.error('You do not have permission to this Action.');
                return;
            } else {
                navigate('/EmployeeManagementAdd?id=' + empId + '&view=1');
            }
        }
    };

    return (
        <div className="bacgroundStyle">
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleEmployee">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> User Management &gt;&gt; </span>
                                <span> Employee Management </span>
                            </span>
                            <div><InfoOutlinedIcon /></div>
                        </div>
                    </div>
                    {userScreens["Employee Management"]?.View && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="cardBodyEmployeeManagement">
                                    <div className="d-flex align-items-left">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Manage Employee</div>
                                    </div>

                                    <div >
                                        <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: '8px' }}>
                                            <div className="custom-container">
                                                <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                    <span className="show">Show</span>
                                                    <select className="custom-dropdown" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                                                        {paginateArray.map((n) => (
                                                            <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                                {n}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                        Entries
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                    className="searchTextBox"
                                                />
                                            </div>

                                            <div className="addbuttondiv">
                                                <button type="button" id="BtnaddUnit" className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd" onClick={handleAddButtonClick}>Add New </button>
                                                <button type="button" className="fontFamily fontweightRegular fontsize16 importBtn" onClick={handleEmployeeImportClick}> Import </button>
                                                <select className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportEmployee}>
                                                    <option value="" hidden={true}>Export</option>
                                                    <option value="csv">CSV</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="p-1">
                                            <div className="EmpBoxShadow">
                                                <div className="empTableScroll">
                                                    <table className="w-100 mt-2">
                                                        <thead>
                                                            <tr className="text-left empTableHeight">
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG  " style={{ width: "12%", padding: '2px 2px 2px 3%' }}>SI.No</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "16%" }}>Employee Name</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "16%" }}>Employee ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "16%" }}>Email ID</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "16%" }}>Phone Number</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "8%" }}>Status</th>
                                                                <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG p-2 " style={{ width: "10%" }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredEmployee.length > 0 ? (
                                                                filteredEmployee.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((Employee, index) => (
                                                                    <tr key={Employee.Rowid} style={{ height: "59px" }}>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{Employee.Rowid}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Employee.EmployeeName}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}><a href={`/EmployeeManagementAdd?id=${Employee.id}&view=0`}><span style={{ color: "#605BFF" }}>{Employee.employeeID}</span></a></td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>{Employee.email}</td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                            {Employee.phoneNumber}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ color: Employee.EmployeeStatus === 'Active' ? '#2ED47A' : '#EC2020' }}>
                                                                            {Employee.EmployeeStatus}
                                                                        </td>
                                                                        <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                            <span style={{ cursor: 'pointer' }}><a href='#EmployeeManagementView' onClick={() => viewOrEditEmployee(Employee.id, 1)}><img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href='#EmployeeManagementEdit' onClick={() => viewOrEditEmployee(Employee.id, 0)}><img src={editsvgIcon} alt="editsvgIcon" /></a></span>
                                                                            <span style={{ marginLeft: '10px', cursor: 'pointer' }}><a href="#deleteEmployee" onClick={() => deleteEmployeeId(Employee)}><img src={deletesvgIcon} alt="deletesvgIcon" /></a></span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16">
                                                                        <img src={nodataFound} alt="no data found" />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {totalEntries > 0 && (
                                                <div >
                                                    <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                                        <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                                                        </div>
                                                        <div >
                                                            <div className="pagination-container d-flex align-items-center">
                                                                <button className="paginationButton btn btn-link" onClick={handleFirstPage}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                </button>
                                                                <ReactPaginate
                                                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                    breakLabel={'...'}
                                                                    breakClassName={'break-me'}
                                                                    pageCount={Math.ceil(totalEntries / rowsPerPage)}
                                                                    forcePage={page}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={handleChangePagePagination}
                                                                    containerClassName={'pagination'}
                                                                    subContainerClassName={'pages pagination'}
                                                                    activeClassName={'active'}
                                                                    renderOnZeroPageCount={null}
                                                                />
                                                                <button className="paginationButton btn btn-link" onClick={handleLastPage}>
                                                                    <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "0px 100px" }}>
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default EmployeeManagementView;

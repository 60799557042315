import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import GetUserAccess from '../../../../CommomPages/GetUserAccess';
import ConfirmDelete from '../../../../CommomPages/ConfirmDelete';
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { exportRoleData } from './ExportRole';
import { getRoleData, deleteRoleData } from '../../../../Services/RoleManagementService';

import './RolesList.css';

import viewsvgIcon from '../../../../assets/icon/Eye.svg';
import editsvgIcon from '../../../../assets/icon/Edit.svg';
import deletesvgIcon from '../../../../assets/icon/Delete.svg';
import nodataFound from '../../../../assets/img/nodatafound.png';


const RolesList = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [userScreens, setUserScreens] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(true);
    const [roleArray, setRoleArray] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchUserScreens();
        getRoles();
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const getRoles = async () => {
        setIsLoading(true);
        const data = await getRoleData();
        const finalData = data.map((role, index) => {
            return {
                ...role,
                Rowid: index + 1,
            };
        });
        setRoleArray(finalData);
        setIsLoading(false);
    }

    const viewRole = (role) => {
        if (!userScreens["Role Management"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        console.log('role data in view role', role);
        const selectedRole = roleArray.find(r => r.rolE_ID === role.rolE_ID);
        if (selectedRole) {
            console.log('selectedRole in view role', selectedRole);
            navigate('/CreateRole', { state: { roleData: selectedRole, isViewMode: true } });
        } else {
            console.error('Role not found in roleArray');
        }
    };

    const editRole = (role) => {
        if (!userScreens["Role Management"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        console.log('Role data in edit role', role);
        const selectedRole = roleArray.find(r => r.rolE_ID === role.rolE_ID);
        if (selectedRole) {
            console.log('selectedRole in edit role', selectedRole);
            navigate('/CreateRole', { state: { roleData: selectedRole, isEditMode: true } });
        } else {
            console.error('Role not found in roleArray');
        }
    };


    const deleteRole = async (roleId) => {
        if (!userScreens["Role Management"]?.Delete) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const isConfirmed = await ConfirmDelete();
        if (isConfirmed) {
            setIsLoading(true);
            const deleteResponse = await deleteRoleData(roleId);
            if (deleteResponse === 'Deleted') {
                setIsLoading(false);
                toast.success('Role Deleted Successfully');
                getRoles();
            } else {
                setIsLoading(false);
                toast.error(deleteResponse);
            }
        }
    }


    const handleAddButtonClick = () => {
        if (!userScreens["Role Management"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        navigate('/CreateRole');
    }

    const exportRole = (event) => {
        if (!userScreens["Role Management"]?.Export) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const selectedOption = event.target.value;
        if (selectedOption !== '') {
            handleExportRole(selectedOption);
            event.target.value = '';
        }
    };

    const handleExportRole = (format) => {
        exportRoleData(roleArray, format);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredRoles = roleArray.filter((role) =>
        role &&
        (
            role.rolE_User_Role?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
            role.rolE_Status?.toLowerCase()?.startsWith(searchQuery?.toLowerCase())
        )
    );

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleFirstPage = () => {
        handleChangePagePagination({ selected: 0 });
    };

    const handleChangePagePagination = ({ selected }) => {
        setPage(selected);
    };

    const handleLastPage = () => {
        handleChangePagePagination({ selected: Math.ceil(roleArray.length / rowsPerPage) - 1 });
    };

    const totalEntries = filteredRoles.length;
    const startEntry = page * rowsPerPage + 1;
    const endEntry = Math.min((page + 1) * rowsPerPage, totalEntries);

    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleRole">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> User Management &gt;&gt; </span>
                                <span> Role Management</span>
                            </span>
                            <div><InfoOutlinedIcon /></div>
                        </div>
                    </div>

                    {userScreens["Role Management"]?.View && (
                        <div className="card mt-4">
                            <div className="card-body cardBodyCustom">
                                <div className="d-flex align-items-left">
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Role Management</div>
                                </div>

                                <div style={{ marginBottom: '20px' }}>
                                    <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: '16px' }}>
                                        <div className="custom-container">
                                            <span className="blackTextColor fontFamily fontsize16 fontweightRegular" >
                                                <span className="show">Show</span>
                                                <select className="custom-dropdown" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                                                    {[5, 10, 15].map((n) => (
                                                        <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n}>
                                                            {n}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular" >
                                                    Entries
                                                </span>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    className="searchTextBox"
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                    style={{ marginLeft: '28px' }}
                                                />
                                            </span>
                                        </div>

                                        <div className="addbuttondiv">
                                            <button type="button" id="BtnaddUnit" className="buttonAddNew fontFamily fontweightRegular fontsize16 coloredButtonAdd" style={{ marginLeft: "20px" }} onClick={handleAddButtonClick}>Add New</button>
                                            <select className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn" name="language" id="language" defaultValue="" onChange={exportRole}>
                                                <option value="" hidden={true}>Export</option>
                                                <option value="csv">CSV</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div style={{ padding: '1px' }}>
                                        <div className="RoleBoxShadow">
                                            <div className="tableScroll" style={{ height: '233px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                <table className="w-100 mt-2">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16  " style={{ backgroundColor: '#F6F6F6', width: "20%", padding: '2px 2px 2px 3%' }}>SI.No</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 p-2 " style={{ backgroundColor: '#F6F6F6', width: "30%" }}>Role</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 p-2 " style={{ backgroundColor: '#F6F6F6', width: "30%" }}>Status</th>
                                                            <th className="blackTextColor fontFamily fontweightMedium fontsize16 p-2 " style={{ backgroundColor: '#F6F6F6', width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredRoles.length > 0 ? (
                                                            filteredRoles.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((role, index) => (
                                                                <tr key={role.Rowid}>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 ${index % 2 === 0 ? '' : 'tableDataBG'}`} style={{ padding: "2px 2px 2px 4%" }}>{role.Rowid}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                        title={role.rolE_User_Role}>{role.rolE_User_Role}</td>
                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}
                                                                        style={{ color: role.rolE_Status === 'Active' ? '#2ED47A' : '#EC2020' }}>{role.rolE_Status}</td>

                                                                    <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 ${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                        <span style={{ marginRight: '10px', cursor: 'pointer' }}>
                                                                            <a href="#viewRole" onClick={() => viewRole(role)}>
                                                                                <img style={{ width: '18px', height: '15px' }} src={viewsvgIcon} alt="viewsvgIcon" />
                                                                            </a>
                                                                        </span>
                                                                        <span style={{ cursor: 'pointer' }}>
                                                                            <a href="#editRole" onClick={() => editRole(role)}>
                                                                                <img src={editsvgIcon} alt="editsvgIcon" />
                                                                            </a>
                                                                        </span>
                                                                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                                                            <a href="#deleteRole" onClick={() => deleteRole(role.rolE_ID)}>
                                                                                <img src={deletesvgIcon} alt="deletesvgIcon" />
                                                                            </a>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center fontFamily fontweightRegular fontsize16">
                                                                    <img src={nodataFound} alt="no data found" />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {totalEntries > 0 && (
                                            <div >
                                                <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px" }}>
                                                    <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                        Showing {startEntry} to {endEntry} of {totalEntries} entries
                                                    </div>
                                                    <div >
                                                        <div className="pagination-container d-flex align-items-center">
                                                            <button className="paginationButton btn btn-link" onClick={handleFirstPage}>
                                                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                            </button>
                                                            <ReactPaginate
                                                                previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={Math.ceil(totalEntries / rowsPerPage)}
                                                                forcePage={page}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handleChangePagePagination}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                                renderOnZeroPageCount={null}
                                                            />
                                                            <button className="paginationButton btn btn-link" onClick={handleLastPage}>
                                                                <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "0px 100px" }}>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default RolesList;

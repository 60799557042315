import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import ReactPaginate from "react-paginate";

import { useLocation, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { Chips } from "primereact/chips";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

import GetUserAccess from '../../../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { getTeam } from "../../../../Services/DepartmentsAndTeamService";
import { getEmployee } from "../../../../Services/EmployeeManagementService";
import { getAsset, CreateAssetHistory } from '../../../../Services/AssetSettingsService';
import { getRequestCategory, getRequestSubCategory, getDefaultPriority, getRequestTeam } from "../../../../Services/RequestService";
import { getProFormServiceRequest, updateProFormServiceRequest, createProFormServiceRequestHistory, getProFormServiceRequestHistory } from '../../../../Services/ProcessFormConfigurationService';

import viewsvgIcon from "../../../../assets/icon/Eye.svg";
import editsvgIcon from "../../../../assets/icon/Edit.svg";
import nodataFound from "../../../../assets/img/nodatafound.png";
import XMarksvgIcon from '../../../../assets/icon/X mark.svg';
import { Modal } from 'react-bootstrap';
import { NoLuggageOutlined } from '@mui/icons-material';

const ProcessFormConfigurationServiceRequest = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userScreens, setUserScreens] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [userName, setUserName] = useState('');

    const [IsServiceRequestView, setIsServiceRequestView] = useState(false);
    const [IsServiceRequestEdit, setIsServiceRequestEdit] = useState(false);

    const [rowsPerPageAsset, setRowsPerPageAsset] = useState(5);
    const paginateArray = [5, 10, 15];
    const [pageAsset, setPageAsset] = useState(0);
    const [IsRequestList, setIsRequestList] = useState(true);
    const [RequestListArray, setRequestListArray] = useState([]);
    const [FilterRequestListArray, setFilterRequestListArray] = useState([]);
    const [AssetRequestCategoryDropdown, setAssetRequestCategoryDropdown] = useState([]);
    const [AssetRequestSubCategoryDropdown, setAssetRequestSubCategoryDropdown] = useState([]);
    const [AssetRequestFilterSubCategoryDropdown, setAssetRequestFilterSubCategoryDropdown] = useState([]);
    const [AssetRequestPriorityDropdown, setAssetRequestPriorityDropdown] = useState([]);
    const [AssetData, setAssetData] = useState('');
    const [EmpData, setEmpData] = useState('');
    const [TeamData, setTeamData] = useState('');
    const [EmpAndAssetDetails, setEmpAndAssetDetails] = useState('');
    const [TeamChipValue, setTeamChipValue] = useState([]);
    const [UserChipValue, setUserChipValue] = useState([]);
    const [RequestStatus, setRequestStatus] = useState('');

    const [Document, setDocument] = useState('');
    const [DocumentName, setDocumentName] = useState('');
    const [RequestIsVisible, setRequestIsVisible] = useState(false);

    const [ServiceRequestStatus, setServiceRequestStatus] = useState('');
    const [ServiceRequestID, setServiceRequestID] = useState('');
    const [ServiceRequestNotes, setServiceRequestNotes] = useState('');

    const [ServiceRequestHistoryList, setServiceRequestHistoryList] = useState([]);
    const [FilterServiceRequestHistoryList, setFilterServiceRequestHistoryList] = useState([]);
    const [RequestTeamList, setRequestTeamList] = useState([]);

    const [IsFilterPopUp, setIsFilterPopUp] = useState(false);
    const [searchQueryAsset, setSearchQueryAsset] = useState("");

    const [RequestHoldCount, setRequestHoldCount] = useState(0);
    const [RequestReopenCount, setRequestReopenCount] = useState(0);
    const [RequestAllCount, setRequestAllCount] = useState(0);
    const [RequestCompletedCount, setRequestCompletedCount] = useState(0);
    const [RequestTeamUnassignedCount, setRequestTeamUnassignedCount] = useState(0);
    const [MyRequestPendingCount, setMyRequestPendingCount] = useState(0);
    const [NotMyRequestPendingCount, setNotMyRequestPendingCount] = useState(0);

    const [SelectedCardTab, setSelectedCardTab] = useState('myPendingRequest');

    const requestStatus = [
        { idValue: 'assigned', Value: 'Assigned' },
        { idValue: 'unassigned', Value: 'Unassigned' },
        { idValue: 'hold', Value: 'Hold' },
        { idValue: 'completed', Value: 'Completed' },
        { idValue: 'reopened', Value: 'Reopened' },
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        resetField,
        control,
        clearErrors,
    } = useForm({
        defaultValues: {
            requestCategory: '',
            requestSubCategory: '',
            requestPriority: '',
            subject: '',
            explainYourRequestDetails: '',
        }
    });

    const {
        register: registerFilter,
        handleSubmit: handleSubmitFilter,
        formState: { errorsFilter },
        reset: resetFilter,
        setValue: setValueFilter,
        resetField: resetFieldFilter,
        control: controlFilter,
        clearErrors: clearErrorsFilter,
    } = useForm({
        defaultValues: {
            requestor: 0,
            team: 0,
            category: 0,
            priority: 0,
            explainYourRequestDetails: '',
        }
    });

    const getQueryParams = (query) => {
        return new URLSearchParams(query);
    };

    let localAssetDate = new Date();
    let offset = localAssetDate.getTimezoneOffset(); // Offset in minutes
    // Adjust for local time
    localAssetDate.setMinutes(localAssetDate.getMinutes() - offset);
    // Convert to ISO format without the 'Z'
    let localISODate = localAssetDate.toISOString().slice(0, -1);


    useEffect(() => {
        fetchUserScreens();
        setIsFilterPopUp(false);
        const user = localStorage.getItem('UserName');
        setUserName(user);
        const queryParams = getQueryParams(location.search);
        const tabName = queryParams.get('tab');
        if (tabName == "AddNew") {
            // setIsRequestList(false);
        }
        getRequestData();
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const getRequestData = async () => {
        setIsLoading(true);
        const assetData = await getAsset();
        const requestCategoryData = await getRequestCategory();
        const requestSubCategoryData = await getRequestSubCategory();
        const DefaultPriority = await getDefaultPriority();
        const activeRequestCategory = requestCategoryData?.filter((e) => e.requestStatus == 'Active')
        const activeRequestSubCategory = requestSubCategoryData?.filter((e) => e.requestStatus == 'Active')
        const RequestData = await getProFormServiceRequest();
        const empData = await getEmployee();
        const teamData = await getTeam();
        const requestTeamData = await getRequestTeam();
        const serviceHistoryData = await getProFormServiceRequestHistory();
        setServiceRequestHistoryList(serviceHistoryData);
        const finalEmpData = empData?.map((e) => {
            return {
                ...e,
                employeeName: `${e?.firstName} ${e?.lastName}`
            }
        });

        const userID = parseInt(localStorage.getItem('UserEmpID'), 10);
        const userTeamID = teamData?.find((e) => {
            const empIds = e?.empId ? JSON.parse(e.empId) : null;
            return empIds?.includes(userID);
        })?.id || null;
        const teamRequestData = RequestData?.filter((e) => {
            let teamIds;
            try {
                teamIds = e?.teamIds ? JSON.parse(e.teamIds) : null;
            } catch (error) {
                console.error("Error parsing teamIds:", error);
                teamIds = null;
            }
            return teamIds?.includes(userTeamID) && e?.status === 'unassigned';
        });
        setRequestTeamUnassignedCount(teamRequestData.length)

        const myPendingRequestData = RequestData?.filter((e) => e?.empIds?.includes(userID) && e.status != 'completed')
        setMyRequestPendingCount(myPendingRequestData.length)
        localStorage.setItem('ServiceRequestCount', myPendingRequestData.length);

        const NotmyPendingRequestData = RequestData?.filter((e) => !e.empIds.includes(userID) && e.status != 'completed')
        setNotMyRequestPendingCount(NotmyPendingRequestData.length)

        const currentRequestData = RequestData.map((e) => {
            const empIds = e?.empIds ? JSON.parse(e.empIds) : null;
            const teamIds = e?.teamIds ? JSON.parse(e.teamIds) : null;
            if (empIds == '' || empIds == null || empIds == undefined) {
                // userTeamID = teamData?.find((e) => {
                //     const parsedEmpId = e?.empId ? JSON.parse(e.empId) : null;
                //     return parsedEmpId?.includes(userID);
                // })?.id || null;
                // const containsEmpValue = empIds.includes(userID);
            } else {
                const containsEmpValue = empIds.includes(userID);
                if (containsEmpValue) {
                    const associatedAsset = assetData?.find(asset => asset.id === e.assetID);
                    let requestCategoryValue = activeRequestCategory?.find((x) => x.id == e.requestCategory)
                    let requestSubCategoryValue = activeRequestSubCategory?.find((y) => y.id == e.requestSubCategory)
                    let requestPriorityValue = DefaultPriority?.find((z) => z.id == e.requestPriority)
                    let requestRequester = finalEmpData?.find((a) => a.id == e.createdById)
                    return {
                        ...e,
                        assetCodeID: associatedAsset.assetID,
                        assetName: associatedAsset.assetName,
                        requestCategoryValue: requestCategoryValue.requestCategoryName,
                        requestSubCategoryValue: requestSubCategoryValue.requestSubCategoryName,
                        requestPriorityValue: requestPriorityValue.name,
                        formattedCreatedDate: CreateRequestformatDate(e.createdDate),
                        requestStatusValue: requestStatus?.find((x) => x.idValue == e.status).Value,
                        formattedUpdatedDate: e.updatedDate == null ? null : CreateRequestformatDate(e.updatedDate),
                        requester: requestRequester?.employeeName
                    }
                } else {
                    return null;
                }
            }

        })?.filter(item => item !== null);
        //seting my pending request data
        const PendingRequestData = myPendingRequestData.map((e) => {
            const empIds = e?.empIds ? JSON.parse(e.empIds) : null;
            const teamIds = e?.teamIds ? JSON.parse(e.teamIds) : null;
            if (empIds == '' || empIds == null || empIds == undefined) {
                // userTeamID = teamData?.find((e) => {
                //     const parsedEmpId = e?.empId ? JSON.parse(e.empId) : null;
                //     return parsedEmpId?.includes(userID);
                // })?.id || null;                // const containsEmpValue = empIds.includes(userID);
            } else {
                const containsEmpValue = empIds.includes(userID);
                if (containsEmpValue) {
                    const associatedAsset = assetData?.find(asset => asset.id === e.assetID);
                    let requestCategoryValue = activeRequestCategory?.find((x) => x.id == e.requestCategory)
                    let requestSubCategoryValue = activeRequestSubCategory?.find((y) => y.id == e.requestSubCategory)
                    let requestPriorityValue = DefaultPriority?.find((z) => z.id == e.requestPriority)
                    let requestRequester = finalEmpData?.find((a) => a.id == e.createdById)
                    return {
                        ...e,
                        assetCodeID: associatedAsset.assetID,
                        assetName: associatedAsset.assetName,
                        requestCategoryValue: requestCategoryValue.requestCategoryName,
                        requestSubCategoryValue: requestSubCategoryValue.requestSubCategoryName,
                        requestPriorityValue: requestPriorityValue.name,
                        formattedCreatedDate: CreateRequestformatDate(e.createdDate),
                        requestStatusValue: requestStatus?.find((x) => x.idValue == e.status).Value,
                        formattedUpdatedDate: e.updatedDate == null ? null : CreateRequestformatDate(e.updatedDate),
                        requester: requestRequester?.employeeName
                    }
                } else {
                    return null;
                }
            }

        })?.filter(item => item !== null);
        const holdRequestCount = RequestData?.filter((e) => e.status == 'hold');
        const reopenedRequestCount = RequestData?.filter((e) => e.status == 'reopened');
        const completedRequestCount = RequestData?.filter((e) => e.status == 'completed');
        setRequestHoldCount(holdRequestCount.length)
        setRequestReopenCount(reopenedRequestCount.length)
        setRequestCompletedCount(completedRequestCount.length)
        setRequestAllCount(RequestData.length)
        setRequestTeamList(requestTeamData);
        setEmpData(finalEmpData);
        setTeamData(teamData);
        setAssetData(assetData);
        setAssetRequestCategoryDropdown(activeRequestCategory);
        setAssetRequestSubCategoryDropdown(activeRequestSubCategory);
        setAssetRequestPriorityDropdown(DefaultPriority)?.reverse();
        setRequestListArray(currentRequestData);
        //seting my pending request data
        setFilterRequestListArray(PendingRequestData);
        setIsLoading(false);
    };

    const CreateRequestformatDate = (originalDate) => {
        const dateObject = new Date(originalDate);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                alert('Maximum allowed file size is 2MB');
                event.target.value = null;
                return;
            }

            if (!['image/jpeg', 'image/png'].includes(file.type)) {
                alert('Only JPG, JPEG and PNG files are allowed');
                event.target.value = null;
                return;
            }

            const fileName = file.name;
            const fileSize = (file.size / 1024).toFixed(0); // Size in KB
            const fileDisplayText = `${fileName} (${fileSize}k)`;

            // Read file as base64 string
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get base64 part
                setDocument(base64String);
                setDocumentName(fileDisplayText);
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const clearFileInput = () => {
        setDocument('');
        setDocumentName('');
        document.getElementById('fileInput').value = null;
    };

    const onCancel = () => {
        reset();
        setTeamChipValue([]);
        setUserChipValue([]);
        setRequestStatus('');
        setDocument('');
        setDocumentName('');
        setRequestIsVisible(false);
        setServiceRequestStatus('');
        setServiceRequestID('');
        setIsServiceRequestView(false);
        setIsServiceRequestEdit(false)
        setIsRequestList(true);
        setServiceRequestNotes('');
        setEmpAndAssetDetails('');
    };

    const totalEntriesRequest = FilterRequestListArray.length;
    const startEntryRequest = pageAsset * rowsPerPageAsset + 1;
    const endEntryRequest = Math.min((pageAsset + 1) * rowsPerPageAsset, totalEntriesRequest);

    const handleRowsPerPageChangeAsset = (event) => {
        setRowsPerPageAsset(parseInt(event.target.value));
        setPageAsset(0);
    };

    const handleFirstPageAsset = () => {
        handleChangePagePaginationAsset({ selected: 0 });
    };

    const handleChangePagePaginationAsset = ({ selected }) => {
        setPageAsset(selected);
    };

    const handleLastPageAsset = () => {
        handleChangePagePaginationAsset({
            selected: Math.ceil(FilterRequestListArray?.length / rowsPerPageAsset) - 1,
        });
    };

    const handleChangeRequestEdit = (selectedRequest) => {
        if (!userScreens["Service Request"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsRequestList(false);
        setIsServiceRequestEdit(true);
        const filterServiceHistoryData = ServiceRequestHistoryList.map((e) => {
            const empData = EmpData?.find((x) => x.id == e.createdById)
            const formatDate = (Inputdate) => {
                const date = new Date(Inputdate)
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                const year = date.getFullYear();
                const hours = date.getHours();
                const minutes = String(date.getMinutes()).padStart(2, '0');

                const period = hours >= 12 ? 'pm' : 'am';
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

                // return `${day}/${month}/${year} - ${formattedHours}:${minutes}${period}`;
                return `${day}/${month}/${year}`;
            };

            return {
                empName: empData?.employeeName,
                empCodeId: empData.employeeID,
                createDateAndTime: formatDate(e.createdDate),
                historyMessage: e.message,
                documentName: e.documentName,
                serviceRequestID: e.serviceRequestID
            }
        })?.filter(item => item.serviceRequestID == selectedRequest.id)

        setFilterServiceRequestHistoryList(filterServiceHistoryData);
        const SelectedAssetData = AssetData?.find((e) => e.id == selectedRequest.assetID);
        const SelectedEmpData = EmpData?.find((e) => e.id == SelectedAssetData.empId);
        const _assetEmpData = {
            EmpCodeId: SelectedEmpData ? SelectedEmpData?.employeeID : '',
            EmpId: SelectedEmpData ? SelectedEmpData?.id : '',
            EmpName: SelectedEmpData ? `${SelectedEmpData?.firstName} ${SelectedEmpData?.lastName}` : '',
            AssetCodeId: SelectedAssetData.assetID,
            AssetId: SelectedAssetData.id,
            AssetName: SelectedAssetData.assetName
        };
        const filterSubCategory = AssetRequestSubCategoryDropdown?.filter((e) => e.requestCategoryId == selectedRequest.requestCategory);
        const teamChipIDs = selectedRequest?.teamIds ? JSON?.parse(selectedRequest.teamIds) : null;
        const teamChipArray = teamChipIDs.map((e) => {
            return TeamData?.find((x) => x.id == e)?.teamName
        });
        const empChipIDs = selectedRequest?.empIds ? JSON?.parse(selectedRequest.empIds) : null;
        const empChipArray = empChipIDs.map((e) => {
            return EmpData?.find((x) => x.id == e)?.employeeName
        });

        const statusValue = requestStatus?.find(x => x.idValue == selectedRequest.status).Value;
        setServiceRequestStatus(statusValue)
        setServiceRequestID(selectedRequest.requestID)
        setAssetRequestFilterSubCategoryDropdown(filterSubCategory);
        setValue('requestCategory', selectedRequest.requestCategory)
        setValue('requestSubCategory', selectedRequest.requestSubCategory)
        setValue('requestPriority', selectedRequest.requestPriority)
        setValue('subject', selectedRequest.subject)
        setValue('explainYourRequestDetails', selectedRequest.explainYourRequestDetails);
        setTeamChipValue(teamChipArray);
        setUserChipValue(empChipArray);
        setRequestStatus(selectedRequest.status);

        setRequestIsVisible(selectedRequest.isVisible);
        setDocument(selectedRequest.document);
        setDocumentName(selectedRequest.documentName);
        setEmpAndAssetDetails(_assetEmpData);
        setServiceRequestNotes('');
    };

    const handleChangeRequestView = (selectedRequest) => {
        if (!userScreens["Service Request"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setIsRequestList(false);
        setIsServiceRequestView(true);
        const SelectedAssetData = AssetData?.find((e) => e.id == selectedRequest.assetID);
        const SelectedEmpData = EmpData?.find((e) => e.id == SelectedAssetData.empId);
        const _assetEmpData = {
            EmpCodeId: SelectedEmpData ? SelectedEmpData?.employeeID : '',
            EmpId: SelectedEmpData ? SelectedEmpData?.id : '',
            EmpName: SelectedEmpData ? `${SelectedEmpData?.firstName} ${SelectedEmpData?.lastName}` : '',
            AssetCodeId: SelectedAssetData.assetID,
            AssetId: SelectedAssetData.id,
            AssetName: SelectedAssetData.assetName
        };
        const filterSubCategory = AssetRequestSubCategoryDropdown?.filter((e) => e.requestCategoryId == selectedRequest.requestCategory);
        const teamChipIDs = selectedRequest?.teamIds ? JSON?.parse(selectedRequest.teamIds) : null;
        const teamChipArray = teamChipIDs.map((e) => {
            return TeamData?.find((x) => x.id == e)?.teamName
        });
        const empChipIDs = selectedRequest?.empIds ? JSON?.parse(selectedRequest.empIds) : null;
        const empChipArray = empChipIDs.map((e) => {
            return EmpData?.find((x) => x.id == e)?.employeeName
        });

        const statusValue = requestStatus?.find(x => x.idValue == selectedRequest.status).Value;
        setServiceRequestStatus(statusValue)
        setServiceRequestID(selectedRequest.requestID)
        setAssetRequestFilterSubCategoryDropdown(filterSubCategory);
        setValue('requestCategory', selectedRequest.requestCategory)
        setValue('requestSubCategory', selectedRequest.requestSubCategory)
        setValue('requestPriority', selectedRequest.requestPriority)
        setValue('subject', selectedRequest.subject)
        setValue('explainYourRequestDetails', selectedRequest.explainYourRequestDetails);
        setTeamChipValue(teamChipArray);
        setUserChipValue(empChipArray);
        setRequestStatus(selectedRequest.status);

        setRequestIsVisible(selectedRequest.isVisible);
        setDocument(selectedRequest.document);
        setDocumentName(selectedRequest.documentName);
        setEmpAndAssetDetails(_assetEmpData);
        setServiceRequestNotes(selectedRequest.notes);
    };

    const OnchangeRequestStatus = (event) => {
        setRequestStatus(event.idValue)
    };

    const handleServiceRequestSubmit = async (data) => {
        if (!userScreens["Service Request"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        if (UserChipValue.length > 0 && TeamChipValue.length > 0) {
            setIsLoading(true);
            const selectedServiceRequest = RequestListArray?.find(e => e.requestID == ServiceRequestID);
            const empId = UserChipValue.map((e) => {
                const empValue = EmpData?.find((x) => x?.employeeName == e)
                return empValue ? empValue.id : null;
            });
            const teamId = TeamChipValue.map((e) => {
                const teamValue = TeamData?.find((x) => x.teamName == e)
                return teamValue ? teamValue.id : null;
            });

            const payload = {
                id: selectedServiceRequest.id,
                requestID: selectedServiceRequest.requestID,
                requestCategory: data.requestCategory,
                requestSubCategory: data.requestSubCategory,
                requestPriority: data.requestPriority,
                subject: data.subject,
                explainYourRequestDetails: data.explainYourRequestDetails,
                assetID: selectedServiceRequest.assetID,
                documentName: DocumentName?.trim(),
                document: JSON.stringify(Document),
                status: RequestStatus,
                isVisible: RequestIsVisible,
                empIds: JSON.stringify(empId),
                teamIds: JSON.stringify(teamId),
                notes: ServiceRequestNotes?.trim()
            }
            let historyMessage = [];
            let historyMessageString = '';
            let documentChanges = false;
            let StatusChanges = false;
            const requestStatusValue = requestStatus?.find(e => e.idValue == RequestStatus);
            if (selectedServiceRequest.requestCategory !== data.requestCategory) {
                historyMessage.push('Category');
            }

            if (selectedServiceRequest.requestSubCategory !== data.requestSubCategory) {
                historyMessage.push('Sub-Category');
            }

            if (selectedServiceRequest.requestPriority !== data.requestPriority) {
                historyMessage.push('Priority');
            }
            if (selectedServiceRequest.teamIds != JSON.stringify(teamId)) {
                historyMessage.push('Team');
            }
            if (selectedServiceRequest.empIds != JSON.stringify(empId)) {
                historyMessage.push('User');
            }
            if (selectedServiceRequest.requestStatusValue != requestStatusValue.Value) {
                // historyMessage.push('Status');
                StatusChanges = true
            }
            if (selectedServiceRequest.notes != ServiceRequestNotes) {
                historyMessage.push('Notes');
            }
            if (selectedServiceRequest.documentName != DocumentName) {
                if (DocumentName != "") {
                    historyMessage.push('Document');
                    documentChanges = true
                }
            }
            if (selectedServiceRequest.isVisible != RequestIsVisible) {
                historyMessage.push('Visible Request');

            }
            if (historyMessage.length > 0) {
                StatusChanges == true ? historyMessageString = historyMessage.join(', ') + ` has been modified and status has been changed to ${requestStatusValue.Value}` : historyMessageString = historyMessage.join(', ') + ' has been modified.';
            }
            if (StatusChanges && historyMessage.length == 0) {
                historyMessageString = `Status has been changed to ${requestStatusValue.Value}`;
            }
            const userID = parseInt(localStorage.getItem('UserEmpID'), 10);
            const historyPayload = {
                id: 0,
                serviceRequestID: selectedServiceRequest.id,
                message: historyMessageString,
                assetID: selectedServiceRequest.assetID,
                documentName: documentChanges ? DocumentName : '',
                createdById: userID,
            }
            try {
                const response = await updateProFormServiceRequest(payload);
                if (response === "Updated") {
                    if (historyMessage.length > 0) {
                        const Historyresponse = await createProFormServiceRequestHistory(historyPayload);
                    }
                    if (selectedServiceRequest.documentName != DocumentName) {
                        if (DocumentName == "") {
                            const historyPayload = {
                                id: 0,
                                serviceRequestID: selectedServiceRequest.id,
                                message: 'Document has been Deleted.',
                                assetID: selectedServiceRequest.assetID,
                                documentName: documentChanges ? DocumentName : '',
                                createdById: userID,
                            }
                            const Historyresponse = await createProFormServiceRequestHistory(historyPayload);
                        }

                    }
                    if (StatusChanges) {
                        if (ServiceRequestNotes != '') {
                            const historyPayload = {
                                id: 0,
                                serviceRequestID: selectedServiceRequest.id,
                                message: ServiceRequestNotes?.trim(),
                                assetID: selectedServiceRequest.assetID,
                                documentName: documentChanges ? DocumentName?.trim() : '',
                                createdById: userID,
                            }
                            const Historyresponse = await createProFormServiceRequestHistory(historyPayload);
                        }
                    }

                    const historyPayloadAsset = {
                        id: 0,
                        assetId: payload.assetID || 0,
                        assetDate: localISODate,
                        assetEvent: 'Service Request Updated',
                        assetField: "Service Request",
                        assetChangedFrom: '',
                        assetChangedTo: '',
                        assetActionBy: userName || '',
                    };
                    await CreateAssetHistory(historyPayloadAsset);
                    toast.success("ServiceRequest Updated Successfully");
                    setTimeout(() =>
                        onCancel(),
                        getRequestData(),
                        setIsRequestList(true)
                        , 1000);
                } else {
                    toast.error(response);
                }
                // setIsLoading(false);
            } catch (error) {
                toast.error(error.message)
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
            toast.error("Please add Team and User");

        }
    };

    const OnchangeCategory = (event) => {
        const filterSubCategory = AssetRequestSubCategoryDropdown?.filter((e) => e.requestCategoryId == event.id);
        setValue('requestCategory', event.id)
        resetField('requestSubCategory');
        setAssetRequestFilterSubCategoryDropdown(filterSubCategory);
        setTeamChipValue('');
        setUserChipValue('');
    };

    const OnchangeSubCategory = (event) => {
        if (event) {
            clearErrors('requestSubCategory');
        }
        const filterRequestTeam = RequestTeamList?.find(x => x.requestSubCategoryId == event.id);
        if (filterRequestTeam == undefined || filterRequestTeam == null || filterRequestTeam == "") {
            setTeamChipValue('');
            setUserChipValue('');
        } else {
            if (filterRequestTeam?.teamId != "") {
                const teamChipIDs = filterRequestTeam?.teamId ? JSON?.parse(filterRequestTeam?.teamId) : null;
                const teamChipArray = teamChipIDs?.map((e) => {
                    return TeamData?.find((x) => x.id == e)?.teamName
                })
                setTeamChipValue(teamChipArray);

            } else {
                setTeamChipValue('');
            }
            if (filterRequestTeam?.empId != "") {
                const empChipIDs = filterRequestTeam?.empId ? JSON?.parse(filterRequestTeam?.empId) : null;
                const empChipArray = empChipIDs?.map((e) => {
                    return EmpData?.find((x) => x.id == e)?.employeeName
                })
                setUserChipValue(empChipArray);
            } else {
                setUserChipValue('');
            }


        }
        setValue('requestSubCategory', event.id)
    };

    const OnchangePriority = (event) => {
        setValue('requestPriority', event.id)
    };

    const isVisibleOnChange = (event) => {
        setRequestIsVisible(event.target.checked)
    };

    const onChangeTeamChip = (e) => {
        if (e.value.length > 0) {
            if (TeamChipValue.length > e.value.length) {
                setTeamChipValue(e.value)
            } else {
                const newName = e.value[e.value.length - 1];
                TeamAddChip(newName);
            }
        } else {
            toast.warning("Team should be atleast one");
        }
    };

    const TeamAddChip = (newName) => {
        const inputValue = newName.toLowerCase().replace(/\s+/g, '');
        if (TeamChipValue == '') {
            const nameExists = TeamData?.find(team => team.teamName.toLowerCase().replace(/\s+/g, '') === inputValue);
            if (nameExists) {
                setTeamChipValue(prev => [...prev, nameExists.teamName]);
            } else {
                toast.error(`${newName} does not exist in the Team`);
            }
        } else {
            const existValue = TeamChipValue?.find(team => team?.toLowerCase().replace(/\s+/g, '') === inputValue);
            if (existValue == null || existValue == undefined) {
                const nameExists = TeamData?.find(team => team.teamName.toLowerCase().replace(/\s+/g, '') === inputValue);
                if (nameExists) {
                    setTeamChipValue(prev => [...prev, nameExists.teamName]);
                } else {
                    toast.error(`${newName} does not exist in the Team`);
                }
            } else {
                toast.warning('Team already added')
            }
        }
    };

    const onChangeUserChip = (e) => {
        if (e.value.length > 0) {
            if (UserChipValue.length > e.value.length) {
                setUserChipValue(e.value)
            } else {
                const newName = e.value[e.value.length - 1];
                UserAddChip(newName);
            }
        } else {
            toast.warning("User should be atleast one");
        }
    };

    const UserAddChip = (newName) => {
        const inputValue = newName.toLowerCase().replace(/\s+/g, '');
        if (UserChipValue == '') {
            const nameExists = EmpData?.find(team => team?.employeeName.toLowerCase().replace(/\s+/g, '') === inputValue);
            if (nameExists) {
                setUserChipValue(prev => [...prev, nameExists?.employeeName]);
            } else {
                toast.error(`${newName} does not exist in the User`);
            }
        } else {
            const existValue = UserChipValue?.find(team => team.toLowerCase().replace(/\s+/g, '') === inputValue);
            if (existValue == null || existValue == undefined) {
                const nameExists = EmpData?.find(team => team?.employeeName.toLowerCase().replace(/\s+/g, '') === inputValue);
                if (nameExists) {
                    setUserChipValue(prev => [...prev, nameExists?.employeeName]);
                } else {
                    toast.error(`${newName} does not exist in the User`);
                }
            } else {
                toast.warning('User already added')
            }
        }
    };

    const onChangeFilterBtn = () => {
        setIsFilterPopUp(true);
    };

    const handlePopUpClose = () => {
        resetFilter();
        setFilterRequestListArray(RequestListArray);
        setIsFilterPopUp(false);
    };

    const filterTheData = (filterInput) => {
        const arrayList = RequestListArray?.filter(request => {
            const teamId = request.teamIds ? JSON?.parse(request.teamIds) : null;
            return (
                (filterInput.requestor == 0 || filterInput.requestor == null || filterInput.requestor == request.createdById) &&
                (filterInput.category == 0 || filterInput.category == null || filterInput.category == request.requestCategory) &&
                (filterInput.priority == 0 || filterInput.priority == null || filterInput.priority == request.requestPriority) &&
                (filterInput.team == 0 || filterInput.team == null || teamId.includes(filterInput.team))
            )
        });
        setFilterRequestListArray(arrayList)
        setIsFilterPopUp(false);
    };

    const handleSearchChangeRequest = (event) => {
        const searchValue = event.target.value;
        setSearchQueryAsset(searchValue);
        const filteredRequest = searchValue != '' ? RequestListArray?.filter((Request) =>
            Request?.requestID?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            Request?.assetCodeID?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            Request?.requester?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            Request?.subject?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            Request?.requestCategoryValue?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            Request?.requestPriorityValue?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            Request?.requestStatusValue?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
            Request?.formattedUpdatedDate?.toLowerCase()?.includes(searchValue?.toLowerCase())
        ) : RequestListArray;
        setFilterRequestListArray(filteredRequest);
        setPageAsset(0);
    };

    const handleCardChange = (selectedCard) => {
        if (!userScreens["Service Request"]?.View) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const userID = parseInt(localStorage.getItem('UserEmpID'), 10);
        const userTeamID = TeamData?.find((e) => {
            const parsedEmpId = e?.empId ? JSON.parse(e.empId) : null;
            return parsedEmpId?.includes(userID);
        })?.id || null;
        if (selectedCard == 'myPendingRequest') {
            const myPendingRequestData = RequestListArray?.filter((e) => e.empIds.includes(userID) && e.status != 'completed');
            setFilterRequestListArray(myPendingRequestData);
            setSelectedCardTab('myPendingRequest')
        } else if (selectedCard == 'teamUnassignedRequest') {
            const teamRequestData = RequestListArray?.filter((e) => {
                const teamIds = e?.teamIds ? JSON.parse(e.teamIds) : null;
                return teamIds?.includes(userTeamID) && e?.status === 'unassigned';
            });
            setFilterRequestListArray(teamRequestData)
            setSelectedCardTab('teamUnassignedRequest')
        } else if (selectedCard == 'teamPendingRequest') {
            const NotmyPendingRequestData = RequestListArray?.filter((e) => !e.empIds.includes(userID) && e.status != 'completed')
            setFilterRequestListArray(NotmyPendingRequestData)
            setSelectedCardTab('teamPendingRequest')
        } else if (selectedCard == 'holdRequest') {
            const holdRequestCount = RequestListArray?.filter((e) => e.status == 'hold');
            setFilterRequestListArray(holdRequestCount);
            setSelectedCardTab('holdRequest')
        } else if (selectedCard == 'reopenRequest') {
            const reopenedRequestCount = RequestListArray?.filter((e) => e.status == 'reopened');
            setFilterRequestListArray(reopenedRequestCount)
            setSelectedCardTab('reopenRequest')
        } else if (selectedCard == 'allRequest') {
            setFilterRequestListArray(RequestListArray)
            setSelectedCardTab('allRequest')
        } else if (selectedCard == 'completedRequest') {
            const completedRequestCount = RequestListArray?.filter((e) => e.status == 'completed');
            setFilterRequestListArray(completedRequestCount)
            setSelectedCardTab('completedRequest')
        }
    }


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleCountry">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            {/* <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span> Asset - Process Form Configuration - Service Request </span>
                            </span> */}
                            <span>
                                <span>Inbox &gt;&gt;</span>
                                <span> Asset - Process Form Configuration - Service Request </span>
                            </span>
                            <div><InfoOutlinedIcon /></div></div>
                    </div>

                    {IsRequestList ? (
                        <div className="customScrollbar">

                            {userScreens["Service Request"]?.View && (
                                <div className="card mt-4" style={{ height: '750px' }}>
                                    <div className="card-body">
                                        <div>
                                            <div className='d-flex justify-content-between'>
                                                <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%', background: SelectedCardTab == 'myPendingRequest' ? '#2ED47A' : 'none', cursor: 'pointer' }} onClick={() => handleCardChange('myPendingRequest')}>
                                                    <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ height: '75px', color: SelectedCardTab == 'myPendingRequest' ? 'white' : '#808093' }}>
                                                        My Pending <br />Requests
                                                    </div>
                                                    <div className="fontFamily fontsize20 fontweightMedium textAlignCenter" style={{ color: SelectedCardTab == 'myPendingRequest' ? 'white' : '#000000' }}>
                                                        {MyRequestPendingCount}
                                                    </div>
                                                </div>
                                                <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%', marginLeft: '15px', background: SelectedCardTab == 'teamUnassignedRequest' ? '#2ED47A' : 'none', cursor: 'pointer' }} onClick={() => handleCardChange('teamUnassignedRequest')}>
                                                    <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ height: '75px', color: SelectedCardTab == 'teamUnassignedRequest' ? 'white' : '#808093' }}>
                                                        My Team <br />unassigned<br />request
                                                    </div>
                                                    <div className="fontFamily fontsize20 fontweightMedium textAlignCenter" style={{ color: SelectedCardTab == 'teamUnassignedRequest' ? 'white' : '#000000' }}>
                                                        {RequestTeamUnassignedCount}
                                                    </div>
                                                </div>
                                                <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%', marginLeft: '15px', background: SelectedCardTab == 'teamPendingRequest' ? '#2ED47A' : 'none', cursor: 'pointer' }} onClick={() => handleCardChange('teamPendingRequest')}>
                                                    <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ height: '75px', color: SelectedCardTab == 'teamPendingRequest' ? 'white' : '#808093' }}>
                                                        My Teams <br />Pending Request
                                                    </div>
                                                    <div className="fontFamily fontsize20 fontweightMedium textAlignCenter" style={{ color: SelectedCardTab == 'teamPendingRequest' ? 'white' : '#000000' }}>
                                                        {NotMyRequestPendingCount}
                                                    </div>
                                                </div>
                                                <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%', marginLeft: '15px', background: SelectedCardTab == 'holdRequest' ? '#2ED47A' : 'none', cursor: 'pointer' }} onClick={() => handleCardChange('holdRequest')}>
                                                    <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ height: '75px', color: SelectedCardTab == 'holdRequest' ? 'white' : '#808093' }}>
                                                        Hold
                                                    </div>
                                                    <div className="fontFamily fontsize20 fontweightMedium textAlignCenter" style={{ color: SelectedCardTab == 'holdRequest' ? 'white' : '#000000' }}>
                                                        {RequestHoldCount}
                                                    </div>
                                                </div>
                                                <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%', marginLeft: '15px', background: SelectedCardTab == 'reopenRequest' ? '#2ED47A' : 'none', cursor: 'pointer' }} onClick={() => handleCardChange('reopenRequest')}>
                                                    <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ height: '75px', color: SelectedCardTab == 'reopenRequest' ? 'white' : '#808093' }}>
                                                        Reopened
                                                    </div>
                                                    <div className="fontFamily fontsize20 fontweightMedium textAlignCenter" style={{ color: SelectedCardTab == 'reopenRequest' ? 'white' : '#000000' }}>
                                                        {RequestReopenCount}
                                                    </div>
                                                </div>
                                                <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%', marginLeft: '15px', background: SelectedCardTab == 'allRequest' ? '#2ED47A' : 'none', cursor: 'pointer' }} onClick={() => handleCardChange('allRequest')}>
                                                    <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ height: '75px', color: SelectedCardTab == 'allRequest' ? 'white' : '#808093' }}>
                                                        All
                                                    </div>
                                                    <div className="fontFamily fontsize20 fontweightMedium textAlignCenter" style={{ color: SelectedCardTab == 'allRequest' ? 'white' : '#000000' }}>
                                                        {RequestAllCount}
                                                    </div>
                                                </div>
                                                <div className="CustomBoxShadow" style={{ width: '15%', padding: '2% 1%', marginLeft: '15px', background: SelectedCardTab == 'completedRequest' ? '#2ED47A' : 'none', cursor: 'pointer' }} onClick={() => handleCardChange('completedRequest')}>
                                                    <div className="fontFamily fontsize16 fontweightMedium textAlignCenter" style={{ height: '75px', color: SelectedCardTab == 'completedRequest' ? 'white' : '#808093' }}>
                                                        Completed
                                                    </div>
                                                    <div className="fontFamily fontsize20 fontweightMedium textAlignCenter" style={{ color: SelectedCardTab == 'completedRequest' ? 'white' : '#000000' }}>
                                                        {RequestCompletedCount}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-3" style={{ paddingBottom: "8px" }}>
                                                <div className="custom-container">
                                                    <span className="blackTextColor fontFamily fontsize16 fontweightRegular">
                                                        <span className="show">Show</span>
                                                        <select className="custom-dropdown" value={rowsPerPageAsset} onChange={handleRowsPerPageChangeAsset} >
                                                            {paginateArray.map((n) => (
                                                                <option className="blackTextColor fontFamily fontsize14 fontweightRegular" key={n} value={n} >
                                                                    {n}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className="blackTextColor entries fontFamily fontsize16 fontweightRegular">
                                                            Entries
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={searchQueryAsset}
                                                        onChange={handleSearchChangeRequest}
                                                        className="searchTextBox"
                                                    />
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="coloredButtonSave"
                                                        onClick={onChangeFilterBtn}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        Filter
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="p-1">
                                                <div className="EmpBoxShadow">
                                                    <div className="" style={{ padding: "10px" }}>
                                                        <table className="w-100 mt-2">
                                                            <thead>
                                                                <tr className="text-left empTableHeight">
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG textAlignCenter" style={{ width: "10%", padding: "2px 0px" }} > Request ID </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG textAlignCenter" style={{ width: "10%" }}> Asset ID </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG textAlignCenter" style={{ width: "15%" }}> Requester </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG textAlignCenter" style={{ width: "15%" }}> Subject </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG ~p-2 textAlignCenter" style={{ width: "15%" }} > Category </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG ~p-2 textAlignCenter" style={{ width: "10%" }} > Priority </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG ~p-2 textAlignCenter" style={{ width: "10%" }} > Status </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG ~p-2 textAlignCenter" style={{ width: "10%" }} > Last Updated </th>
                                                                    <th className="blackTextColor fontFamily fontweightMedium fontsize16 tableHeaderBG ~p-2 textAlignCenter" style={{ width: "15%" }} > Action </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {FilterRequestListArray.length > 0 ? (
                                                                    FilterRequestListArray.slice(pageAsset * rowsPerPageAsset, (pageAsset + 1) * rowsPerPageAsset).map((Request, index) => (
                                                                        <tr key={Request.id} style={{ height: "59px" }}>
                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} ><span style={{ color: "#605BFF", cursor: "pointer" }} onClick={() => handleChangeRequestEdit(Request)} >{Request.requestID}</span></td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} ><a href={`/AssetDetailsView?id=${Request.assetID}`}><span style={{ color: "#605BFF", cursor: "pointer" }}>{Request.assetCodeID}</span></a></td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Request.requester}</td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Request.subject}</td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Request.requestCategoryValue}</td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Request.requestPriorityValue}</td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Request.requestStatusValue}</td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} >{Request.formattedUpdatedDate}</td>

                                                                            <td className={`grayTextColor fontFamily fontweightRegular fontsize14 p-2 textAlignCenter ${index % 2 === 0 ? "" : "tableDataBG"}`} >
                                                                                <span style={{ cursor: "pointer" }}>
                                                                                    <img style={{ width: "18px", height: "15px", }} src={viewsvgIcon} alt="viewsvgIcon"
                                                                                        onClick={() => handleChangeRequestView(Request)}
                                                                                    />
                                                                                </span>

                                                                                <span style={{ marginLeft: "10px", cursor: "pointer", }}>
                                                                                    <img src={editsvgIcon} alt="editsvgIcon"
                                                                                        onClick={() => handleChangeRequestEdit(Request)}
                                                                                    />
                                                                                </span>



                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <td colSpan="9" className="text-center fontFamily fontweightRegular fontsize16"><img src={nodataFound} alt="no data found" /></td>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {totalEntriesRequest > 0 && (
                                                    <div>
                                                        <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "16px", }}>
                                                            <div className="text-muted fontFamily fontsize12 fontweightRegular">
                                                                Showing {startEntryRequest} to {endEntryRequest} of {totalEntriesRequest} entries
                                                            </div>
                                                            <div>
                                                                <div className="pagination-container d-flex align-items-center">
                                                                    <button className="paginationButton btn btn-link" onClick={handleFirstPageAsset} >
                                                                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon-color" />
                                                                    </button>
                                                                    <ReactPaginate
                                                                        previousLabel={<FontAwesomeIcon icon={faChevronLeft} className="icon-color" />}
                                                                        nextLabel={<FontAwesomeIcon icon={faChevronRight} className="icon-color" />}
                                                                        breakLabel={"..."}
                                                                        breakClassName={"break-me"}
                                                                        pageCount={Math.ceil(totalEntriesRequest / rowsPerPageAsset)}
                                                                        forcePage={pageAsset}
                                                                        marginPagesDisplayed={2}
                                                                        pageRangeDisplayed={5}
                                                                        onPageChange={handleChangePagePaginationAsset}
                                                                        containerClassName={"pagination"}
                                                                        subContainerClassName={"pages pagination"}
                                                                        activeClassName={"active"}
                                                                        renderOnZeroPageCount={null}
                                                                    />
                                                                    <button className="paginationButton btn btn-link" onClick={handleLastPageAsset}>
                                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon-color" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div style={{ padding: "0px 100px" }}></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div >
                    ) : (
                        <div className="card mt-4">

                            {userScreens["Service Request"]?.View && (
                                <div className="card-body">
                                    <div className='d-flex align-items-center justify-content-between pBottom20'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Service Request ID: {ServiceRequestID}</div>
                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium pLeft45">Status: {ServiceRequestStatus}</div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            {!IsServiceRequestView && (
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="buttonExport fontFamily fontweightRegular fontsize16 exportBtn mRight12"
                                                        onClick={(e) => setIsServiceRequestEdit(false)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="coloredButtonSave"
                                                        onClick={handleSubmit(handleServiceRequestSubmit)}
                                                        disabled={IsServiceRequestEdit}
                                                        style={{ cursor: IsServiceRequestEdit ? 'not-allowed' : 'pointer' }}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            )}

                                            <button type="button"
                                                className="outlineButton"
                                                onClick={onCancel}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>

                                    <div className="customScrollbar" style={{ padding: '1%' }}>
                                        <div className="CustomBoxShadow" style={{ width: '100%', padding: '2% 1%' }}>
                                            <div className='row' style={{ paddingBottom: '7%' }}>
                                                <div className="col-8 col-md-8 col-lg-8 col-sm-12">
                                                    <div className="CustomBoxShadow" style={{ width: '100%', padding: '2% 1%' }}>
                                                        <div className="fontFamily fontsize16 fontweightSemiBold blackTextColor pLeft12">
                                                            Details Tab
                                                        </div>
                                                        <form onSubmit={handleSubmit(handleServiceRequestSubmit)}>
                                                            <div className='row'>
                                                                <div className='col-6 col-lg-6 col-md-6 col-sm-12'>
                                                                    <div className="fontFamily fontsize16 fontweightRegular grayTextColor pLeft12 pTop12">
                                                                        Request Category
                                                                    </div>
                                                                    <div className='pLeft12'>
                                                                        <Controller
                                                                            name="requestCategory"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <Select
                                                                                    {...field}
                                                                                    value={AssetRequestCategoryDropdown?.find(repo => repo.id === field.value) || null}
                                                                                    onChange={OnchangeCategory}

                                                                                    options={AssetRequestCategoryDropdown}
                                                                                    getOptionLabel={(option) => option.requestCategoryName}
                                                                                    getOptionValue={(option) => option.id}
                                                                                    placeholder=""
                                                                                    // isClearable
                                                                                    isSearchable
                                                                                    isDisabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                                    styles={{
                                                                                        menu: base => ({ ...base, zIndex: 9999 }),
                                                                                        container: base => ({ ...base, width: '100%' }), // Add this line
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6 col-lg-6 col-md-6 col-sm-12'>
                                                                    <div className="fontFamily fontsize16 fontweightRegular grayTextColor pTop12">
                                                                        Request Sub Category<span className='mandatoryCss'>*</span>
                                                                    </div>
                                                                    <div>
                                                                        <Controller
                                                                            name="requestSubCategory"
                                                                            control={control}
                                                                            rules={{
                                                                                required: 'Request Sub Category is required',
                                                                                validate: {
                                                                                    validOption: value => {
                                                                                        return value && value.id !== '' || 'Request Sub Category is required';
                                                                                    }
                                                                                }
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <Select
                                                                                    {...field}
                                                                                    value={AssetRequestFilterSubCategoryDropdown?.find(repo => repo.id === field.value) || null}
                                                                                    onChange={OnchangeSubCategory}
                                                                                    options={AssetRequestFilterSubCategoryDropdown}
                                                                                    getOptionLabel={(option) => option.requestSubCategoryName}
                                                                                    getOptionValue={(option) => option.id}
                                                                                    placeholder=""
                                                                                    isSearchable
                                                                                    isDisabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                                    styles={{
                                                                                        menu: base => ({ ...base, zIndex: 9999 }),
                                                                                        container: base => ({ ...base, width: '100%' }),
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div >
                                                                        {errors.requestSubCategory && (
                                                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                {errors.requestSubCategory.message}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-6 col-lg-6 col-md-6 col-sm-12'>
                                                                    <div className="fontFamily fontsize16 fontweightRegular grayTextColor pLeft12 pTop12">
                                                                        Subject
                                                                    </div>
                                                                    <div className='pLeft12'>
                                                                        <input
                                                                            className="textinputCustomAsset"
                                                                            placeholder=""
                                                                            {...register('subject')}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6 col-lg-6 col-md-6 col-sm-12'>
                                                                    <div className="fontFamily fontsize16 fontweightRegular grayTextColor pTop12">
                                                                        Request Priority
                                                                    </div>
                                                                    <div>
                                                                        <Controller
                                                                            name="requestPriority"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <Select
                                                                                    {...field}
                                                                                    value={AssetRequestPriorityDropdown?.find(repo => repo.id === field.value) || null}
                                                                                    onChange={OnchangePriority}
                                                                                    options={AssetRequestPriorityDropdown}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    getOptionValue={(option) => option.id}
                                                                                    placeholder=""
                                                                                    // isClearable
                                                                                    isSearchable
                                                                                    isDisabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                                    styles={{
                                                                                        menu: base => ({ ...base, zIndex: 9999 }),
                                                                                        container: base => ({ ...base, width: '100%' }), // Add this line
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12 col-lg-12 col-md-12 col-sm-12'>
                                                                    <div className="fontFamily fontsize16 fontweightRegular grayTextColor pLeft12 pTop12">
                                                                        Request Info
                                                                    </div>
                                                                    <div className='pLeft12'>
                                                                        <textarea
                                                                            placeholder=""
                                                                            className="textareaCustom textAeraHeight"
                                                                            {...register('explainYourRequestDetails')}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="CustomBoxShadow" style={{ width: '100%', padding: '2% 1%', marginTop: '2%' }}>
                                                        <div className="fontFamily fontsize16 fontweightSemiBold blackTextColor pLeft12">
                                                            History
                                                        </div>
                                                        <div className='ServiceHistorycustomScrollbar' >
                                                            {FilterServiceRequestHistoryList.map((history, inded) => (
                                                                <div style={{ background: 'rgba(96, 91, 255, 0.08)', borderRadius: '5px', marginTop: '1%', marginLeft: '1%' }}>
                                                                    <div style={{ padding: '1%' }}>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div className="fontFamily fontsize16 fontweightSemiBold blackTextColor">
                                                                                {history.empName} ({history.empCodeId})
                                                                            </div>
                                                                            <div className="fontFamily fontsize16 fontweightSemiBold blackTextColor">
                                                                                {history.createDateAndTime}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fontFamily fontsize16 fontweightSemiBold grayTextColor' style={{ padding: '0% 1%' }}>
                                                                        {history.historyMessage}
                                                                    </div>
                                                                    {history.documentName != '' && (
                                                                        <div className='fontFamily fontsize12 fontweightRegular' style={{ color: '#109CF1', padding: '0% 2%' }}>
                                                                            {history.documentName}
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-4 col-sm-4">
                                                    <div className="CustomBoxShadow" style={{ width: '100%', padding: '2% 1%' }}>
                                                        {EmpAndAssetDetails.EmpCodeId != '' && EmpAndAssetDetails.EmpName != '' && (
                                                            <div>
                                                                <div className="fontFamily fontsize16 fontweightSemiBold pLeft12">
                                                                    Name (Employee ID)
                                                                </div>
                                                                <div className="fontFamily fontsize16 fontweightSemiBold pLeft12">
                                                                    {EmpAndAssetDetails.EmpName} (<a href={`/EmployeeManagementAdd?id=${EmpAndAssetDetails.EmpId}&view=0#UserInfo`}><span style={{ color: '#605BFF' }}>{EmpAndAssetDetails.EmpCodeId}</span></a>)
                                                                </div>
                                                            </div>

                                                        )}

                                                        <div className="fontFamily fontsize16 fontweightSemiBold pLeft12">
                                                            Asset ID: <a href={`/AssetDetailsView?id=${EmpAndAssetDetails.AssetId}`}><span style={{ color: '#605BFF' }}>{EmpAndAssetDetails.AssetCodeId}</span></a>
                                                        </div>
                                                        <div className="fontFamily fontsize16 fontweightSemiBold pLeft12">
                                                            Asset Name: {EmpAndAssetDetails.AssetName}
                                                        </div>
                                                    </div>
                                                    <div className="CustomBoxShadow" style={{ width: '100%', padding: '2% 1%', marginTop: '7%' }}>
                                                        <div className="fontFamily fontsize16 fontweightRegular grayTextColor pLeft12">
                                                            Team
                                                        </div>
                                                        <div className="pLeft12">
                                                            <Chips className='chip-class' style={{ width: '100%', height: '100%', outline: 'none !important' }} value={TeamChipValue} onChange={onChangeTeamChip} disabled={IsServiceRequestView || IsServiceRequestEdit} />
                                                        </div>
                                                        <div className="fontFamily fontsize16 fontweightRegular grayTextColor pLeft12">
                                                            User
                                                        </div>
                                                        <div className="pLeft12">
                                                            <Chips className='chip-class' style={{ width: '100%', height: '100%', outline: 'none !important' }} value={UserChipValue} onChange={onChangeUserChip} disabled={IsServiceRequestView || IsServiceRequestEdit} />
                                                        </div>
                                                    </div>
                                                    <div className="CustomBoxShadow" style={{ width: '100%', padding: '2% 1%', marginTop: '7%' }}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className="fontFamily fontsize16 fontweightRegular blackTextColor pLeft12">
                                                                Action
                                                            </div>
                                                            <div className="pLeft8" style={{ width: '100%' }}>
                                                                <Controller
                                                                    name="RequestStatus"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            className='fontFamily fontsize12 fontweightRegular'
                                                                            {...field}
                                                                            value={requestStatus?.find(repo => repo.idValue === RequestStatus) || null}
                                                                            onChange={OnchangeRequestStatus}
                                                                            options={requestStatus}
                                                                            getOptionLabel={(option) => option.Value}
                                                                            getOptionValue={(option) => option.idValue}
                                                                            placeholder=""
                                                                            // isClearable
                                                                            isSearchable
                                                                            isDisabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                            styles={{
                                                                                menu: base => ({ ...base, zIndex: 9999 }),
                                                                                container: base => ({ ...base, width: '100%' }), // Add this line
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontsize12 fontweightRegular grayTextColor pLeft12 pTop12">
                                                            Add Notes
                                                        </div>

                                                        <div className="pLeft12">
                                                            <textarea
                                                                placeholder=""
                                                                className="textareaCustom textAeraHeight"
                                                                value={ServiceRequestNotes}
                                                                onChange={(e) => setServiceRequestNotes(e.target.value)}
                                                                disabled={IsServiceRequestView || IsServiceRequestEdit}
                                                            />
                                                        </div>

                                                        <div className='d-flex align-items-center'>
                                                            <div className="pLeft12">
                                                                <input
                                                                    type="checkbox"
                                                                    // {...field}
                                                                    checked={RequestIsVisible}
                                                                    onChange={(e) => isVisibleOnChange(e)}
                                                                    disabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                />
                                                            </div>
                                                            <div className="fontFamily fontsize12 fontweightRegular blackTextColor pLeft12">
                                                                Visible to Requestor
                                                            </div>
                                                        </div>

                                                        <div className="pLeft12 pTop12">
                                                            <button
                                                                type="button"
                                                                style={{ cursor: IsServiceRequestView || IsServiceRequestEdit ? 'not-allowed' : 'pointer' }}

                                                                disabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                className="coloredButtonSave" onClick={() => document.getElementById('fileInput').click()}>
                                                                Upload File
                                                            </button>
                                                            <input
                                                                id="fileInput"
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                accept=".jpg,.jpeg,.png,.pdf"
                                                                onChange={handleFileChange}
                                                            />
                                                            <div style={{ position: 'relative', marginTop: "16px" }}>
                                                                <input
                                                                    value={DocumentName}
                                                                    style={{
                                                                        paddingLeft: '12px', backgroundColor: IsServiceRequestView || IsServiceRequestEdit ? '#FAFAFB' : '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '32px'
                                                                    }}

                                                                    disabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={clearFileInput}
                                                                    disabled={IsServiceRequestView || IsServiceRequestEdit}
                                                                    style={{
                                                                        position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: IsServiceRequestView || IsServiceRequestEdit ? 'not-allowed' : 'pointer'
                                                                    }}
                                                                >
                                                                    <img src={XMarksvgIcon} alt="clear" />
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div >
            )}
            {/*  */}
            <Modal show={IsFilterPopUp} onHide={handlePopUpClose} centered size="md" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="fontFamily fontweightMedium fontsize16 blackTextColor">
                            Filters
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='row'>
                            <div className='col-12 col-lg-12 col-md-12 col-sm-12'>
                                <div>
                                    Requestor
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                    <Controller
                                        name="requestor"
                                        control={controlFilter}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                value={EmpData?.find(repo => repo.id === field.value) || null}
                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                options={EmpData}
                                                getOptionLabel={(option) => option?.employeeName}
                                                getOptionValue={(option) => option.id}
                                                placeholder=""
                                                isClearable
                                                isSearchable
                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-12 col-lg-12 col-md-12 col-sm-12' style={{ marginTop: "15px" }}>
                                <div>
                                    Team
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                    <Controller
                                        name="team"
                                        control={controlFilter}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                value={TeamData?.find(repo => repo.id === field.value) || null}
                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                options={TeamData}
                                                getOptionLabel={(option) => option.teamName}
                                                getOptionValue={(option) => option.id}
                                                placeholder=""
                                                isClearable
                                                isSearchable
                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-12 col-lg-12 col-md-12 col-sm-12' style={{ marginTop: "15px" }}>
                                <div>
                                    Request Category
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                    <Controller
                                        name="category"
                                        control={controlFilter}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                value={AssetRequestCategoryDropdown?.find(repo => repo.id === field.value) || null}
                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                options={AssetRequestCategoryDropdown}
                                                getOptionLabel={(option) => option.requestCategoryName}
                                                getOptionValue={(option) => option.id}
                                                placeholder=""
                                                isClearable
                                                isSearchable
                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-12 col-lg-12 col-md-12 col-sm-12' style={{ marginTop: "15px" }}>
                                <div>
                                    Priority
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                    <Controller
                                        name="priority"
                                        control={controlFilter}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                value={AssetRequestPriorityDropdown?.find(repo => repo.id === field.value) || null}
                                                onChange={(selectedOption) => { field.onChange(selectedOption ? selectedOption.id : null); }}
                                                options={AssetRequestPriorityDropdown}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                placeholder=""
                                                isClearable
                                                isSearchable
                                                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='d-flex align-items-center justify-content-center' style={{ marginTop: "10px", marginTop: "10px" }}>
                            <div>
                                <button
                                    type="button"
                                    className="coloredButtonSave"
                                    onClick={handleSubmitFilter(filterTheData)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Search
                                </button>
                            </div>

                            <div>
                                <button type="button"
                                    className="outlineButton"
                                    onClick={handlePopUpClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );
};

export default ProcessFormConfigurationServiceRequest;

import axios from 'axios';
import api from '../webconfig';
import { updateDocument } from './GeneralSettingsService';

const API_BASE_URL = api;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { Authorization: `Bearer ${token}` };
};

//ProFormContract
export async function createProFormContract(contract) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormContract/CreateProFormContract', contract, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Contract:', error);
        throw error;
    }
}

export async function getProFormContract() {
    try {
        const response = await axios.get(API_BASE_URL + 'ProFormContract/getProFormContract', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Contract:', error);
        throw error;
    }
}

//ProFormInsurance
export async function createProFormInsurance(insurance) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormInsurance/CreateProFormInsurance', insurance, { headers: getAuthHeader() });
        if (insurance.NextID) {
            const payload = {
                id: 7,
                documentType: 'InsuranceNo',
                documentNo: insurance.NextID
            }
            await updateDocument(payload);
        }

        return response.data;
    } catch (error) {
        console.error('Error create Insurance:', error);
        throw error;
    }
}

export async function updateProFormInsurance(insurance) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormInsurance/UpdateProFormInsurance', insurance, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error Update Insurance:', error);
        throw error;
    }
}

export async function getProFormInsurance() {
    try {
        const response = await axios.get(API_BASE_URL + 'ProFormInsurance/GetProFormInsurance', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Insurance:', error);
        throw error;
    }
}

export async function deleteProFormInsurance(insuranceId) {
    try {
        const response = await axios.post(`${API_BASE_URL}ProFormInsurance/DeleteProFormInsurance?insuranceId=${insuranceId}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Insurance:', error);
        throw error;
    }
}

export async function GetProFormInsuranceByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}ProFormInsurance/GetProFormInsuranceByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Insurance:', error);
        throw error;
    }
}
//Create ProFormMaintenance
export async function createProFormMaintenance(maintenance) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormMaintenance/CreateProFormMaintenance', maintenance, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create Maintenance:', error);
        throw error;
    }
}

//Update ProFormMaintenance
export async function updateProFormMaintenance(maintenance) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormMaintenance/UpdateProFormMaintenance', maintenance, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error Update Maintenance:', error);
        throw error;
    }
}

//Get ProFormMaintenance
export async function getProFormMaintenance() {
    try {
        const response = await axios.get(API_BASE_URL + 'ProFormMaintenance/GetProFormMaintenance', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Maintenance:', error);
        throw error;
    }
}

export async function GetProFormMaintenanceByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}ProFormMaintenance/GetProFormMaintenanceByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Insurance:', error);
        throw error;
    }
}
//Create ProFormServiceRequest
export async function createProFormServiceRequest(serviceRequest) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormServiceRequest/CreateProFormServiceRequest', serviceRequest, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create ServiceRequest:', error);
        throw error;
    }
}
//UpdateProFormServiceRequest
export async function updateProFormServiceRequest(serviceRequest) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormServiceRequest/UpdateProFormServiceRequest', serviceRequest, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create ServiceRequest:', error);
        throw error;
    }
}

//Get ProFormServiceRequest
export async function getProFormServiceRequest() {
    try {
        const response = await axios.get(API_BASE_URL + 'ProFormServiceRequest/GetProFormServiceRequest', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Serive Request:', error);
        throw error;
    }
}

export async function GetProFormServiceRequestByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}ProFormServiceRequest/GetProFormServiceRequestByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Insurance:', error);
        throw error;
    }
}

//Create serviceReqest History
export async function createProFormServiceRequestHistory(serviceRequest) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormServiceRequestHistory/CreateServiceRequestHistory', serviceRequest, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error create ServiceRequestHistroy:', error);
        throw error;
    }
}

//Get ProFormServiceRequest History
export async function getProFormServiceRequestHistory() {
    try {
        const response = await axios.get(API_BASE_URL + 'ProFormServiceRequestHistory/GetServiceRequestHistory', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Serive Request:', error);
        throw error;
    }
}

export async function GetAssetCreationHistoryByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}ProFormInsurance/GetAssetCreationHistoryByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Insurance:', error);
        throw error;
    }
}

//ProFormWarranty
export async function createProFormWarranty(warranty) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormWarranty/CreateProFormWarranty', warranty, { headers: getAuthHeader() });
        if (warranty.NextID) {
            const payload = {
                id: 6,
                documentType: 'WarrantyNo',
                documentNo: warranty.NextID
            }
            await updateDocument(payload);
        }

        return response.data;
    } catch (error) {
        console.error('Error create Warranty:', error);
        throw error;
    }
}

export async function updateProFormWarranty(warranty) {
    try {
        const response = await axios.post(API_BASE_URL + 'ProFormWarranty/UpdateProFormWarranty', warranty, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error Update Warranty:', error);
        throw error;
    }
}

export async function getProFormWarranty() {
    try {
        const response = await axios.get(API_BASE_URL + 'ProFormWarranty/GetProFormWarranty', { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error fetching Warranty:', error);
        throw error;
    }
}

export async function GetProFormWarrantyByAssetID(assetID) {
    try {
        const response = await axios.post(`${API_BASE_URL}ProFormWarranty/GetProFormWarrantyByAssetID?assetID=${assetID}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Warranty:', error);
        throw error;
    }
}

export async function deleteProFormWarranty(warrantyId) {
    try {
        const response = await axios.post(`${API_BASE_URL}ProFormWarranty/DeleteProFormWarranty?warrantyId=${warrantyId}`, null, { headers: getAuthHeader() });
        return response.data;
    } catch (error) {
        console.error('Error delete Warranty:', error);
        throw error;
    }
}
import React, { useState, useEffect, useCallback } from 'react';
import ReactPaginate from "react-paginate";
import { ThreeCircles } from 'react-loader-spinner';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CustomCheckBox from './CustomCheckBox';
import GetUserAccess from '../../../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../../../CommomPages/LoadingSpinner';
import { getBRData } from '../../../../Services/BusinessEntityNewService';
import { getDepartment } from '../../../../Services/DepartmentsAndTeamService';
import { getCategory } from '../../../../Services/AssetCategoriesAndBrandService';
import { insertRoleData, updateRoleData, getRoleData } from '../../../../Services/RoleManagementService';

import './CreateRole.css';


const CreateRole = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userScreens, setUserScreens] = useState({});
    const [selectedRoleData, setSelectedRoleData] = useState({});
    const [isViewMode, setIsViewMode] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [tabActive, setTabActive] = useState('Screens');
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [txtRoleName, setTxtRoleName] = useState('');
    const [txtDescription, setTxtDescription] = useState('');
    const [roleArray, setRoleArray] = useState([]);
    const [roleData, setRoleData] = useState({
        AdminRights: {},
        Asset: {},
        Dashboard: {},
        Events: {},
        Reports: {},
        Screens: {},
    });
    const allTabs = ['Screens', 'Asset', 'Reports', 'Dashboard'];
    // const allTabs = ['Screens', 'Asset', 'Events', 'Reports', 'Dashboard', 'Admin Rights'];
    const [individualOptions] = useState(['View', 'Add', 'Edit', 'Delete', 'Import', 'Export']);
    const reportsPartOne = ['Standard Reports', 'Asset by Department', 'Asset by Location', 'Asset Depreciation', 'Asset Maintenance'];
    const reportsPartTwo = ['Asset Warranties', 'Asset Contract', 'Asset Check out', 'Asset Users'];
    const dashboardPartOne = ['Standard Reports', 'Custom Dashboard', 'Alert', 'Reminders', 'Notification'];
    const dashboardPartTwo = ['Escalation', 'Task'];
    const [branchArray, setBranchArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [RoleIsSubmit] = useState(false);
    const [RoleIsSave] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [expanded, setExpanded] = useState(null);

    const [allScreens, setAllScreens] = useState([
        { id: 1, name: 'Business Entity' },
        { id: 2, name: 'Business Unit' },
        { id: 3, name: 'Business Branch' },
        { id: 4, name: 'Country' },
        { id: 5, name: 'State' },
        { id: 6, name: 'City' },
        { id: 7, name: 'Department' },
        { id: 8, name: 'Sub Department' },
        { id: 9, name: 'Team' },
        { id: 10, name: 'General Settings' },
        { id: 11, name: 'Role Management' },
        { id: 12, name: 'User Access Permission' },
        { id: 13, name: 'Employee Management' },
        { id: 14, name: 'Supplier Management' },
        { id: 15, name: 'Asset Configuration' },
        { id: 16, name: 'Category' },
        { id: 17, name: 'Sub Category' },
        { id: 18, name: 'Brand' },
        { id: 19, name: 'Action Form Configuration' },
        { id: 20, name: 'Asset Management' },
        { id: 21, name: 'Leave Management' },
        { id: 22, name: 'Warranty Management' },
        { id: 23, name: 'Task Management' },
        { id: 24, name: 'Service Desk Management' },
        { id: 25, name: 'Maintenance Management' },
        { id: 26, name: 'Knowledge Base Management' },
        { id: 27, name: 'Request Configuration' },
        { id: 28, name: 'Request Category' },
        { id: 29, name: 'Request Sub Category' },
        { id: 30, name: 'Request Team Mapping' },
        { id: 31, name: 'Maintenance Configuration' },
        { id: 32, name: 'Maintenance Type' },
        { id: 33, name: 'Maintenance Sub Type' },
        { id: 34, name: 'Manage Maintenance' },
        { id: 35, name: 'Maintenance Stage and Status' },
        { id: 36, name: 'Contract Configuration' },
        { id: 37, name: 'Contract Type' },
        { id: 38, name: 'Manage Contract' },
        { id: 39, name: 'Contract Status' },
        { id: 40, name: 'Contract' },
        { id: 41, name: 'Maintenance' },
        { id: 42, name: 'Warranties' },
        { id: 43, name: 'Service Request' },
        { id: 44, name: 'Insurance' },
        { id: 45, name: 'Contract Management' },
        { id: 46, name: 'Custom Form' },
        { id: 47, name: 'Forms Management' },
        { id: 48, name: 'Service Desk Management' },
        { id: 49, name: 'Alert' },
        { id: 50, name: 'Notification' },
        { id: 51, name: 'SLA' },
        { id: 52, name: 'Workflow' },
        { id: 53, name: 'Approval' },
        { id: 54, name: 'Email Template' },
        { id: 55, name: 'Alert Templates' },
        { id: 56, name: 'Incoming' },
        { id: 57, name: 'Outgoing' },
        { id: 58, name: 'Spam Filter' },
        { id: 59, name: 'Delimier' },
        { id: 60, name: 'QR Properties Settings' },
        { id: 61, name: 'QR Configuration' },
        { id: 62, name: 'QR Mapping' },
        { id: 63, name: 'QR Tracking' },
        { id: 64, name: 'QR Printing' },
        { id: 65, name: 'QR Labelling' },
        { id: 66, name: 'Financial Transaction' },
        { id: 67, name: 'Audit' },
        { id: 68, name: 'Report' },
    ]);

    const groupedScreens = [
        { groupName: 'Account Details', screens: allScreens.slice(0, 9) },
        { groupName: 'General Settings', screens: allScreens.slice(9, 10) },
        { groupName: 'User Management', screens: allScreens.slice(10, 14) },
        { groupName: 'Asset', screens: allScreens.slice(14, 19) },
        { groupName: 'Module Configuration', screens: allScreens.slice(19, 26) },
        { groupName: 'Request', screens: allScreens.slice(26, 30) },
        { groupName: 'Process Form Config', screens: allScreens.slice(39, 44) },
    ];


    useEffect(() => {
        if (location.state?.roleData) {
            const selectedRoleData = location.state.roleData;
            console.log('selectedRoleData in create role screen', selectedRoleData);
            setSelectedRoleData(selectedRoleData);

            const { rolE_DESC, rolE_Status, rolE_User_Role, rolE_Asset, rolE_DashBoard, rolE_Reports, rolE_Screen } = selectedRoleData;
            setTxtRoleName(rolE_User_Role || '');
            setTxtDescription(rolE_DESC || '');
            setIsChecked(rolE_Status === 'Active');
            setRoleData({
                AdminRights: {},
                Asset: JSON.parse(rolE_Asset || '{}'),
                Dashboard: JSON.parse(rolE_DashBoard || '{}'),
                Events: {},
                Reports: JSON.parse(rolE_Reports || '{}'),
                Screens: JSON.parse(rolE_Screen || '{}'),
            });
            setIsViewMode(location.state.isViewMode || false);
            setIsEditMode(location.state.isEditMode || false);
        }
    }, [location.state]);

    useEffect(() => {
        const allSelected = allScreens.every(screen =>
            individualOptions.every(option =>
                roleData.Screens?.[screen.name]?.[option]
            )
        );
        setSelectAll(allSelected);
    }, [roleData, allScreens, individualOptions]);

    useEffect(() => {
        fetchUserScreens();
        getRoles();
        getBusinessBranch();
        getDepartmentDetails();
        getCategoryDetails();
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const getRoles = async () => {
        setIsLoading(true);
        const RoleData = await getRoleData();
        setRoleArray(RoleData);
        setIsLoading(false);
    }

    const getBusinessBranch = async () => {
        setIsLoading(true);
        const BranchData = await getBRData();
        setBranchArray(BranchData);
        setIsLoading(false);
    };

    const getDepartmentDetails = async () => {
        setIsLoading(true);
        const DepartmentData = await getDepartment();
        setDepartmentArray(DepartmentData);
        setIsLoading(false);
    };

    const getCategoryDetails = async () => {
        setIsLoading(true);
        const CategoryData = await getCategory();
        setCategoryArray(CategoryData);
        setIsLoading(false);
    };

    const handleCheckboxChange = useCallback((tab, item, option, newSelectAllState) => {
        if (isViewMode) {
            return;
        }

        if (tab === 'Screens' && item === 'selectAll') {
            setSelectAll(newSelectAllState);
            setRoleData(prevState => {
                const updatedScreens = allScreens.reduce((acc, screen) => {
                    const screenOptions = individualOptions.reduce((opts, opt) => {
                        opts[opt] = newSelectAllState;
                        return opts;
                    }, {});
                    acc[screen.name] = screenOptions;
                    return acc;
                }, {});
                return {
                    ...prevState,
                    Screens: updatedScreens,
                };
            });
        } else if (tab === 'Screens' && option === '') {
            // Handle screen-level checkbox
            setRoleData(prevState => {
                const currentOptions = prevState.Screens[item] || {};
                const allChecked = Object.values(currentOptions).every(value => value);
                const updatedOptions = individualOptions.reduce((opts, opt) => {
                    opts[opt] = !allChecked;
                    return opts;
                }, {});

                return {
                    ...prevState,
                    Screens: {
                        ...prevState.Screens,
                        [item]: updatedOptions,
                    },
                };
            });
        } else {
            // Handle individual option checkbox
            setRoleData(prevState => {
                const updatedScreenOptions = { ...(prevState?.[tab]?.[item] || {}) };
                updatedScreenOptions[option] = !updatedScreenOptions[option];
                const screenChecked = Object.values(updatedScreenOptions).some(value => value === true);

                return {
                    ...prevState,
                    [tab]: {
                        ...(prevState[tab] || {}),
                        [item]: updatedScreenOptions,
                    },
                    Screens: {
                        ...prevState.Screens,
                        [item]: screenChecked ? updatedScreenOptions : {},
                    },
                };
            });
        }
    }, [isViewMode, setRoleData, allScreens, individualOptions]);


    const isFieldInvalid = (field, minLength = 1) => {
        return field === '' || (field.length < minLength && field !== '');
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        filterScreens(event.target.value);
    };

    const filterScreens = (query) => {
        const filtered = allScreens.filter((screen) =>
            screen.name.toLowerCase().includes(query.toLowerCase())
        );
        setAllScreens(filtered);
    };

    const cancelRole = () => {
        navigate('/RolesList');
        setSelectedRoleData({});
    }

    const submitRole = async () => {
        if (!userScreens["Role Management"]?.Add || !userScreens["Role Management"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        if (isViewMode) return;

        if (!txtRoleName && !txtDescription) {
            toast.error('Please enter Role Name and Description.');
            return;
        }

        let screenSelected = false;
        for (const screen of allScreens) {
            if (roleData['Screens'][screen.name]) {
                screenSelected = true;
                break;
            }
        }

        if (!screenSelected) {
            toast.error('Please select at least one screen name with access.');
            return;
        }
        const UserRoleExists = roleArray.some(x => x.rolE_User_Role === txtRoleName?.trim());
        if (isEditMode) {
            const currentRole = roleArray.find(x => x.rolE_User_Role === txtRoleName?.trim());
            if (UserRoleExists && currentRole.rolE_User_Role !== txtRoleName?.trim()) {
                toast.error("Role Name already exists.");
                return;
            }
        } else {
            if (UserRoleExists) {
                toast.error("Role Name already exists.");
                return;
            }
        }

        const payload = {
            rolE_ID: (selectedRoleData?.rolE_ID) || roleData.rolE_ID || 0,
            rolE_BE: (selectedRoleData?.rolE_BE) || roleData.rolE_BE || 0,
            rolE_BU: (selectedRoleData?.rolE_BU) || roleData.rolE_BU || 0,
            rolE_DESC: txtDescription?.trim() || '',
            rolE_Status: isChecked ? 'Active' : 'Inactive',
            rolE_User_Role: txtRoleName?.trim(),
            rolE_Admin_Rights: '',
            rolE_Asset: JSON.stringify(roleData['Asset']) || '',
            rolE_DashBoard: JSON.stringify(roleData['Dashboard']) || '',
            rolE_Events: '',
            rolE_Reports: JSON.stringify(roleData['Reports']) || '',
            rolE_Screen: JSON.stringify(roleData['Screens']) || '',
        };

        try {
            setIsLoading(true);
            const response = isEditMode ? await updateRoleData(payload) : await insertRoleData(payload);
            if (response === (isEditMode ? "Updated" : "Inserted")) {
                toast.success(`Role ${isEditMode ? "Updated" : "Inserted"} Successfully`);
                setUserAccess();
                setTimeout(() => navigate("/RolesList"), 500);
                setIsLoading(false);
                setSelectedRoleData({});
            } else {
                console.log('Response from insert/update role data', response);
                toast.error('Failed to save role.');
            }
        } catch (error) {
            console.error('Error while inserting/updating role data', error);
            toast.error('Error saving role.');
        } finally {
            setIsLoading(false);
        }
    };

    const setUserAccess = async () => {
        try {
            // Retrieve the user's role from localStorage
            const userRole = localStorage.getItem('UserRole');
            const roleData = await getRoleData();
            const selectedScreens = roleData?.find(option => option?.rolE_User_Role == userRole)?.rolE_Screen;

            // Clean up selected screens by removing any empty objects
            for (let key in selectedScreens) {
                if (Object.keys(selectedScreens[key]).length === 0) {
                    delete selectedScreens[key];
                }
            }

            // Store cleaned-up selectedScreens as a string in localStorage
            localStorage.setItem('UserScreens', selectedScreens);


            // Asset Actions
            // Parse the selectedActions based on the user's role
            const selectedActions = roleData?.find(option => option?.rolE_User_Role == userRole)?.rolE_Asset;

            // Clean up selected screens by removing any empty objects
            for (let key in selectedActions) {
                if (Object.keys(selectedActions[key]).length === 0) {
                    delete selectedActions[key];
                }
            }

            // Store cleaned-up selectedActions as a string in localStorage
            localStorage.setItem('UserActionsOnAsset', selectedActions);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };


    return (
        <div style={{ height: "100vh", width: "100%", backgroundColor: '#FAFAFB' }}>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}

            {!isLoading && (
                <div>
                    <div className="pagetitleRole">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> User Management &gt;&gt; </span>
                                <span onClick={() => { navigate('/RolesList') }} style={{ cursor: 'pointer' }}> Role Management &gt;&gt; </span>
                                <span> {isEditMode == false ? 'Add' : 'Update'}</span>
                            </span>
                            <div>
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    </div>

                    {userScreens["Role Management"]?.View && (
                        <div className="card mt-4 customScrollbar">
                            <div className="card-body cardBodyCustom">
                                <div className="d-flex align-items-left">
                                    <div className="blackTextColor fontFamily fontsize20 fontweightMedium">
                                        Role Management
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '20px', marginBottom: '20px', paddingLeft: '20px' }}>
                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                            Role Name<span className="mandatoryCss"> *</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={txtRoleName}
                                            placeholder="Role Name"
                                            onChange={(e) => setTxtRoleName(e.target.value)}
                                            disabled={isViewMode}
                                            style={{
                                                paddingLeft: '12px',
                                                backgroundColor: '#FFFFFF',
                                                border: '#00000014 1px solid',
                                                borderRadius: '3px',
                                                fontFamily: '"Nunito", sans-serif',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                width: '85%',
                                                outline: 'none',
                                                height: '45px'
                                            }}
                                        />
                                        {RoleIsSubmit && isFieldInvalid(txtRoleName) && (
                                            <div className="fontFamily fontweightRegular fontsize16 validationError" style={{ paddingLeft: '20px' }}>
                                                Please Enter Role Name
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                            Description
                                        </div>
                                        <input
                                            type="text"
                                            value={txtDescription}
                                            placeholder="Description"
                                            onChange={(e) => setTxtDescription(e.target.value)}
                                            disabled={isViewMode}
                                            style={{
                                                paddingLeft: '12px',
                                                backgroundColor: '#FFFFFF',
                                                border: '#00000014 1px solid',
                                                borderRadius: '3px',
                                                fontFamily: '"Nunito", sans-serif',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                width: '85%',
                                                outline: 'none',
                                                height: '45px'
                                            }}
                                        />
                                        {RoleIsSubmit && isFieldInvalid(txtDescription) && (
                                            <div className="fontFamily fontweightRegular fontsize16 validationError" style={{ paddingLeft: '20px' }}>
                                                Please Enter Description
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-4 col-md-4 col-lg-4 col-sm-12">
                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor">
                                            Status
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <a href='#Status'
                                                className="switch-container"
                                                onClick={!isViewMode ? () => setIsChecked(prev => !prev) : undefined}
                                                style={{ cursor: isViewMode ? 'not-allowed' : 'pointer' }}
                                            >
                                                <div className={`switch ${isChecked ? 'active' : ''}`}>
                                                    <div className="handle"></div>
                                                </div>
                                            </a>
                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ paddingLeft: '10px' }}>
                                                {isChecked ? 'Active' : 'Inactive'}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="RoleBoxShadow">
                                    <div className="HeaderTab">
                                        {allTabs.map(tab => (
                                            <a
                                                key={tab}
                                                href={`#${tab.replace(' ', '')}`}
                                                className={`${tabActive === tab ? 'activeTab' : 'InactiveTab'}`}
                                                onClick={() => setTabActive(tab)}
                                            >
                                                <div className="fontFamily fontweightRegular fontsize20">
                                                    {tab}
                                                </div>
                                            </a>
                                        ))}
                                    </div>

                                    {tabActive === 'Screens' && (
                                        <div>
                                            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                                                <div className="flexStart" style={{ paddingBottom: "8px" }}>
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        className="searchTextBox margin0"
                                                    />
                                                </div>
                                                <div className="p-1">
                                                    <div className="EmpBoxShadow">
                                                        <div className="" style={{ padding: "10px" }}>
                                                            <table className="w-100 mt-2">
                                                                <thead>
                                                                    <tr className="text-left">
                                                                        <th className="blackTextColor fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "15%", padding: '2px 2px 2px 4%' }}>
                                                                            Screen Name
                                                                        </th>
                                                                        {['View All', 'Add All', 'Edit All', 'Delete All', 'Import All', 'Export All'].map(option => (
                                                                            <th key={option} className="blackTextColor fontFamily fontweightMedium fontsize16 p-2" style={{ backgroundColor: '#F6F6F6', width: "8%" }}>
                                                                                {option}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {groupedScreens.map((group, groupIndex) => (
                                                                        <React.Fragment key={group.groupName}>
                                                                            <tr>
                                                                                <td colSpan="8" style={{ paddingTop: '2%' }}>
                                                                                    <Accordion
                                                                                        expanded={expanded === `panel${groupIndex}`}
                                                                                        onChange={handleAccordionChange(`panel${groupIndex}`)}
                                                                                    >
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            aria-controls={`panel${groupIndex}-content`}
                                                                                            id={`panel${groupIndex}-header`}
                                                                                        >
                                                                                            <div className="fontFamily fontweightRegular fontsize18 grayTextColor" style={{ width: "15%" }}>
                                                                                                {group.groupName}
                                                                                            </div>
                                                                                            {/* {['View All', 'Add All', 'Edit All', 'Delete All', 'Import All', 'Export All'].map(option => (
                                                                                                <div key={option} className="" style={{ width: option === 'Export All' ? "8%" : "15%", paddingLeft: option === 'View All' ? "7%" : option === 'Add All' ? "4%" : option === 'Edit All' ? "1%" : '', paddingRight: option === 'Delete All' ? "1%" : option === 'Import All' ? "4%" : '', textAlign: 'center' }}>
                                                                                                    <CustomCheckBox
                                                                                                        tab="Screens"
                                                                                                        item={group.groupName}
                                                                                                        option={option}
                                                                                                        roleData={roleData}
                                                                                                        isViewMode={isViewMode}
                                                                                                        handleCheckboxChange={handleCheckboxChange}
                                                                                                        selectAll={selectAll} // Use selectAll prop for group-level selection
                                                                                                    />
                                                                                                </div>
                                                                                            ))} */}
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <table className="w-100">
                                                                                                {group.screens.map((screen, index) => (
                                                                                                    <tr key={screen.id} className={`${index % 2 === 0 ? '' : 'tableDataBG'}`}>
                                                                                                        <td className="grayTextColor fontFamily fontsize16" style={{ padding: "2px 2px 2px 3%", width: "5%" }}>
                                                                                                            <CustomCheckBox
                                                                                                                tab="Screens"
                                                                                                                item={screen.name}
                                                                                                                option="" // Screen level checkbox
                                                                                                                roleData={roleData}
                                                                                                                isViewMode={isViewMode}
                                                                                                                handleCheckboxChange={handleCheckboxChange}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="fontFamily fontsize16 grayTextColor p-2" style={{ width: "15%" }}>
                                                                                                            {screen.name}
                                                                                                        </td>
                                                                                                        {individualOptions.map((option, index) => (
                                                                                                            <td key={option} className="option-item fontFamily fontsize16 pLeft5Percentage">
                                                                                                                <CustomCheckBox
                                                                                                                    tab="Screens"
                                                                                                                    item={screen.name}
                                                                                                                    option={option} // Individual option checkbox
                                                                                                                    roleData={roleData}
                                                                                                                    isViewMode={isViewMode}
                                                                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                                                                />
                                                                                                                {/* <span className="optionLabel grayTextColor p-2" style={{ marginLeft: '10px' }}>{option}</span> */}
                                                                                                            </td>
                                                                                                        ))}
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </table>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {tabActive === 'Asset' && (
                                        <div>
                                            <div className="accessMapping">
                                                <div className="mt-3">
                                                    <div className="fontFamily fontweightRegular fontsize16 blackTextColor" style={{ marginRight: '30px' }}>
                                                        Asset Access Mapping:
                                                    </div>
                                                </div>
                                                <div className="mt-3" style={{ marginRight: '30px' }}>
                                                    <div className="d-flex align-items-center">
                                                        <CustomCheckBox
                                                            tab="Asset"
                                                            item="Access to own Assets"
                                                            option="Access to own Assets"
                                                            roleData={roleData}
                                                            isViewMode={isViewMode}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                            className={"customCheckBox customCheckBoxAsset"}
                                                        />
                                                        <div className="fontFamily fontweightRegular fontsize16 blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Access to own Assets
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <div className="d-flex align-items-center">
                                                        <CustomCheckBox
                                                            tab="Asset"
                                                            item="Others"
                                                            option="Others"
                                                            roleData={roleData}
                                                            isViewMode={isViewMode}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                            className={"customCheckBox customCheckBoxAsset"}
                                                        />
                                                        <div className="fontFamily fontweightRegular fontsize16 blackTextColor" style={{ marginLeft: '10px' }}>
                                                            Others
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="customCardsAsset" style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="card mt-4" style={{ width: '309px', height: '279px', marginRight: '120px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                    <div className="card-body">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item={branchArray}
                                                                option="AllBranches"
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                                Branch
                                                            </div>
                                                        </div>
                                                        {branchArray.map((branch) => (
                                                            <div key={branch.bR_ID} style={{ display: 'flex', alignItems: 'center', marginRight: '50px', marginTop: '10px' }}>
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item={branch.bR_NAME}
                                                                    option={branch.bR_ID}
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '40px' }}>
                                                                    {branch.bR_NAME}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="card mt-4" style={{ width: '329px', height: '279px', marginRight: '120px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                    <div className="card-body cardBodyCustomAsset">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item={departmentArray}
                                                                option="AllDepartments"
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                                Department
                                                            </div>
                                                        </div>
                                                        {departmentArray.map((department) => (
                                                            <div key={department.id} style={{ display: 'flex', alignItems: 'center', marginRight: '50px', marginTop: '10px' }}>
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item={department.departmentName}
                                                                    option={department.id}
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '40px' }}>
                                                                    {department.departmentName}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="card mt-4" style={{ width: '329px', height: '279px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                                    <div className="card-body cardBodyCustomAsset">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                            <CustomCheckBox
                                                                tab="Asset"
                                                                item={categoryArray}
                                                                option="AllCategories"
                                                                roleData={roleData}
                                                                isViewMode={isViewMode}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                            />
                                                            <div className="fontFamily fontsize20 text-center blackTextColor" style={{ marginLeft: '10px' }}>
                                                                Asset Category
                                                            </div>
                                                        </div>
                                                        {categoryArray.map((category) => (
                                                            <div key={category.id} style={{ display: 'flex', alignItems: 'center', marginRight: '50px', marginTop: '10px' }}>
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item={category.categoryName}
                                                                    option={category.id}
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '40px' }}>
                                                                    {category.categoryName}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fontFamily fontweightRegular fontsize20 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                *Please make sure to define ‘Branch’ and ‘Departments’ for all in the Employees’ list.
                                            </div>
                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                View and Edit Asset
                                            </div>
                                            <div className="fontFamily fontweightRegular fontsize20 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                Set user’s permission to add / edit / delete / view assets:
                                            </div>

                                            <div className='customCardsAsset' style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                    <div className="card-body smallCardBodyCustomAsset">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                                            <div className="fontFamily fontsize16 text-center">
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item="viewAssets"
                                                                    option="View"
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                                View Only
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                            User can only view assets
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="fontFamily fontsize16 text-center">
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item="hidePriceInfo"
                                                                    option="Hide"
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px' }}>
                                                                Hide Price info.
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize14 grayTextColor" style={{ marginLeft: '10px', marginBottom: '20px' }}>
                                                            (This option will also curtail any Editing privileges allowed elsewhere)
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                    <div className="card-body smallCardBodyCustomAsset">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                            <div className="fontFamily fontsize16 text-center">
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item="addAssets"
                                                                    option="Add"
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                                Add Assets
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                            User can view and create new assets. User can edit or delete Self-Created assets.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                    <div className="card-body smallCardBodyCustomAsset">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                            <div className="fontFamily fontsize16 text-center">
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item="editAssets"
                                                                    option="Edit"
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                                Edit Assets
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                            User can view and edit ANY assets
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card mt-4" style={{ width: '210px', height: '247px', marginRight: '40px' }}>
                                                    <div className="card-body smallCardBodyCustomAsset">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                            <div className="fontFamily fontsize16 text-center">
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item="deleteAssets"
                                                                    option="Delete"
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                                Delete Assets
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                            User can view and delete ANY assets
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card mt-4" style={{ width: '210px', height: '247px' }}>
                                                    <div className="card-body smallCardBodyCustomAsset">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                            <div className="fontFamily fontsize16 text-center">
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item="importAssets"
                                                                    option="Import"
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                                Import Assets
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                            User can import assets
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                            <div className="fontFamily fontsize16 text-center">
                                                                <CustomCheckBox
                                                                    tab="Asset"
                                                                    item="exportAssets"
                                                                    option="Export"
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                            <div className="blackTextColor fontFamily fontsize20 fontweightMedium text-center" style={{ marginLeft: '10px' }}>
                                                                Export Assets
                                                            </div>
                                                        </div>
                                                        <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                                            User can export assets
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {tabActive === 'Reports' && (
                                        <div>
                                            <div className="mt-3">
                                                <div className="fontFamily fontweightMedium fontsize20 blackTextColor">
                                                    Reports
                                                </div>
                                                <div className="customFlexbox">
                                                    <div className="column" style={{ marginRight: '250px', marginLeft: '20px' }}>
                                                        {reportsPartOne.map((report) => (
                                                            <div key={report} className="d-flex align-items-center mt-2">
                                                                <CustomCheckBox
                                                                    tab="Reports"
                                                                    item={report}
                                                                    option={report}
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                                <label htmlFor={`report_${report}`} className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                    {report}
                                                                </label>
                                                            </div>
                                                        ))}

                                                    </div>
                                                    <div className="column" style={{ marginBottom: '34px', marginLeft: '20px' }}>
                                                        {reportsPartTwo.map((report) => (
                                                            <div key={report} className="d-flex align-items-center mt-2">
                                                                <CustomCheckBox
                                                                    tab="Reports"
                                                                    item={report}
                                                                    option={report}
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                                <label htmlFor={`report_${report + 5}`} className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                    {report}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {tabActive === 'Dashboard' && (
                                        <div>
                                            <div className="mt-3">
                                                <div className="fontFamily fontweightMedium fontsize20 blackTextColor">
                                                    Dashboard
                                                </div>
                                                <div className="customFlexbox">
                                                    <div className="column" style={{ marginRight: '250px', marginLeft: '20px' }}>
                                                        {dashboardPartOne.map((Dashboard) => (
                                                            <div key={Dashboard} className="d-flex align-items-center mt-2">
                                                                <CustomCheckBox
                                                                    tab="Dashboard"
                                                                    item={Dashboard}
                                                                    option={Dashboard}
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                                <label htmlFor={`Dashboard_${Dashboard}`} className="ml-2 fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                    {Dashboard}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="column" style={{ marginBottom: '95px', marginLeft: '20px' }}>
                                                        {dashboardPartTwo.map((Dashboard) => (
                                                            <div key={Dashboard} className="d-flex align-items-center mt-2">
                                                                <CustomCheckBox
                                                                    tab="Dashboard"
                                                                    item={Dashboard}
                                                                    option={Dashboard}
                                                                    roleData={roleData}
                                                                    isViewMode={isViewMode}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                                <label htmlFor={`Dashboard_${Dashboard + 5}`} className="ml-2 fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginLeft: '20px' }}>
                                                                    {Dashboard}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {tabActive !== 'Events' && tabActive !== 'Admin Rights' && (
                                    <div className="text-center buttonSpace">
                                        {!isViewMode && (
                                            <button type="button" className="buttonSave fontFamily fontweightRegular fontsize16 coloredButton" onClick={submitRole}>
                                                {RoleIsSave ? (
                                                    <ThreeCircles
                                                        visible={true}
                                                        height="25"
                                                        width="47"
                                                        color="White"
                                                        ariaLabel="three-circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                    />
                                                ) : (
                                                    'Save'
                                                )}
                                            </button>
                                        )}

                                        <button
                                            type="button"
                                            id="btncloseModalBranch"
                                            className="buttonClose fontFamily fontweightRegular fontsize16 outlineButton"
                                            style={{ marginLeft: '5px' }}
                                            onClick={cancelRole}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}

                            </div>
                        </div>
                    )}

                </div>
            )}
        </div>
    );
};

export default CreateRole;

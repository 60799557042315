import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import GetUserAccess from '../../../CommomPages/GetUserAccess';
import LoadingSpinner from '../../../CommomPages/LoadingSpinner';
import { getRequestSettings, updateRequestSettings, getRequestSubCategory, addDefaultPriority, getDefaultPriority } from '../../../Services/RequestService';

import '../AssetSettings/AssetSettingsCss.css';
import XMarksvgIcon from '../../../assets/icon/X mark.svg';


const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#B0ACAC',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        marginLeft: '10px',
        width: '275px'
    }),
};

const customStylesNew = {
    placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#B0ACAC',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    // container: (provided) => ({
    //     ...provided,
    //     marginLeft: '10px',
    //     width: '476px'
    // }),
};


const RequestSettings = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [userScreens, setUserScreens] = useState({});

    const [prefix, setPrefix] = useState('');
    const [padding, setPadding] = useState('');
    const [numberStartsFrom, setNumberStartsFrom] = useState('');
    const [requestID, setRequestID] = useState('');
    const [requestSubCategoryArray, setRequestSubCategoryArray] = useState([]);
    const [addDefaultPriorityTab, setAddDefaultPriorityTab] = useState(false);
    const [defaultPriorityArray, setDefaultPriorityArray] = useState([]);
    const [labelConfigurationArray, setLabelConfigurationArray] = useState([]);

    const defaultOptions = [
        { value: 'Auto Generate', label: 'Auto Generate' },
        { value: 'Company Preference', label: 'Company Preference' },
        { value: 'Request Category', label: 'Request Category' },
        { value: 'Request Sub Category', label: 'Request Sub Category' },
    ];
    const financialYearOptions = [
        { value: 'Beginning of Financial year', label: 'Beginning of Financial year' }
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm({
        defaultValues: {
            defaultValue: "",
            prefix: "",
            padding: "",
            numberStartsFrom: "",
            optionToReset: "",
            financialYear: "",
            mapForAsset: 0,
            defaultPriority: 0,
            labelConfiguration: []
        }
    });

    const {
        register: registerDefaultPriority,
        handleSubmit: handleSubmitDefaultPriority,
        formState: { errors: errorsDefaultPriority },
        reset: resetDefaultPriority
    } = useForm({
        defaultValues: {
            defaultPriorityName: '',
        }
    });

    useEffect(() => {
        fetchUserScreens();
        fetchAssetData();
        getRequestSubCategoryDetails();
        getRequestDefaultPriority();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserScreens = async () => {
        try {
            const userScreens = await GetUserAccess();
            setUserScreens(userScreens);
        } catch (error) {
            console.error('Error fetching user access:', error);
        }
    };

    const fetchAssetData = async () => {
        try {
            setAddDefaultPriorityTab(false);
            const RequestData = await getRequestSettings();
            const { defaultValue, requestID, prefix, padding, numberStartsFrom, optionToReset, financialYear, mapForAsset, defaultPriority, labelConfiguration } = RequestData;
            setLabelConfigurationArray(labelConfiguration);
            setRequestID(requestID);
            setPrefix(prefix);
            setPadding(padding);
            setNumberStartsFrom(numberStartsFrom);
            setValue('defaultValue', defaultValue);
            setValue('prefix', prefix);
            setValue('padding', padding);
            setValue('numberStartsFrom', numberStartsFrom);
            setValue('optionToReset', optionToReset);
            setValue('financialYear', financialYear);
            setValue('mapForAsset', mapForAsset);
            setValue('defaultPriority', defaultPriority);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching Asset Setting data:', error);
            setIsLoading(false);
        }
    };

    const getRequestSubCategoryDetails = async () => {
        const reqSubCategoryData = await getRequestSubCategory();
        setRequestSubCategoryArray(reqSubCategoryData)?.reverse();
    };

    const getRequestDefaultPriority = async () => {
        const DefaultPriority = await getDefaultPriority();
        setDefaultPriorityArray(DefaultPriority)?.reverse();
    };

    useEffect(() => {
        const generatedID = generateRequestID(prefix, numberStartsFrom, padding);
        setRequestID(generatedID);
    }, [prefix, numberStartsFrom, padding]);


    const generateRequestID = (prefix, numberStartsFrom, padding) => {
        const zeros = '0'.repeat(parseInt(padding, 10));
        return `${prefix.toUpperCase()}${zeros}${numberStartsFrom}`;
    };

    const onSubmit = async (data) => {
        if (!userScreens["Request Configuration"]?.Add || !userScreens["Request Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        try {
            setIsLoading(true);
            const { defaultValue, prefix, padding, numberStartsFrom, optionToReset, financialYear, mapForAsset, defaultPriority, labelConfiguration } = data;

            const payload = {
                id: 1,
                requestID: requestID,
                defaultValue: defaultValue,
                prefix: prefix,
                padding: padding,
                numberStartsFrom: numberStartsFrom,
                optionToReset: optionToReset,
                financialYear: financialYear,
                mapForAsset: mapForAsset,
                defaultPriority: defaultPriority,
                labelConfiguration: labelConfiguration
            };
            await updateRequestSettings(payload);
            setIsLoading(false);
            toast.success('Request Settings Updated successfully');
            setTimeout(() => navigate("/Settings"), 2000);
        } catch (error) {
            setIsLoading(false);
            toast.error('Failed to save Request Settings');
        }
    };

    const AddDefaultPriority = () => {
        if (!userScreens["Request Configuration"]?.Add) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        setAddDefaultPriorityTab(true);
    };

    const closeDefaultPriority = () => {
        setAddDefaultPriorityTab(false);
        resetDefaultPriority();
    };

    const handleDefaultPrioritySubmit = async (desData) => {
        if (!userScreens["Request Configuration"]?.Add || !userScreens["Request Configuration"]?.Edit) {
            toast.error('You do not have permission to this Action.');
            return;
        }
        const { defaultPriorityName } = desData;
        setIsLoading(true);

        const response = await addDefaultPriority(defaultPriorityName);
        if (response === "Inserted") {
            getRequestDefaultPriority();
            closeDefaultPriority();
            setIsLoading(false);
            toast.success("Default Priority Inserted Sucessfully");
            setTimeout(() => navigate("/Settings"), 2000);
        } else {
            setIsLoading(false);
            toast.error(response);
        }
    }

    const handleCloseRequestModal = () => {
        navigate("/Settings");
        setValue('', null);
    };


    return (
        <div className='bacgroundStyle'>
            <ToastContainer theme="colored" />
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div>
                    <div className="pagetitleAsset">
                        <div className="Pageheader fontFamily fontsize24 fontweightSemiBold">
                            <span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}>Setting &gt;&gt;</span>
                                <span onClick={() => { navigate('/Settings') }} style={{ cursor: 'pointer' }}> Request &gt;&gt; </span>
                                <span> Request Settings </span>
                            </span>
                            <div>
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    </div>

                    {userScreens["Request Configuration"]?.View && (
                        <div className="customScrollbar">
                            <div className="card mt-4">
                                <div className="card-body-AssetSettings">
                                    <div className="CustomBoxShadow">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium">Request ID Settings</div>
                                        <div className="parentDiv">
                                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor" style={{ marginRight: '30px' }}>
                                                    Default
                                                </div>
                                                <Controller
                                                    name="defaultValue"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={defaultOptions.find(option => option.value === field.value)}
                                                            options={defaultOptions}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStyles}
                                                            placeholder="Select an option"
                                                            isSearchable
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                                            onBlur={field.onBlur}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ paddingLeft: '16px' }}>
                                            <div className="row" style={{ paddingTop: '16px' }}>
                                                <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                        Prefix
                                                    </div>
                                                    <input
                                                        className="textinputCustomAssetSettings upperCase"
                                                        placeholder=""
                                                        {...register('prefix', { required: 'Please Enter Prefix' })}
                                                        onChange={(e) => setPrefix(e.target.value)}
                                                    />
                                                    {errors.prefix && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                            {errors.prefix.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                        Padding
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                            className="textinputCustomAssetSettings"
                                                            placeholder=""
                                                            maxLength="2"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/\D/g, '').slice(0, 2);
                                                                setPadding(e.target.value);
                                                            }}
                                                            {...register('padding', { required: 'Please Enter Padding' })}
                                                        />
                                                    </div>
                                                    {errors.padding && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                            {errors.padding.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '4px' }}>
                                                        Number Starts From
                                                    </div>
                                                    <input
                                                        className="textinputCustomAssetSettings"
                                                        placeholder=""
                                                        onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                                                        {...register('numberStartsFrom', { required: 'Please Enter Number starts from' })}
                                                        onChange={(e) => setNumberStartsFrom(e.target.value)}
                                                    />
                                                    {errors.numberStartsFrom && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                            {errors.numberStartsFrom.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div>
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2" style={{ paddingTop: '10px', whiteSpace: 'nowrap' }}>
                                                    Your Request ID looks like : <span className='blackTextColor fontFamily fontsize14 fontweightMedium'>{requestID}</span>
                                                </div>
                                            </div>

                                            <div className='d-flex mTop20'>
                                                <div className='d-flex align-items-center'>
                                                    <Controller
                                                        name="optionToReset"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pRight45'>
                                                                    Option to reset the code
                                                                </div>
                                                                <input
                                                                    type="radio"
                                                                    value="Yes"
                                                                    {...field}
                                                                    checked={field.value === 'Yes'}
                                                                    onChange={() => field.onChange('Yes')}
                                                                />
                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                    Yes
                                                                </div>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className='d-flex pLeft12 align-items-center'>
                                                    <Controller
                                                        name="optionToReset"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <input
                                                                    type="radio"
                                                                    value="No"
                                                                    {...field}
                                                                    checked={field.value === 'No'}
                                                                    onChange={() => field.onChange('No')}
                                                                />
                                                                <div className='blackTextColor fontFamily fontsize16 fontweightRegular pLeft12 pRight12'>
                                                                    No
                                                                </div>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <Controller
                                                    name="financialYear"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={financialYearOptions.find(option => option.value === field.value)}
                                                            options={financialYearOptions}
                                                            className="fontFamily fontweightRegular fontsize16"
                                                            styles={customStyles}
                                                            placeholder="Select an option"
                                                            isSearchable
                                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                                            onBlur={field.onBlur}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="row pTop24">
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor mt-2">
                                                        Map the default request category for asset queries<span className="mandatoryCss"> *</span>
                                                    </div>
                                                    <Controller
                                                        name="mapForAsset"
                                                        control={control}
                                                        rules={{ required: 'Please select a Request Category for mapping Asset queries' }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                value={requestSubCategoryArray?.find(c => c.id === field.value) || null}
                                                                onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                                                                options={requestSubCategoryArray}
                                                                getOptionLabel={(option) => option.requestSubCategoryName}
                                                                getOptionValue={(option) => option.id}
                                                                placeholder="Select Request Sub Category"
                                                                // isClearable
                                                                isSearchable
                                                                className={`${errors.mapForAsset ? 'is-invalid' : ''}`}
                                                                styles={customStylesNew}
                                                            />
                                                        )}
                                                    />
                                                    {errors.mapForAsset && (
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                            {errors.mapForAsset.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="CustomBoxShadow mTop20">
                                        <div className="blackTextColor fontFamily fontsize20 fontweightMedium mBottom20">
                                            Request Priority
                                        </div>

                                        <div className="row pTop24">
                                            <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor linkLableStyle">
                                                    <div className="fontFamily fontweightRegular fontsize16 grayTextColor" >
                                                        Default Priority
                                                    </div>
                                                    <div className="fontFamily fontweightRegular fontsize16 linkColor">
                                                        <a href="#AddDefaultPriority" className="underline" onClick={AddDefaultPriority}>Add</a>
                                                    </div>
                                                </div>
                                                <Controller
                                                    name="defaultPriority"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            value={defaultPriorityArray.find(des => des.id === field.value) || null}
                                                            onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : null)}
                                                            options={defaultPriorityArray}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            placeholder="Select Default Priority"
                                                            // isClearable
                                                            isSearchable
                                                            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: '30px', textAlign: 'center' }}>
                                            <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                <div className="blackTextColor fontFamily fontsize16 fontweightMedium mBottom20">
                                                    Request Status Label
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                <div className="blackTextColor fontFamily fontsize16 fontweightMedium mBottom20">
                                                    Configured Label for Managing Team
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                <div className="blackTextColor fontFamily fontsize16 fontweightMedium mBottom20">
                                                    Employees Status Label
                                                </div>
                                            </div>
                                        </div>

                                        {/* Map through labelConfiguration to dynamically render fields */}
                                        {labelConfigurationArray?.map((config, index) => (
                                            <div className="row" key={index} style={{ paddingTop: '12px' }}>
                                                {/* Request Status Label (Non-editable) */}
                                                <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                    <input
                                                        className="textinputCustomAssetSettings"
                                                        value={config.labelValue}
                                                        readOnly
                                                        {...register(`labelConfiguration[${index}].labelValue`, { required: true })}
                                                    />
                                                </div>

                                                {/* Configured Label for Managing Team */}
                                                <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                    <input
                                                        className="textinputCustomAsset"
                                                        defaultValue={config.labelAssignedValue}
                                                        {...register(`labelConfiguration[${index}].labelAssignedValue`, { required: 'Please enter the configured label' })}
                                                    />
                                                </div>

                                                {/* Employees Status Label */}
                                                <div className="col-6 col-md-4 col-lg-4 col-sm-12">
                                                    <input
                                                        className="textinputCustomAsset"
                                                        defaultValue={config.labelAssignedEmployee}
                                                        {...register(`labelConfiguration[${index}].labelAssignedEmployee`, { required: 'Please enter the employee status label' })}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div style={{ marginTop: "8%", display: 'flex', justifyContent: 'center', paddingTop: 15, paddingBottom: 10 }}>
                                        <button type="button" className="fontFamily fontweightRegular fontsize16 coloredButtonSave ButtonSpace" onClick={handleSubmit(onSubmit)}>
                                            Save
                                        </button>
                                        <button type="button" id="btncloseModal" onClick={handleCloseRequestModal} className="fontFamily fontweightRegular fontsize16 outlineButton" style={{ marginLeft: '5px' }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <Dialog open={addDefaultPriorityTab} >
                        <DialogTitle className="dialogTitle">
                            <div className="d-flex justify-content-between">
                                <div className="fontFamily fontweightMedium fontsize16 d-flex align-items-center">
                                    <span className="fontFamily fontweightMedium fontsize16">Add Default Priority</span>
                                </div>
                                <div>
                                    <a href="#close" onClick={() => closeDefaultPriority()}> <img src={XMarksvgIcon} alt="datesvgIcon" /></a>
                                </div>
                            </div>
                        </DialogTitle>
                        <hr className="m-2"></hr>
                        <DialogContent className="dialogContent">
                            <form className="needs-validation" noValidate onSubmit={handleSubmitDefaultPriority(handleDefaultPrioritySubmit)}>
                                <div className="fontFamily fontweightRegular fontsize16 grayTextColor">Default Priority<span className="mandatoryCss"> *</span></div>
                                <input
                                    placeholder="Enter Default Priority"
                                    {...registerDefaultPriority('defaultPriorityName', { required: 'Please Enter Default Priority' })}
                                    style={{
                                        paddingLeft: '12px', backgroundColor: '#FFFFFF', border: '#00000014 1px solid', borderRadius: '5px', fontFamily: '"Nunito", sans-serif', fontWeight: '400', fontSize: '16px', width: '100%', outline: 'none', height: '32px'
                                    }}
                                />
                                {errorsDefaultPriority.defaultPriorityName && (
                                    <div className="invalid-feedback" style={{ display: 'block' }}  >
                                        {errorsDefaultPriority.defaultPriorityName.message}
                                    </div>
                                )}
                                <div className="text-center buttonSpace">
                                    <button type="submit" className="coloredButtonSave">
                                        Save
                                    </button>
                                    <button type="button" className="outlineButton" onClick={() => closeDefaultPriority()}>
                                        Close
                                    </button>
                                </div>
                            </form>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </div>
    );

}

export default RequestSettings;
